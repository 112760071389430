import React, { useState } from 'react';
import { Row, Col, InputGroup, Form, Button, FormControl } from 'react-bootstrap';

const FormMatriculaEditHeader = (props) => {

    const [text, setText] = useState(props.elementToEdit.text);
    const [classType, setClassType] = useState(props.elementToEdit.class[0]);

    const handleEnableSave = () =>  {
        return text.length > 3;
    };

    const handleSave = () => {
        const elementEdited = props.elementToEdit;
        elementEdited.text = text.trim();
        elementEdited.class[0] = classType;
        props.onComplete(elementEdited);
    }

    return (
        <div>
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Texto</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control 
                            type="text" 
                            value={text}                    
                            onChange={e=>setText(e.target.value)}
                            required
                            isInvalid={!(text.length > 3)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Ingresa un texto para el título.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Tipo</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            as="select"
                            value={classType}
                            onChange={e=>setClassType(e.target.value)}
                        >
                            <option value='h1'>h1</option>
                            <option value='h2'>h2</option>
                            <option value='h3'>h3</option>
                            <option value='h4'>h4</option>
                        </FormControl>
                    </InputGroup>
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col className='mt-3 text-right'>
                    <Button 
                        onClick={()=>handleSave()} 
                        disabled={!handleEnableSave()} 
                        variant='outline-primary'
                    >Guardar</Button>
                </Col>
            </Row>
        </div>
    )
};

export default FormMatriculaEditHeader;