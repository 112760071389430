const dev = {
  apiGateway: {
      REGION: "us-east-2",
      URL: "https://jy57yuzcu0.execute-api.us-east-2.amazonaws.com/dev"
  },
  cognito: {
      REGION: "us-east-2",
      USER_POOL_ID: "us-east-2_9mZPFoSmA",
      APP_CLIENT_ID: "79t2h8o9qk5vd0bajg4ujpsjtu",
      IDENTITY_POOL_ID: "us-east-2:ed03fb25-a1d1-4d29-931d-d4c89e9991ed"
  },
  stripe_pk: "pk_test_",
  matriculasUser: 'matriculas@rocket.gvalor.co',
  matriculasPass: 'M@tr1cul@5*',
};

const prod = {
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://u9vkld7spg.execute-api.us-east-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_XWNxPh6lM",
    APP_CLIENT_ID: "27538n1uc1g2jk3o1m6heghsf2",
    IDENTITY_POOL_ID: "us-east-2:6b065fa2-1f91-4334-98ee-2fd220af7228"
  },
  matriculasUser: 'matriculas@rocket.gvalor.co',
  matriculasPass: 'M@tr1cul@5*',
};

// Si no se especifica el ambiente (dev ó prod), se asume que el ambiente es dev
const state = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

const config = {
    // Aca las configuraciones para ambos ambientes
    customUser: "userTest",
    customUserPass: "userTestPass",
    SENTRY_DSN: "https://8606cd0130dc4f7abeb24a12a1519955@o1010058.ingest.sentry.io/5977789",
    ...state
  };

export default config;