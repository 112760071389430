import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Spinner, Button, Tabs, Tab, Modal, InputGroup, Form } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { MdPlaylistPlay, MdErrorOutline } from "react-icons/md";
import { GrDocumentConfig } from "react-icons/gr";
import ExecReport from "../components/exectReport";
import LoadingModal from "../components/loadingModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { API } from "aws-amplify";

const Reports = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [showLM, setShowLM] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showConfigReportLM, setShowConfigReportLM] = useState(false);
    const [showConfigReportModal, setShowConfigReportModal] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [reportData, setReportData] = useState({});
    const [startDate, setStartDate] = useState(null);

    const bootstrapTableMsg = useRef("No hay reportes que mostrar");
    const msgModal = useRef("");
    const alertModalTitle = useRef(<MdErrorOutline />);
    const configReportModalTitle = useRef(<GrDocumentConfig />);
    const configReportModalBody = useRef("");
    const periodoSelected = useRef(null);
    const cursoSelected = useRef(null);
    const reportProps = useRef({});
    const showPeriodo = useRef(false);
    const reportId = useRef("");
    const cursoSelectedNombre = useRef("");

    useEffect(()=>{
        if(!showReport){
            if(showLM){
                setShowReport(true);
            }
        }
    },[showReport, showLM])

    const handleSetReport = (data, param) => {
        switch (param) {
            case "periodo":
                periodoSelected.current = data;
                break;
            case "curso":
                cursoSelected.current = data;
                break;
            default:
                break;
        }

        switch (reportId.current) {
            case "2021A02":
                reportProps.current = {
                    reportId: reportId.current,
                    onLoad: setShowLM,
                    periodo: periodoSelected.current
                };
                setReportData(reportProps.current);
                handleCloseConfigReportModal();
                setShowLM(true);
                break;
            case "2021B01":
                if(cursoSelected.current && periodoSelected.current){
                    reportProps.current = {
                        reportId: reportId.current,
                        onLoad: setShowLM,
                        periodo: periodoSelected.current,
                        cursoId: cursoSelected.current,
                        cursoNombre: cursoSelectedNombre.current
                    };
                    setReportData(reportProps.current);
                    handleCloseConfigReportModal();
                    setShowLM(true);
                }
                break;
            case "2021B03":
                if(cursoSelected.current){
                    reportProps.current = {
                        reportId: reportId.current,
                        onLoad: setShowLM,
                        cursoId: cursoSelected.current,
                        cursoNombre: cursoSelectedNombre.current
                    };
                    setReportData(reportProps.current);
                    handleCloseConfigReportModal();
                    setShowLM(true);
                }
                break;
            default:
                break;
        }
    };

    const loadCourseSelect = async(report, shPeriodo) => {
        try{
            const response = await API.get("rocket", "/getCursos", {
                "queryStringParameters": {
                    institucionId: props.institucionId,
                    index: "-1"
                }
            });
            if (response.result === "success"){
                const cursos = response.description;
                reportId.current = report;
                showPeriodo.current = shPeriodo;
                configReportModalBody.current =  
                    <Row className="justify-content-center">
                        <Col xs={"auto"}>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="nombre-acudiente">Curso</InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    as="select"
                                    onChange={e => {
                                        const tgt = e.target;
                                        cursoSelectedNombre.current = tgt.children[tgt.selectedIndex].id;
                                        handleSetReport(e.target.value, "curso");
                                    }}
                                >
                                    <option></option>
                                    {cursos.map((value) => {
                                        if(value.status){
                                            return (
                                                <option id={value.nombre} key={value.cursoId} value={value.cursoId}>{value.nombre}</option>
                                            )
                                        } else {
                                            return ""
                                        }
                                    })}
                                </Form.Control>
                            </InputGroup>
                        </Col>
                    </Row>
                setShowConfigReportLM(false);
                setShowConfigReportModal(true);
            } else {
                console.log(response);
                throw Error("UnableToGetCursos");
            }
        } catch(error){
            console.log(error);
            msgModal.current = "No se puede ejecutar el reporte en este momento, intentalo de nuevo, si el problema persiste, ponte en contacto con nosotros.";
            setShowConfigReportLM(false);
            setShowAlertModal(true);
        }
    };

    const handleExecReport = async(report) => {
        setShowReport(false);
        console.log("Ejecutando reporte: " + report);
        switch (report) {
            case "2021A01":
                //Reporte Cartera por cobrar
                reportProps.current = {
                    reportId: report,
                    onLoad: setShowLM
                }
                setReportData(reportProps.current);
                setShowLM(true);
                reportProps.current = {};
                break;
            case "2021A02":
                //Reporte Ingresos VS Egresos
                reportId.current = report;
                showPeriodo.current = true;
                setShowConfigReportModal(true);
                break;
            case "2021B01":
                //Reporte Asistencia
                setShowConfigReportLM(true);
                loadCourseSelect(report, true);             
                break;
            case "2021B03":
                //Reporte Listado estudiantes
                setShowConfigReportLM(true);
                loadCourseSelect(report, false);
                break;
            case "2021C01":
                //Reporte Listado usuarios
                reportProps.current = {
                    reportId: report,
                    onLoad: setShowLM
                };
                setReportData(reportProps.current);
                setShowLM(true);
                reportProps.current = {};
                break;
            default:
                break;
        }
    };

    useEffect(()=>{
        if(isLoading){
            setIsLoading(false);
        }
    },[isLoading])

    const reportsFinancieros = useRef([
        {
            report: "cartera por cobrar",
            id: "2021A01",
            ejecutar: <Button onClick={()=>handleExecReport("2021A01")} className="action-button" variant="outline-success" ><MdPlaylistPlay size={30}/></Button>
        },
        {
            report: "ingresos vs egresos",
            id: "2021A02",
            ejecutar: <Button onClick={()=>handleExecReport("2021A02")} className="action-button" variant="outline-success" ><MdPlaylistPlay size={30}/></Button>
        },
    ]);
    const reportsAcademicos = useRef([
        {
            report: "asistencia",
            id: "2021B01",
            ejecutar: <Button onClick={()=>handleExecReport("2021B01")} className="action-button" variant="outline-success" ><MdPlaylistPlay size={30}/></Button>
        },
        {
            report: "calificaciones",
            id: "2021B02"
        },
        {
            report: "listado participantes",
            id: "2021B03",
            ejecutar: <Button onClick={()=>handleExecReport("2021B03")} className="action-button" variant="outline-success" ><MdPlaylistPlay size={30}/></Button>
        },
    ]);
    const reportsAdministrativos = useRef([
        {
            report: "listado usuarios",
            id: "2021C01",
            ejecutar: <Button onClick={()=>handleExecReport("2021C01")} className="action-button" variant="outline-success" ><MdPlaylistPlay size={30}/></Button>
        }
    ]);

    const columns = useRef([
        {
            dataField: "report", 
            text: "Reporte",
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "ejecutar", 
            text: "Ejecutar",
            headerStyle: () => {
                return { width: '20%', textAlign: "center" }
            },
            style: {
                textAlign: "center"
            }
        },
    ]);

    const handleCloseAlertModal = () => {
        setShowAlertModal(false);
    };

    const handleCloseConfigReportModal = () => {
        setShowConfigReportModal(false);
        periodoSelected.current = null;
        cursoSelected.current = null;
        setStartDate(null);
        configReportModalBody.current = "";
        showPeriodo.current = false;
        reportId.current = "";
        cursoSelectedNombre.current = "";
        reportProps.current = {};
    };
    
    return (
        <div>
            <h4>Reportes</h4>
            <hr></hr>
            <Modal 
                show={showConfigReportModal}
                onHide={()=>handleCloseConfigReportModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{configReportModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showPeriodo.current && 
                    <Row className="justify-content-center">
                        <Col xs={"auto"}>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="nombre-acudiente">Periodo</InputGroup.Text>
                                </InputGroup.Prepend>
                                <DatePicker
                                    className="form-control text-center"
                                    selected = {startDate}
                                    dateFormat="MM/yyyy"
                                    onChange={date => {setStartDate(date);handleSetReport(date, "periodo")}}
                                    showMonthYearPicker
                                />  
                            </InputGroup>
                        </Col>
                    </Row>}
                    {configReportModalBody.current}
                </Modal.Body>
            </Modal>
            <Modal 
                show={showAlertModal}
                onHide={()=>handleCloseAlertModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>
            <LoadingModal showLoadingModal={showLM}/>
            <LoadingModal showLoadingModal={showConfigReportLM}/>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-1 pt-1">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :  
                <div>
                    <Row className="mb-1">
                        <Col>
                            <Tabs defaultActiveKey="financieros" id="reports">
                                <Tab eventKey="financieros" title="Financieros">
                                    <BootstrapTable
                                        keyField="id"
                                        data={reportsFinancieros.current}
                                        columns={columns.current}
                                        noDataIndication={bootstrapTableMsg.current}
                                    />
                                </Tab>
                                <Tab eventKey="academicos" title="Academicos">
                                    <BootstrapTable
                                        keyField="id"
                                        data={reportsAcademicos.current}
                                        columns={columns.current}
                                        noDataIndication={bootstrapTableMsg.current}
                                    />
                                </Tab>
                                <Tab eventKey="administrativos" title="Administrativos">
                                    <BootstrapTable
                                        keyField="id"
                                        data={reportsAdministrativos.current}
                                        columns={columns.current}
                                        noDataIndication={bootstrapTableMsg.current}
                                    />
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                    
                        { showReport && <ExecReport reportProps={reportData} >
                            
                            
                        </ExecReport>}
                </div>
            }
        </div>
    )
};

export default Reports;