import React, { useState } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import FormsMatriculaList from "../components/formsMatriculasList";
import MatriculasConfig from "../components/matriculasConfig";
import MatriculasCandidatos from "../components/matriculasCandidatos";

const Matriculas = (props) => {

    const [tab, setTab] = useState('candidatos')
    
    return (
        <div>
            <h4>Matrículas</h4>
            <hr></hr>
            <Row className="mb-1">
                <Col>
                    <Tabs 
                        activeKey={tab}
                        id="matriculas"
                        onSelect={(k)=>setTab(k)}
                    >
                        <Tab eventKey="candidatos" title="Candidatos">
                            {tab === 'candidatos' && <MatriculasCandidatos />}
                        </Tab>
                        <Tab eventKey="formularios" title="Formularios">
                            {tab === 'formularios' && <FormsMatriculaList />}
                        </Tab>
                        <Tab eventKey="configuracion" title="Configuración">
                            {tab === 'configuracion' && <MatriculasConfig />}
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </div>
    )
};

export default Matriculas;