import React from "react";
import { Row, Col, Alert } from "react-bootstrap";

const Calificaciones = () => {

    return (
        <div>
            <Row>
                <Col>
                    <Alert variant={"success"}>
                    <Alert.Heading>Hola!</Alert.Heading>
                        No hay calificaciones por ahora, vuelve luego.
                    </Alert>
                </Col>
            </Row>
        </div>
    )
}

export default Calificaciones;