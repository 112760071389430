import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col, InputGroup, Spinner, Modal, Card, ButtonGroup, Button, Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { useHistory } from "react-router";
import RenderCurso from "../components/renderCurso";
import { MdErrorOutline } from "react-icons/md";
import { GiSpellBook, GiBookCover, GiBookshelf, GiWhiteBook, GiBookPile , GiOpenBook, GiBlackBook, GiBookmark } from "react-icons/gi";
import { GrDocumentConfig } from "react-icons/gr";
import Asistencia from "./asistencia";
import CrearAnuncio from "../components/crearAnuncio";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingModal from "../components/loadingModal";
import ExecReport from "../components/exectReport";

const mapStateToProps = (state, props) => ({
    userData: state.userAuthenticated,
    integraciones: state.integraciones,
    props: props
});

const mapDispatchToProps = dispatch => ({});

const MisCursos = ({userData, props, integraciones}) => {
    const bootstrapTableMsg = useRef("No tienes inscripciones a cursos");
    const [isLoading, setIsLoading] = useState(true);
    const msgModal = useRef("");
    const alertModalTitle = useRef(<MdErrorOutline />);
    const configReportModalTitle = useRef(<GrDocumentConfig />);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showModalAsistencia, setShowModalAsistencia] = useState(false);
    const [showModalAnuncio, setShowModalAnuncio] = useState(false);
    const [showLM, setShowLM] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [reportData, setReportData] = useState({});
    const [showConfigReportModal, setShowConfigReportModal] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const history = useHistory();

    const modalAsistenciaTitle = useRef("");
    const modalAsistenciaBody = useRef("");
    const modalAnuncioTitle = useRef("");
    const modalAnuncioBody = useRef("");
    const reportProps = useRef({});
    const showPeriodo = useRef(false);
    const reportId = useRef("");
    const periodoSelected = useRef(null);
    const cursoSelected = useRef(null);
    const cursoSelectedNombre = useRef("");
    const usuarioId = useRef(null);

    const courseIcons = useRef([
        <GiSpellBook size={60}/>,<GiBookCover size={60}/>,<GiBookshelf size={60}/>,
        <GiWhiteBook size={60}/>,<GiBookPile size={60}/>, <GiOpenBook size={60}/>, 
        <GiBlackBook size={60}/>, <GiBookmark size={60}/>
    ])
    const columns = useRef([
        {
            dataField: "action",
            text: "Tus cursos",
            headerStyle: () => {
                return { width: '30%' }
            }
        },
        {
            dataField: "curso",
            text: "Anuncios",
        }
    ]);

    const tableData = useRef([]);

    useEffect(()=>{
        if(!showReport){
            if(showLM){
                setShowReport(true);
            }
        }
    },[showReport, showLM])

    const handleErrors = (exception) => {
        switch (exception.code) {
            case "GetEnrollsException":
                msgModal.current = "No es posible recuperar tus inscripciones, por favor intentalo de nuevo, si el problema persiste comunicate con nosotros.";
                break;
            default:
                msgModal.current = "Error en la aplicación, intentalo de nuevo, si el problema persiste comunicate con nosotros.";
                break;
        }
        setShowAlertModal(true);
    }

    const goToCroomCourse = useCallback(async(id) => {
        console.log("go to croom course");
        setIsLoading(true);
        try{
            var croom_go = require("moodle-client");
            const client = await croom_go.init({
                wwwroot: integraciones.croom.url,
                token: integraciones.croom.usuario,
                service: integraciones.croom.servicio,
                strictSSL: false
            });
            const result = await client.call({
                wsfunction: "auth_userkey_request_login_url",
                method: "POST",
                args: {
                    user: {
                        "username": userData.email
                    }
                }
            });
            if(result.exception){
                console.log(result);
                throw new Error ("SsoException");
            } else {
                console.log(result);
                const url = result.loginurl.split("auth/");
                const courseAccess = result.loginurl + "&wantsurl=" + url[0] + "course/view.php?id="+id;
                window.location.href = courseAccess;
            }
            setIsLoading(false);
        } catch (error){
            console.log(error);
            setIsLoading(false);
        }
    },[integraciones, userData]);

    const handleAsistenica = (cursoData) => {
        modalAsistenciaTitle.current = <span className="capitalize">Asistencia: {cursoData.nombre}</span>
        modalAsistenciaBody.current = <Asistencia cursoData={cursoData} />
        setShowModalAsistencia(true);
    }

    const handleCrearAnuncio = (cursoData) => {
        modalAnuncioTitle.current = ["Nuevo anuncio | ", <span key="123x" className="capitalize">{cursoData.nombre}</span>]
        modalAnuncioBody.current = <CrearAnuncio cursoId={cursoData.cursoId} onFinish={setShowModalAnuncio}/>
        setShowModalAnuncio(true);
    }

    const handleReport = (repId, cursoId, cursoNombre, userId) => {
        setShowReport(false);
        switch (repId) {
            case "2021B01":
                //Reporte Asistencia
                cursoSelected.current = cursoId;
                cursoSelectedNombre.current = cursoNombre;
                reportId.current = repId;
                showPeriodo.current = true;
                usuarioId.current = userId
                setShowConfigReportModal(true);
                break;
            case "2021B03":
                //reporte estudiantes
                reportProps.current = {
                    reportId: repId,
                    onLoad: setShowLM,
                    cursoId: cursoId,
                    cursoNombre: cursoNombre
                };
                setReportData(reportProps.current);
                setShowLM(true);
                reportProps.current = {};
                break;
            default:
                break;
        }
    };

    useEffect(()=>{
        if(userData.rolName === "estudiante" || userData.rolName === "docente"){
            const qsp = {
                usuarioId: userData.usuarioId,
                enrollTypeFlag: true
            }
            API.get("rocket", "/getInscripciones", {
                "queryStringParameters": qsp
            }).then(async(enrolls)=>{
                switch (enrolls.result) {
                    case "success":
                        for (let index = 0; index < enrolls.description.length; index++) {
                            const element = enrolls.description[index];
                            const courseData = await API.get("rocket", "/cursos/"+element.cursoId, {
                                "queryStringParameters":{
                                    institucionId: userData.institucionId
                                }
                            });
                            if (courseData.result === "success"){
                                const curso = courseData.description;
                                if(curso.status){
                                    const courseButton = 
                                        <Card className="text-center">
                                            <Card.Header className="capitalize">{curso.nombre}</Card.Header>
                                            <Card.Body>
                                                <Card.Title>{courseIcons.current[Math.floor(Math.random() * 8)]}</Card.Title>
                                                <Dropdown as={ButtonGroup}>
                                                    <Button onClick={()=>goToCroomCourse(curso.integracionId)} variant="link">Entrar</Button>
                                                    <Dropdown.Toggle split variant="outline-link" id="dropdown-split" />
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item onClick={userData.rolName === "estudiante" ? ()=>props.setPage(2) : ()=>props.setPage(2)}>{userData.rolName === "estudiante" ? "Mis calificaciones" : "Calificar"}</Dropdown.Item>
                                                        <Dropdown.Item onClick={()=>userData.rolName === "estudiante" ? handleReport("2021B01", curso.cursoId, curso.nombre, userData.usuarioId) : handleAsistenica(curso)}>{userData.rolName === "estudiante" ? "Mi asistencia" : "Asistencia"}</Dropdown.Item>
                                                        {userData.rolName === "docente" ? <Dropdown.Item onClick={()=>handleCrearAnuncio(curso)}>Crear anuncio</Dropdown.Item> : <></> }
                                                        {userData.rolName === "docente" ? <Dropdown.Item onClick={()=>handleReport("2021B03", curso.cursoId, curso.nombre)}>Estudiantes</Dropdown.Item> : <></> }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Card.Body>
                                        </Card>
                                    const cData = {
                                        cursoId: curso.cursoId,
                                        action: courseButton,
                                        internalId: curso.internalId,
                                        nombre: curso.nombre,
                                        curso: <RenderCurso institucionId={userData.institucionId} cursoId={curso.cursoId} cursoNombre={curso.nombre} rol={userData.rolName}/>
                                    };
                                    tableData.current.push(cData);
                                }
                            } else {
                                setIsLoading(false);
                                console.log("No se encuentra el curso");
                                const err = {
                                    code: "GetCourseException",
                                    description: courseData
                                };
                                handleErrors(err);
                            }
                        }
                        setIsLoading(false);
                        break;
                    case "fail":
                        setIsLoading(false);
                        console.log("No tienes inscripciones")
                        break;
                
                    default:
                        break;
                }
            }).catch(error=>{
                const err = {
                    code: "GetEnrollsException",
                    description: error
                };
                setIsLoading(false);
                handleErrors(err);
            })
        } else {
            history.push("/");
        }
    },[userData, history, props, goToCroomCourse])

    const handleCloseAlertModal = () => {setShowAlertModal(false);}

    const handleCloseModalAsistencia = () => {
        modalAsistenciaTitle.current = "";
        modalAsistenciaBody.current = "";
        setShowModalAsistencia(false);
    }

    const handleCloseModalAnuncio = () => {
        modalAnuncioTitle.current = "";
        modalAnuncioBody.current = "";
        setShowModalAnuncio(false);
    }

    const handleSetReport = (data, param) => {
        switch (param) {
            case "periodo":
                periodoSelected.current = data;
                break;
            default:
                break;
        }

        switch (reportId.current) {
            case "2021B01":
                //Reporte Asistencia
                if(periodoSelected.current){
                    reportProps.current = {
                        reportId: reportId.current,
                        onLoad: setShowLM,
                        periodo: periodoSelected.current,
                        cursoId: cursoSelected.current,
                        cursoNombre: cursoSelectedNombre.current,
                        usuarioId: usuarioId.current
                    };
                    setReportData(reportProps.current);
                    handleCloseConfigReportModal();
                    setShowLM(true);
                }
                break;
            default:
                break;
        }
    };

    const handleCloseConfigReportModal = () => {
        setShowConfigReportModal(false);
        periodoSelected.current = null;
        cursoSelected.current = null;
        setStartDate(null);
        showPeriodo.current = false;
        reportId.current = "";
        cursoSelectedNombre.current = "";
        reportProps.current = {};
    };

    return (
        <div>
            <Modal 
                show={showConfigReportModal}
                onHide={()=>handleCloseConfigReportModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{configReportModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showPeriodo.current && 
                    <Row className="justify-content-center">
                        <Col xs={"auto"}>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="nombre-acudiente">Periodo</InputGroup.Text>
                                </InputGroup.Prepend>
                                <DatePicker
                                    className="form-control text-center"
                                    selected = {startDate}
                                    dateFormat="MM/yyyy"
                                    onChange={date => {setStartDate(date);handleSetReport(date, "periodo")}}
                                    showMonthYearPicker
                                />  
                            </InputGroup>
                        </Col>
                    </Row>}
                </Modal.Body>
            </Modal>
            <LoadingModal showLoadingModal={showLM}/>
            <Modal 
                show={showModalAsistencia}
                onHide={()=>handleCloseModalAsistencia()}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modalAsistenciaTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalAsistenciaBody.current}
                </Modal.Body>
            </Modal>
            <Modal 
                show={showModalAnuncio}
                onHide={()=>handleCloseModalAnuncio()}
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modalAnuncioTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalAnuncioBody.current}
                </Modal.Body>
            </Modal>
            <Modal 
                show={showAlertModal}
                onHide={()=>handleCloseAlertModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-5 pt-5">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <div>
                    <Row>
                        <Col>
                            <BootstrapTable
                                keyField={"cursoId"}
                                data={tableData.current}
                                columns={columns.current}
                                pagination={paginationFactory({sizePerPage: 10})}
                                noDataIndication={bootstrapTableMsg.current}
                                bordered={ false }
                            />
                        </Col>
                    </Row>
                    { showReport && <ExecReport reportProps={reportData} >
                            
                            
                        </ExecReport>}
                </div>
            }

        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(MisCursos);