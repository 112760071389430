import React from "react";
import { Modal, Spinner, Row, Col } from "react-bootstrap";
import { BsStopwatch } from "react-icons/bs";

const LoadingModal = ({
    showLoadingModal,
    ...props
}) => {
    return (
        <div>
            <Modal 
                show={showLoadingModal}
                centered
                backdrop="static"
                keyboard={false}
                {...props}>
                <Modal.Header>
                    <Modal.Title><BsStopwatch /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col className="text-center">
                            <Spinner animation="border" variant="primary" />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
};

export default LoadingModal;