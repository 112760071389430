import React, { useEffect, useState, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, InputGroup, FormControl, 
    Card, Badge, Modal, Form, OverlayTrigger, Popover,
    Spinner } from 'react-bootstrap';
import { AiOutlineEdit, AiOutlineDelete, AiOutlineVerticalAlignMiddle, 
    AiOutlineNumber, AiOutlineCheckSquare, AiOutlineCheckCircle } from 'react-icons/ai';
import { BiText } from 'react-icons/bi';
import { BsCalendar, BsTextareaT } from 'react-icons/bs';
import { IoMdAdd } from 'react-icons/io';
import { FaHeading, FaParagraph } from 'react-icons/fa';
import { HiSelector } from 'react-icons/hi';
import { MdEmail } from 'react-icons/md';
import { RiParentLine } from 'react-icons/ri';
import FormMatriculaEditHeader from './formMatriculaEditHeader';
import FormMatriculaEditInput from './formMatriculaEditInput';
import FormMatriculaEditSelect from './formMatriculaEditSelect';
import FormMatriculaEditParagraph from './formMatriculaEditParagraph';
import { nanoid } from "nanoid";
import DOMPurify from 'dompurify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import FormMatriculaGenerate from './formMatriculaGenerate';
import { useHistory } from 'react-router';
import Auth from '@aws-amplify/auth';
import API from '@aws-amplify/api';
import { onError } from '../libs/errorLib';

const mapStateToProps = state => ({
    institucionData: state.institucionData,
    userData: state.userAuthenticated,
    stateFormularioId: state.formularioId,
});

const mapDispatchToProps = dispatch => ({
    setFormularioId(formularioId){
        dispatch({
            type: "MATRICULAS_FORMULARIO_EDIT",
            formularioId
        })
    }
});

// const rqf = [
//     {
//         key: 1,
//         type: 'Header',
//         name: 'Nombre Formulario',
//         text: 'Nombre formulario',
//         mandatory: true,
//         editable: true,
//         class:['h2'],
//     },
//     {
//         key: 2,
//         type: 'Divider',
//         name: 'Divisor',
//         mandatory: true,
//         editable: false,
//     },
//     {
//         key: 23,
//         type: 'Paragraph',
//         name: 'Parrafo',
//         text: '<p>Bienvenido! Para comenzar, ingresa el correo electrónico del candidato</p>',
//         mandatory: true,
//         editable: true,
//     },
//     {
//         key: 8,
//         type: 'Input',
//         name: 'email',
//         label: 'email',
//         control_type: 'email',
//         mandatory: true,
//         editable: false,
//         required: true,
//     },
//     {
//         key: 24,
//         type: 'Divider',
//         name: 'Divisor',
//         mandatory: true,
//         editable: false,
//     },
//     {
//         key: 3,
//         type: 'Header',
//         name: 'Encabezado',
//         text: 'Datos estudiante',
//         mandatory: false,
//         editable: true,
//         class:['h4'],
//     },
//     {
//         key: 4,
//         type: 'Divider',
//         name: 'Divisor',
//         mandatory: false,
//         editable: false,
//     },
//     {
//         key: 5,
//         type: 'Input',
//         name: 'Campo de texto',
//         label: 'Nombre',
//         control_type: 'text',
//         mandatory: true,
//         editable: false,
//         required: true,
//     },
//     {
//         key: 6,
//         type: 'Input',
//         name: 'Campo de texto',
//         label: 'Apellido',
//         control_type: 'text',
//         mandatory: true,
//         editable: false,
//         required: true,
//     },
//     {
//         key: 7,
//         type: 'Input',
//         name: 'Campo de texto',
//         label: 'DNI',
//         control_type: 'text',
//         mandatory: true,
//         editable: false,
//         required: true,
//     },
//     {
//         key: 9,
//         type: 'Input',
//         name: 'Fecha',
//         label: 'Nacimiento',
//         control_type: 'date',
//         mandatory: true,
//         editable: false,
//         required: true,
//     },
//     {
//         key: 10,
//         type: 'Input',
//         name: 'Campo de texto',
//         label: 'Ciudad nacimiento',
//         control_type: 'text',
//         mandatory: false,
//         editable: true,
//         required: true,
//     },
//     {
//         key: 11,
//         type: 'Input',
//         name: 'Campo de texto',
//         label: 'País nacimiento',
//         control_type: 'text',
//         mandatory: false,
//         editable: true,
//         required: true,
//     },
//     {
//         key: 12,
//         type: 'Input',
//         name: 'Campo de texto',
//         label: 'Dirección',
//         control_type: 'text',
//         mandatory: false,
//         editable: true,
//         required: true,
//     },
//     {
//         key: 13,
//         type: 'Input',
//         name: 'Campo numérico',
//         label: 'Teléfono',
//         control_type: 'number',
//         mandatory: false,
//         editable: true,
//         required: true,
//     },
//     {
//         key: 14,
//         type: 'Divider',
//         name: 'Divisor',
//         mandatory: false,
//         editable: false,
//     },
//     {
//         key: 15,
//         type: 'Header',
//         name: 'Encabezado',
//         text: 'Datos acudientes',
//         mandatory: false,
//         editable: true,
//         class:['h4'],
//     },
//     {
//         key: 16,
//         type: 'Divider',
//         name: 'Divisor',
//         mandatory: false,
//         editable: false,
//     },
//     {
//         key: 22,
//         type: 'Parent',
//         name: 'Acudiente',
//         label: 'Acudiente 1',
//         mandatory: false,
//         editable: true,
//         required: true,
//         fields: [
//             {
//                 id: 2201,
//                 control_type: 'input',
//                 label: 'DNI',
//             },
//             {
//                 id: 2202,
//                 control_type: 'input',
//                 label: 'Apellido',
//             },
//             {
//                 id: 2203,
//                 control_type: 'input',
//                 label: 'Nombre',
//             },
//             {
//                 id: 2204,
//                 control_type: 'email',
//                 label: 'email',
//             },
//             {
//                 id: 2205,
//                 control_type: 'number',
//                 label: 'Teléfono',
//             },
//             {
//                 id: 2206,
//                 control_type: 'select',
//                 label: 'Vínculo',
//                 options: [
//                     {
//                         id: 220601,
//                         option: 'Madre',
//                         value: 'Madre' ,
//                     },
//                     {
//                         id: 220602,
//                         option: 'Padre',
//                         value: 'Padre' ,
//                     },
//                     {
//                         id: 220603,
//                         option: 'Otro',
//                         value: 'Otro' ,
//                     },
//                 ]
//             },
//             {
//                 id: 2207,
//                 control_type: 'checkbox',
//                 label: '¿Responsable de pagos?',
//             },
//         ]
//     },
//     {
//         key: 25,
//         type: 'Parent',
//         name: 'Acudiente',
//         label: 'Acudiente 2',
//         mandatory: false,
//         editable: true,
//         required: true,
//         fields: [
//             {
//                 id: 2501,
//                 control_type: 'input',
//                 label: 'DNI',
//             },
//             {
//                 id: 2502,
//                 control_type: 'input',
//                 label: 'Apellido',
//             },
//             {
//                 id: 2503,
//                 control_type: 'input',
//                 label: 'Nombre',
//             },
//             {
//                 id: 2504,
//                 control_type: 'email',
//                 label: 'email',
//             },
//             {
//                 id: 2505,
//                 control_type: 'number',
//                 label: 'Teléfono',
//             },
//             {
//                 id: 2506,
//                 control_type: 'select',
//                 label: 'Vínculo',
//                 options: [
//                     {
//                         id: 250601,
//                         option: 'Madre',
//                         value: 'Madre' ,
//                     },
//                     {
//                         id: 250602,
//                         option: 'Padre',
//                         value: 'Padre' ,
//                     },
//                     {
//                         id: 250603,
//                         option: 'Otro',
//                         value: 'Otro' ,
//                     },
//                 ]
//             },
//             {
//                 id: 2507,
//                 control_type: 'checkbox',
//                 label: '¿Responsable de pagos?',
//             },
//         ]
//     },
//     {
//         key: 26,
//         type: 'Divider',
//         name: 'Divisor',
//         mandatory: false,
//         editable: false,
//     },
//     {
//         key: 17,
//         type: 'Select',
//         name: 'Lista de seleccion',
//         label: 'Seleccione de la lista',
//         mandatory: false,
//         editable: true,
//         required: true,
//         options:[
//             {
//                 id: '17001',
//                 option: 'opcion1',
//                 value: '1'
//             }, 
//             {
//                 id: '17002',
//                 option: 'opcion2',
//                 value: '2'
//             },
//             {
//                 id: '17003',
//                 option: 'opcion3',
//                 value: '3'
//             },
//         ],
//     },
//     {
//         key: 18,
//         type: 'Checkbox',
//         name: 'Casillas de verificación',
//         label: 'Marque las opciones según requiera',
//         mandatory: false,
//         editable: true,
//         required: true,
//         options:[
//             {
//                 id: '18001',
//                 option: 'opcion1',
//                 value: '1',
//             }, 
//             {
//                 id: '18002',
//                 option: 'opcion2',
//                 value: '2'
//             },
//             {
//                 id: '18003',
//                 option: 'opcion3',
//                 value: '3'
//             },
//         ],
//     },
//     {
//         key: 19,
//         type: 'Radio',
//         name: 'Casillas de selección',
//         label: 'Marque la opción según requiera',
//         mandatory: false,
//         editable: true,
//         required: true,
//         options:[
//             {
//                 id: '19001',
//                 option: 'opcion1',
//                 value: '1',
//             }, 
//             {
//                 id: '19002',
//                 option: 'opcion2',
//                 value: '2'
//             },
//             {
//                 id: '19003',
//                 option: 'opcion3',
//                 value: '3'
//             },
//         ],
//     },
//     {
//         key: 20,
//         type: 'Textarea',
//         name: 'Campo de texto',
//         label: 'Acá podrá ingresar una respuesta/mayor información en varias lineas de texto',
//         mandatory: false,
//         editable: true,
//         required: true,
//     },
//     {
//         key: 21,
//         type: 'Paragraph',
//         name: 'Parrafo',
//         text: '<p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus cumque maxime illum reprehenderit? In, nemo? Sequi praesentium recusandae itaque maiores sint, id aliquam modi facilis molestiae architecto odit molestias nemo?</p>',
//         mandatory: false,
//         editable: true,
//     },
    
// ];

// const tb = [
//     {
//         key: 101,
//         icon: <FaHeading />,
//         type: 'Header',
//         name: 'Encabezado',
//         text: 'Título encabezado',
//         mandatory: false,
//         editable: true,
//         class:['h2'],
//     },
//     {
//         key: 201,
//         icon: <AiOutlineVerticalAlignMiddle />,
//         type: 'Divider',
//         name: 'Divisor',
//         mandatory: false,
//         editable: false,
//     },
//     {
//         key: 701,
//         icon: <BsTextareaT />,
//         type: 'Input',
//         name: 'Campo de texto',
//         label: 'Etiqueta',
//         control_type: 'text',
//         mandatory: false,
//         editable: true,
//         required: true,
//     },
//     {
//         key: 801,
//         icon: <MdEmail />,
//         type: 'Input',
//         name: 'email',
//         label: 'email',
//         control_type: 'email',
//         mandatory: false,
//         editable: true,
//         required: true,
//     },
//     {
//         key: 901,
//         icon: <BsCalendar />,
//         type: 'Input',
//         name: 'Fecha',
//         label: 'Fecha',
//         control_type: 'date',
//         mandatory: false,
//         editable: true,
//         required: true,
//     },
//     {
//         key: 1301,
//         icon: <AiOutlineNumber />,
//         type: 'Input',
//         name: 'Campo numérico',
//         label: 'Etiqueta',
//         control_type: 'number',
//         mandatory: false,
//         editable: true,
//         required: true,
//     },
//     {
//         key: 1701,
//         icon: <HiSelector />,
//         type: 'Select',
//         name: 'Lista de seleccion',
//         label: 'Seleccione...',
//         mandatory: false,
//         editable: true,
//         required: true,
//         options:[
//             {
//                 id: '001',
//                 option: 'opcion1',
//                 value: '1'
//             }, 
//             {
//                 id: '002',
//                 option: 'opcion2',
//                 value: '2'
//             },
//             {
//                 id: '003',
//                 option: 'opcion3',
//                 value: '3'
//             },
//         ],
//     },
//     {
//         key: 1801,
//         icon: <AiOutlineCheckSquare />,
//         type: 'Checkbox',
//         name: 'Casillas de verificación',
//         label: 'Marque las opciones...',
//         mandatory: false,
//         editable: true,
//         required: true,
//         options:[
//             {
//                 id: '001',
//                 option: 'opcion1',
//                 value: '1',
//             }, 
//             {
//                 id: '002',
//                 option: 'opcion2',
//                 value: '2'
//             },
//             {
//                 id: '003',
//                 option: 'opcion3',
//                 value: '3'
//             },
//         ],
//     },
//     {
//         key: 1901,
//         icon: <AiOutlineCheckCircle />,
//         type: 'Radio',
//         name: 'Casillas de selección',
//         label: 'Marque una opción...',
//         mandatory: false,
//         editable: true,
//         required: true,
//         options:[
//             {
//                 id: '001',
//                 option: 'opcion1',
//                 value: '1',
//             }, 
//             {
//                 id: '002',
//                 option: 'opcion2',
//                 value: '2'
//             },
//             {
//                 id: '003',
//                 option: 'opcion3',
//                 value: '3'
//             },
//         ],
//     },
//     {
//         key: 2001,
//         icon: <BiText />,
//         type: 'Textarea',
//         name: 'Area de texto',
//         label: 'Área de texto de multiples lineas',
//         mandatory: false,
//         editable: true,
//         required: true,
//     },
//     {
//         key: 2101,
//         icon: <FaParagraph />,
//         type: 'Paragraph',
//         name: 'Parrafo',
//         text: '<p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatibus cumque maxime illum reprehenderit? In, nemo? Sequi praesentium recusandae itaque maiores sint, id aliquam modi facilis molestiae architecto odit molestias nemo?</p>',
//         mandatory: false,
//         editable: true,
//     },
//     {
//         key: 2201,
//         icon: <RiParentLine />,
//         type: 'Parent',
//         name: 'Acudiente',
//         label: 'Datos acudiente',
//         mandatory: false,
//         editable: true,
//         required: true,
//         fields: [
//             {
//                 id: 2201,
//                 control_type: 'input',
//                 label: 'DNI',
//             },
//             {
//                 id: 2202,
//                 control_type: 'input',
//                 label: 'Apellido',
//             },
//             {
//                 id: 2203,
//                 control_type: 'input',
//                 label: 'Nombre',
//             },
//             {
//                 id: 2204,
//                 control_type: 'email',
//                 label: 'email',
//             },
//             {
//                 id: 2205,
//                 control_type: 'number',
//                 label: 'Teléfono',
//             },
//             {
//                 id: 2206,
//                 control_type: 'select',
//                 label: 'Vínculo',
//                 options: [
//                     {
//                         id: 220601,
//                         option: 'Madre',
//                         value: 'Madre' ,
//                     },
//                     {
//                         id: 220602,
//                         option: 'Padre',
//                         value: 'Padre' ,
//                     },
//                     {
//                         id: 220603,
//                         option: 'Otro',
//                         value: 'Otro' ,
//                     },
//                 ]
//             },
//             {
//                 id: 2207,
//                 control_type: 'checkbox',
//                 label: '¿Responsable de pagos?',
//             },
//         ]
//     },
// ];

const FormMatricula = ({institucionData, userData, stateFormularioId, setFormularioId}) => {

    const [showEditElementModal, setShowEditElementModal] = useState(false);
    const [showPreviewFormModal, setShowPreviewFormModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showErrorModal, setShowErrorModal] = useState(false);

    //requirementFields almacena los campos obligatorios y mínimos que necesita rocket en el formulario de matricula
    const [requirementFields, setRequirementFields] = useState([]);
    
    const isFirstRender = useRef(true);
    const formElementToEdit = useRef({});
    const formElementToEditIndex = useRef(null);
    const errorContent = useRef(null);
    const toolbox = useRef([]);
    const formularioId = useRef(null);

    const history = useHistory();

    const editElement = (index) => {
        formElementToEdit.current = requirementFields[index];
        formElementToEditIndex.current = index;
        setShowEditElementModal(true);
    };

    const deleteElement = (index) => {
        const localFields = [...requirementFields];
        localFields.splice(index, 1);
        setRequirementFields(localFields);
    };

    const handleCloseEditElementModal = () => {
        setShowEditElementModal(false);
    };

    const handleClosePreviewModal = () => {
        setShowPreviewFormModal(false);
    };

    const onCompleteEditElement = (elementEdited) => {
        formElementToEdit.current = {};
        formElementToEditIndex.current = null;
        setShowEditElementModal(false);
    };

    const reorderRequiredFields = (sourceIndex, destIndex) => {
        const localFields = [...requirementFields];
        const [removed] = localFields.splice(sourceIndex, 1);
        localFields.splice(destIndex, 0, removed);
        setRequirementFields(localFields);
    };

    const handleAddElement = (index) => {
        if(toolbox.current.length > 0){
            const newElement = {
                key: nanoid(3),
                type: toolbox.current[index].type,
                name: toolbox.current[index].name,
                text: toolbox.current[index].text,
                label: toolbox.current[index].label,
                control_type: toolbox.current[index].control_type,
                mandatory: toolbox.current[index].mandatory,
                editable: toolbox.current[index].editable,
                required: toolbox.current[index].required,
                class:toolbox.current[index].class,
                options: toolbox.current[index].options,
                fields: toolbox.current[index].fields,
            };
            const localFields = [...requirementFields];
            localFields.push(newElement);
            setRequirementFields(localFields);
        }
    }; 

    const popover = (
        <Popover id="popover-elements">
            <Popover.Title as="h3">Elementos</Popover.Title>
            <Popover.Content>
                <Row className='justify-content-center'>
                {toolbox.current.map((k, index) => {
                    var icon = null;
                    switch (k.icon) {
                        case 'FaHeading':
                            icon = <FaHeading />;
                            break;
                        case 'AiOutlineVerticalAlignMiddle':
                            icon = <AiOutlineVerticalAlignMiddle />;
                            break;
                        case 'BsTextareaT':
                            icon = <BsTextareaT />;
                            break;
                        case 'MdEmail':
                            icon = <MdEmail />;
                            break;
                        case 'BsCalendar':
                            icon = <BsCalendar />;
                            break;
                        case 'AiOutlineNumber':
                            icon = <AiOutlineNumber />;
                            break;
                        case 'HiSelector':
                            icon = <HiSelector />;
                            break;
                        case 'AiOutlineCheckSquare':
                            icon = <AiOutlineCheckSquare />;
                            break;
                        case 'AiOutlineCheckCircle':
                            icon = <AiOutlineCheckCircle />;
                            break;
                        case 'BiText':
                            icon = <BiText />;
                            break;
                        case 'FaParagraph':
                            icon = <FaParagraph />;
                            break;
                        case 'RiParentLine':
                            icon = <RiParentLine />;
                            break;
                        default:
                            break;
                    }
                    return (
                        <Col className='p-1 Align-self-end' xs={'auto'} key={index}>
                            <Button className='align-self-end' onClick={() => handleAddElement(index)} variant='light'>{icon} {k.name}</Button>
                        </Col>
                    );
                })}
                </Row>
            </Popover.Content>
        </Popover>
    );
      
    const handleClose = () => {
        history.push("/");
    };

    const handleSave = async() => {
        //Validar si es un formulario ya existente, en ese caso 
        //se debe actualizar y no crear uno nuevo
        setIsLoading(true);
        if(formularioId.current){
            try {
                const updateFormData = {
                    institucionId: institucionData.institucionId,
                    campos: requirementFields,
                };
                const respond = await API.put('rocket','/matriculas/formularios/'+formularioId.current,{
                    body: updateFormData
                });
                if(!respond.result){
                    onError(respond);
                    const err = 'No se puede actualizar el formulario en este momento, intentalo de nuevo mas tarde';
                    throw (err);
                }
                setIsLoading(false);
            } catch (error) {
                onError(error);
                handleErrors(error);
            }
        } else {
            try {
                const formData = {
                    institucionId: institucionData.institucionId,
                    campos: requirementFields,
                };
                const respond = await API.post('rocket','/crearMatriculaFormulario',{
                    body: formData,
                });
                if(!respond.result){
                    onError(respond);
                    const err = 'No se puede guardar el formulario en este momento, intentalo de nuevo mas tarde';
                    throw (err);
                }
                console.log(respond);
                formularioId.current = respond.description;
                setIsLoading(false);
            } catch (error) {
                onError(error);
                handleErrors(error);
            }
        }
    };

    const handleErrors = useCallback((errorMSG)=>{
        if(errorMSG){
            errorContent.current = errorMSG;
            setShowErrorModal(true);
        }
    },[]);

    useEffect(()=>{
        //LLamad a API para cargar campos por defecto del formulario
        const getFormFields = async() => {
            try {
                const qsp = {
                    institucionId: institucionData.institucionId,
                };
                if(stateFormularioId){
                    qsp.formularioId = stateFormularioId;
                    formularioId.current = stateFormularioId;
                }
                const localRF = await API.get('rocket', '/getMatriculaFormulario', {
                    queryStringParameters: qsp,
                });
                if(localRF.result){
                    setRequirementFields(localRF.description.requiredFields);
                    toolbox.current = localRF.description.toolbox;
                    setIsLoading(false);
                } else {
                    onError(localRF.description);
                    const err = 'No se puede mostrar/crear el formulario en este momento, intentalo de nuevo mas tarde';
                    throw (err);
                }
            } catch (error) {
                onError(error);
                handleErrors('No se puede mostrar/crear el formulario en este momento, intentalo de nuevo mas tarde');
            }
        };
        const init = async() => {
            try {
                await Auth.currentSession();
                getFormFields();
            } catch (error) {
                console.log('Sin sesion: ' + error);
                history.push('/');
            }
        };
        if(isFirstRender.current){
            isFirstRender.current = false;
            init();
        }
    },[handleErrors, institucionData, history, stateFormularioId]);

    useEffect(()=>{
        return function cleanUp(){
            setFormularioId(null);
        }
    },[setFormularioId]);

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
        history.push('/');
    };
    
    return (
        <div>
            {showErrorModal && 
            <Modal 
                show={showErrorModal}
                onHide={handleCloseErrorModal}
                size={'lg'}
            >
                <Modal.Header closeButton>
                    Formularios
                </Modal.Header>
                <Modal.Body>
                    {errorContent.current}
                </Modal.Body>
            </Modal>
            }
            {showPreviewFormModal && 
            <Modal 
                show={showPreviewFormModal}
                onHide={handleClosePreviewModal}
                size={'lg'}
            >
                <Modal.Header closeButton>
                    Previsualización formulario de matricula
                </Modal.Header>
                <Modal.Body>
                    <FormMatriculaGenerate 
                        preview={true} 
                        fields={requirementFields}
                    />
                </Modal.Body>
            </Modal>}
            {showEditElementModal && 
            <Modal
                show={showEditElementModal}
                onHide={handleCloseEditElementModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>Editando elemento</Modal.Header>
                <Modal.Body>
                    {formElementToEdit.current.type === 'Header' && <FormMatriculaEditHeader onComplete={onCompleteEditElement} elementToEdit={formElementToEdit.current}/>}
                    {(formElementToEdit.current.type === 'Input' || formElementToEdit.current.type === 'Textarea' || formElementToEdit.current.type === 'Parent') && <FormMatriculaEditInput onComplete={onCompleteEditElement} elementToEdit={formElementToEdit.current}/>}
                    {(formElementToEdit.current.type === 'Select' || formElementToEdit.current.type === 'Checkbox' || formElementToEdit.current.type === 'Radio') && <FormMatriculaEditSelect onComplete={onCompleteEditElement} elementToEdit={formElementToEdit.current}/>}
                    {formElementToEdit.current.type === 'Paragraph' && <FormMatriculaEditParagraph onComplete={onCompleteEditElement} elementToEdit={formElementToEdit.current}/>}
                </Modal.Body>
            </Modal>}
            <Row className='pt-2 fixed-top'>
                <Col xs={'auto'} className='ml-3'>
                    <Button disabled={isLoading} onClick={()=>handleSave()} variant='outline-success'>Guardar</Button>
                </Col>
                <Col xs={'auto'} className='ml-3'>
                    <Button disabled={isLoading} onClick={()=>setShowPreviewFormModal(true)} variant='outline-info'>Previsualizar</Button>
                </Col>
                <Col className='ml-3 text-right'>
                    <Button onClick={()=>handleClose()} variant='outline-primary'>Cerrar</Button>
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col>
                    <hr></hr>
                </Col>
            </Row>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-1 pt-1">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <div>
                    <DragDropContext onDragEnd={(result)=>{
                        const {source, destination} = result;
                        if(!destination){
                            return;
                        }
                        if(source.index === destination.index && source.droppableId === destination.droppableId){
                            return;
                        }
                        reorderRequiredFields(source.index, destination.index);
                    }}>
                        <Row>
                            <Droppable droppableId='fields'>
                                {(droppableProvided)=>(
                                <Col 
                                    {...droppableProvided.droppableProps}
                                    ref={droppableProvided.innerRef}
                                >
                                    {requirementFields.map((x, index) => {
                                        var data = '';
                                        switch (x.type) {
                                            case 'Divider':
                                                data = <hr></hr>;
                                                break;
                                            case 'Header':
                                                if(x.class[0] === 'h1'){
                                                    data = <h1>{x.text}</h1>;
                                                } else if(x.class[0] === 'h2'){
                                                    data = <h2>{x.text}</h2>;
                                                } else if(x.class[0] === 'h3'){
                                                    data = <h3>{x.text}</h3>;
                                                } else if(x.class[0] === 'h4'){
                                                    data = <h4>{x.text}</h4>;
                                                } else {
                                                    data = x.text;
                                                }                                
                                                break;
                                            case 'Input':
                                                data = 
                                                    <InputGroup className="mb-3">
                                                        <InputGroup.Prepend>
                                                            <InputGroup.Text >{x.label}</InputGroup.Text>
                                                        </InputGroup.Prepend>
                                                        <FormControl
                                                            type={x.control_type}
                                                        />
                                                    </InputGroup>
                                                break;
                                            case 'Select':
                                                data = 
                                                    <Form.Group >
                                                        <Form.Label>{x.label}</Form.Label>
                                                        <Form.Control as="select">
                                                            {x.options.map((y)=>{
                                                                return <option key={y.id} value={y.value}>{y.option}</option>
                                                            })}
                                                        </Form.Control>
                                                    </Form.Group>
                                                break;
                                            case 'Checkbox':
                                            case 'Radio':
                                                data = 
                                                    <Form.Group >
                                                        <Form.Label>{x.label}</Form.Label>
                                                        {x.options.map((y)=>{
                                                            return (
                                                                <Form.Check
                                                                    key={y.id} 
                                                                    value={y.value}
                                                                    label={y.option}
                                                                    name={x.key}
                                                                    type={x.type.toLowerCase()}
                                                                />
                                                            )
                                                        })}
                                                    </Form.Group>
                                                break;
                                            case 'Textarea':
                                                data = 
                                                    <Form.Group >
                                                        <Form.Label>{x.label}</Form.Label>
                                                        <Form.Control 
                                                            as="textarea" 
                                                            rows={3} 
                                                        />
                                                    </Form.Group>
                                                break;
                                            case 'Paragraph':
                                                data = 
                                                    x.text
                                                break;
                                            case 'Parent':
                                                data = 
                                                    <div>
                                                        <Row>
                                                            <Col>
                                                            <Form.Label>{x.label}</Form.Label>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {x.fields.map((y)=>{
                                                                switch (y.control_type) {
                                                                    case 'input':
                                                                    case 'email':
                                                                    case 'number':
                                                                        return(
                                                                            <Col xs={6} key={y.id}>
                                                                                <InputGroup className="mb-3">
                                                                                    <InputGroup.Prepend>
                                                                                        <InputGroup.Text >{y.label}</InputGroup.Text>
                                                                                    </InputGroup.Prepend>
                                                                                    <FormControl
                                                                                        type={y.control_type}
                                                                                    />
                                                                                </InputGroup>
                                                                            </Col>
                                                                        )
                                                                    case 'select':
                                                                        return (
                                                                            <Col xs={6} key={y.id}>
                                                                                <InputGroup className="mb-3">
                                                                                    <InputGroup.Prepend>
                                                                                        <InputGroup.Text >{y.label}</InputGroup.Text>
                                                                                    </InputGroup.Prepend>
                                                                                    <FormControl 
                                                                                        as='select'
                                                                                    >
                                                                                        {y.options.map((z)=>{
                                                                                            return <option key={z.id} value={z.value}>{z.option}</option>
                                                                                        })}
                                                                                    </FormControl>
                                                                                </InputGroup>
                                                                            </Col>
                                                                        )
                                                                    case 'checkbox':
                                                                        return (
                                                                            <Col xs={6} key={y.id}>
                                                                                <Form.Group >
                                                                                    <Form.Check 
                                                                                        type={y.control_type}
                                                                                        name={y.id}
                                                                                        label={y.label}
                                                                                    />
                                                                                </Form.Group>
                                                                            </Col>
                                                                        )
                                                                    default:
                                                                        return <></>;
                                                                }
                                                            })}
                                                        </Row>
                                                    </div>
                                                break;
                                            default:
                                                break;
                                        }
                                        return (
                                            <Draggable 
                                                key={x.key} 
                                                draggableId={String(x.key)}
                                                index={index}
                                                isDragDisabled={x.mandatory}
                                            >
                                                {(draggableProvided)=>(
                                                    <Card 
                                                        className='hide-element-container'
                                                        {...draggableProvided.draggableProps}
                                                        ref={draggableProvided.innerRef}
                                                        {...draggableProvided.dragHandleProps}
                                                    >
                                                        <Card.Body>
                                                            <Card.Title >
                                                                <Row className='hide-element'>
                                                                    <Col>
                                                                        <Badge pill variant="secondary">{x.name}</Badge>
                                                                    </Col>
                                                                    {x.editable && <Col xs={'auto'}>
                                                                        <Button variant='light' onClick={() => editElement(index)} className='action-button'><AiOutlineEdit /></Button>
                                                                    </Col>}
                                                                    {!x.mandatory && <Col xs={'auto'}>
                                                                        <Button variant='light' onClick={() => deleteElement(index)} className='action-button'><AiOutlineDelete /></Button>
                                                                    </Col>}
                                                                </Row>
                                                            </Card.Title>
                                                                {x.type === 'Paragraph' ? 
                                                                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data) }}/>
                                                                :
                                                                data}
                                                            
                                                        </Card.Body>
                                                    </Card>
                                                )}
                                            </Draggable>
                                        )
                                    })}
                                    {droppableProvided.placeholder}
                                </Col>)
                                }
                            </Droppable>
                        </Row>
                    </DragDropContext>
                    <Row>
                        <Col className='text-center mt-1'>
                            <OverlayTrigger 
                                trigger="focus"
                                overlay={popover}
                            >
                                <Button variant='light'><IoMdAdd size={'2rem'}/></Button>
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    <hr></hr>
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(FormMatricula);