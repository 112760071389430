import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Row, Col, Spinner, Modal, Button } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { connect } from 'react-redux';
import { onError } from '../libs/errorLib';
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { MdAdd } from 'react-icons/md';

const mapStateToProps = state => ({
    userData: state.userAuthenticated,
});

const mapDispatchToProps = dispatch => ({});

const MatriculasCandidatos = ({userData}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);

    const isFirstRender = useRef(true);
    const errorContent = useRef('');
    const matriculasData = useRef([]);

    const columns = useRef([
        {
            dataField: 'candidato',
            text: 'Candidato',
            headerStyle: () => {
                return { 
                    overflow: 'hidden',
                    textOverflow: 'ellipsis', 
                }
            },
            style: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        },
    ]);

    const handleErrors = (msg) => {
        setShowErrorModal(false);
        errorContent.current = msg;
        setShowErrorModal(true);
    };

    const getCandidatos = useCallback(async()=>{
        matriculasData.current = [];
        setIsLoading(true);
        try {
            const qsp = {
                institucionId: userData.institucionId,
                get: ['candidato','matriculaId']
            };
            const mdata = await API.get('rocket','/getMatriculas',{
                queryStringParameters: qsp,
            });
            if(!mdata.result){
                handleErrors('Error obteniendo las solicitudes de matricula');
                const err = {
                    error: 'GetMatriculasException',
                    description: mdata
                };
                throw (err)
            }
            matriculasData.current = mdata.description;
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            onError(error);
        }
    },[userData]);

    useEffect(()=>{
        
        if(isFirstRender.current){
            isFirstRender.current = false;
            getCandidatos();
        }
    },[getCandidatos]);

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    };

    return (
        <div>
            {showErrorModal && 
            <Modal 
                show={showErrorModal}
                onHide={handleCloseErrorModal}
                size={'lg'}
            >
                <Modal.Header closeButton>
                    Candidatos
                </Modal.Header>
                <Modal.Body>
                    {errorContent.current}
                </Modal.Body>
            </Modal>
            }
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-1 pt-1">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <div>
                    <Row className='mt-3 mb-1'>
                        <Col className='text-right'>
                            <Button title='Crear nuevo' onClick={()=>console.log('Btn')} variant="outline-success"><MdAdd /></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BootstrapTable 
                                keyField="matriculaId"
                                data={matriculasData.current}
                                columns={columns.current}
                                noDataIndication={'No se encontrarón solicitudes de matricula'}
                                pagination={paginationFactory()}
                            />
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(MatriculasCandidatos);