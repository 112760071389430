import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./containers/login";
import Rocket from "./containers/rocket";
import Estudiantes from "./containers/estudiantes";
import Docentes from "./containers/docentes";
import Coordinador from "./containers/coordinador";
import FormMatricula from "./components/formMatricula";
import FormMatriculaGenerate from "./components/formMatriculaGenerate";

export default function Routes (){
    
    return (
        <Switch>
            <Route exact path="/">
                <Rocket />
            </Route>
            <Route exact path="/login">
                <Login />
            </Route>
            <Route exact path="/estudiantes">
                <Estudiantes />
            </Route>
            <Route exact path="/docentes">
                <Docentes />
            </Route>
            <Route exact path="/coordinador">
                <Coordinador />
            </Route>
            <Route exact path="/formulariomatricula">
                <FormMatricula />
            </Route>
            <Route exact path="/matriculas">
                <FormMatriculaGenerate />
            </Route>
        </Switch>
    );
}