import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, InputGroup, Button, Dropdown, DropdownButton, ButtonGroup, Modal } from "react-bootstrap";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ModalCambiarEstado from "../components/modalCambiarEstado";
import ModalEliminar from "../components/modalEliminar";
import ModalSetPassword from "../components/modalSetPassword"
import { connect } from "react-redux";
import LoadingModal from "../components/loadingModal";
import { API } from "aws-amplify";
import { MdErrorOutline } from "react-icons/md";
import ModalInscripciones from "../components/modalInscripciones";
import { integraUsuarioCroom } from "../libs/integra";

const mapStateToProps = (state, props) => ({
    institucionId: state.institucionData.institucionId,
    institucionAlias: state.institucionData.alias,
    rolesData: state.rolesData,
    setEditarUsuario: props.setEditarUsuario,
});

const mapDispatchToProps = dispatch => ({
    setUsuarioData(usuarioData){
        dispatch({
            type: "CARGAR_USUARIO_EDITAR",
            usuarioData
        })
    }
});

const Usuarios = ({setUsuarioData, institucionId, institucionAlias, rolesData, setEditarUsuario}) => {
    const [showModalCambiarEstado, setShowModalCambiarEstado] = useState(false);
    const [showModalEliminar, setShowModalEliminar] = useState(false);
    const [showModalSetPassword, setShowModalSetPassword] = useState(false);
    const [filtroBusqueda, setFiltroBusqueda] = useState("1");
    const [bootstrapTableMsg, setBootstrapTableMsg] = useState("Sin usuarios para mostrar");
    const cambiarUsuarioData = useRef(null);
    const [rolSelected, setRolSelected] = useState("0");
    const [searchCriteria,setSearchCriteria] = useState("");
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [tableData, setTableData] = useState([]);
    const msgModal = useRef("");
    const alertModalTitle = useRef(<MdErrorOutline />);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showModalInscripciones, setShowModalInscripciones] = useState(false);
    const usuarioDataSelected = useRef([]);
    const rolDocenteId = useRef("");
    const rolEstudianteId = useRef("");
    const rolManagerId = useRef("");
    const userToSetPass = useRef(null);

    useEffect(()=>{
        for (let index = 0; index < rolesData.length; index++) {
            const element = rolesData[index];
            switch (element.rol) {
                case "docente":
                    rolDocenteId.current = element.rolId;
                    break;
                case "estudiante":
                    rolEstudianteId.current = element.rolId;
                    break;
                case "manager":
                    rolManagerId.current = element.rolId;
                    break;
                default:
                    break;
            }
        }
    },[rolesData])

    const columns = useRef([
        {
            dataField: "identificacion", 
            text: "Identificación",
            headerStyle: () => {
                return { width: '20%' }
            }
        },
        {
            dataField: "nombre", 
            text: "Nombre",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "apellido", 
            text: "Apellido",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "estado", 
            text: "Estado",
            headerStyle: () => {
                return { width: '15%', textAlign: "right" }
            },
            sort: true,
            style: {
                textAlign: "right"
            }
        }
    ]);

    const handleUserChange = (usuario) => {
        cambiarUsuarioData.current = usuario;
        setShowModalCambiarEstado(true);
    };

    const handleDeleteUser = (usuario) => {
        cambiarUsuarioData.current = usuario;
        setShowModalEliminar(true);
    };

    const handleEditarUsuario = (usuarioData) => {
        //console.log('En editar usuario');
        setShowLoadingModal(true);
        setUsuarioData(usuarioData);
        setEditarUsuario(2);
    };

    const handleEnabled = () =>{
        switch (filtroBusqueda) {
            case "3":
                return searchCriteria.includes("@");
            case "4":
                return rolSelected !== "0";
            default:
                return searchCriteria.length > 3;
        }
    }

    const handleError = (exception) =>{
        switch (exception.code) {
            case "UnableSearchUser":
                msgModal.current = "No es posible buscar este usuario. Intentalo de nuevo, si el problema persiste, por favor ponte en contacto con nosotros.";
                break;
            default:
                msgModal.current = "Error en la aplicación. Intentalo de nuevo, si el problema persiste, comunicate con nosotros.";
                break;
        }
        setShowAlertModal(true);
    }

    const blankFields = () => {
        setShowLoadingModal(false);
    }

    const handleIntegrationRequest = (userData) => {
        userData.institucionAlias = institucionAlias;
        integraUsuarioCroom(userData);
    }

    const handleCourseParticipants = (usuarioData) => {
        usuarioDataSelected.current = usuarioData
        setShowModalInscripciones(true);
    };

    const handleSetPassword = (usuarioData) => {
        userToSetPass.current = usuarioData
        setShowModalSetPassword(true);
    };

    const handleSearch = () => {
        setShowLoadingModal(true);
        setTableData([]);
        API.get("rocket", "/getUsers", {
            "queryStringParameters": {
                institucionId: institucionId,
                filtroBusqueda: filtroBusqueda,
                searchCriteria: (filtroBusqueda === 4 ? rolSelected : searchCriteria.trim().toLowerCase())
            }
        }).then(value=>{
            switch (value.result) {
                case "success":
                    const tableDataTemp = [];
                    for (let index = 0; index < value.description.length; index++) {
                        const element = value.description[index];
                        if (element.rol !== rolManagerId.current){
                            var inscripciones = "";
                            var integracion = "";
                            if (element.rol === rolEstudianteId.current || element.rol === rolDocenteId.current){
                                inscripciones = <Dropdown.Item onClick={()=>handleCourseParticipants(element)} eventKey="2">Ver inscripciones</Dropdown.Item>
                                integracion = <Dropdown.Item onClick={()=>handleIntegrationRequest(element)} eventKey="5">Integración</Dropdown.Item>;
                            }
                            const userData = {
                                usuarioId: element.usuarioId,
                                identificacion: 
                                    <DropdownButton variant="link" as={ButtonGroup} title={element.identificacion} id={element.identificacion}>
                                        <Dropdown.Item onClick={()=>handleEditarUsuario(element)} eventKey="1">Editar</Dropdown.Item>
                                        <Dropdown.Item onClick={()=>handleSetPassword(element)} eventKey="6">Cambiar clave</Dropdown.Item>
                                        {inscripciones}
                                        {integracion}
                                        <Dropdown.Item onClick={()=>handleUserChange(element)} eventKey="3">Activar/Deshabilitar</Dropdown.Item>
                                        <Dropdown.Divider/>
                                        <Dropdown.Item onClick={()=>handleDeleteUser(element)} eventKey="4">Eliminar usuario</Dropdown.Item>
                                    </DropdownButton>,
                                nombre: element.nombre,
                                apellido: element.apellido,
                                estado: (element.estado ? "Activo" : "Deshabilitado")
                            };
                            tableDataTemp.push(userData);
                        }
                    }
                    setTableData(tableDataTemp);
                    break;
                case "fail":
                    console.log(value);
                    if (value.description === "no se encontraron usuarios"){
                        setBootstrapTableMsg("No se encontro el usuario");
                    } else {
                        const err = {
                            code: "UnableSearchUser"
                        }
                        handleError(err);
                    }
                    blankFields();
                    break;
                default:
                    break;
            }
            blankFields();
        }).catch(error=>{
            console.log(error);
            handleError("error");
            blankFields();
        })
    }
    
    return (
        <div>
            <ModalInscripciones 
                show={showModalInscripciones}
                setShow={setShowModalInscripciones}
                dataData={usuarioDataSelected.current} 
                inscripcionTypeFlag={true}
                manageInscripcionesFlag={false}
            />
            <Modal 
                show={showAlertModal}
                onHide={()=>setShowAlertModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>
            <LoadingModal show={showLoadingModal} />
            {cambiarUsuarioData.current ? 
                <ModalCambiarEstado 
                    setShow={setShowModalCambiarEstado}
                    show={showModalCambiarEstado}
                    elementData={cambiarUsuarioData.current}
                    object='usuarios'
                ></ModalCambiarEstado>
            :
                <></>
            }
            {cambiarUsuarioData.current ? 
                <ModalEliminar 
                    setShow={setShowModalEliminar}
                    show={showModalEliminar}
                    elementData={cambiarUsuarioData.current}
                    object='usuarios'
                ></ModalEliminar>
            :
                <></>
            }
            {userToSetPass.current ? 
                <ModalSetPassword 
                    setShow={setShowModalSetPassword}
                    show={showModalSetPassword}
                    elementData={userToSetPass.current}
                ></ModalSetPassword>
            :
                <></>
            }
            <h4>Encontrar usuarios</h4><hr></hr>
            <Col>
                <Row>
                    <Col sm={"auto"}>
                        <Form.Label className="mr-sm-2" htmlFor="opciones-busqueda" srOnly>
                            Opciones de busqueda
                        </Form.Label>
                        <Form.Control
                            as="select"
                            className="mr-sm-2"
                            id="opciones-busqueda"
                            value={filtroBusqueda}
                            onChange={e=>setFiltroBusqueda(e.target.value)}
                            custom
                        >
                            <option value="1">Nombre</option>
                            <option value="2">Apellido</option>
                            <option value="3">email</option>
                            <option value="4">rol</option>
                            <option value="5">Identificación</option>
                        </Form.Control>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            {(filtroBusqueda === "4" ?
                                <Form.Control
                                    as="select"
                                    className="mr-sm-2"
                                    id="roles"
                                    value={rolSelected}
                                    onChange={e=>setRolSelected(e.target.value)} 
                                    custom
                                >
                                    <option value="0">Seleccione rol</option>
                                {rolesData.map(value=>{
                                    return (
                                            value.rol==="manager" 
                                            ? "" 
                                            : <option key={value.rolId} value={value.rolId}>{value.rol}</option>
                                    )
                                })}
                                </Form.Control>
                            :
                                <Form.Control
                                    autoFocus
                                    placeholder="Criterios de busqueda"
                                    aria-label="Criterios de busqueda"
                                    aria-describedby="basic-addon2"
                                    value={searchCriteria}
                                    onChange={e=>setSearchCriteria(e.target.value)}
                                />
                            )}
                            <InputGroup.Append>
                                <Button disabled={!handleEnabled()} onClick={()=>handleSearch()} variant="outline-secondary">Buscar</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
            </Col>
            <Row>
                <Col>
                    <Col>
                        <BootstrapTable
                            keyField="usuarioId"
                            data={tableData}
                            columns={columns.current}
                            pagination={paginationFactory()}
                            noDataIndication={bootstrapTableMsg}
                        />
                    </Col>
                </Col>
            </Row>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Usuarios);