import React, { useState, useRef, useEffect, useCallback } from "react";
import { Row, Col, Spinner, Button, Modal, InputGroup, FormControl, Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdRefresh, MdAdd, MdNavigateNext, MdNavigateBefore, MdDelete, MdErrorOutline } from "react-icons/md";
import cellEditFactory from 'react-bootstrap-table2-editor';
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { useHistory } from "react-router";
import LoaderButton from "../components/loaderButton";

const mapStateToProps = state => ({
    userData: state.userAuthenticated
});

const mapDispatchToProps = dispatch => ({});

const RegistrarEgresos = ({userData}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [textButton, setTextButton] = useState("Guardar");
    const [startDate, setStartDate] = useState(new Date());
    const tmpStartDate = useRef(new Date());
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showAddEgresoModal, setShowAddEgresoModal] = useState(false);
    const [isEgSelected, setIsEgSelected] = useState(false);
    const [descripcion, setDescripcion] = useState("");
    const [costo, setCosto] = useState(0);

    const bootstrapTableMsg = useRef("No hay datos que mostrar");
    const alertModalTitle = useRef(<MdErrorOutline />);
    const msgModal = useRef("");
    const tableData = useRef([]);
    const egresoSelected = useRef("");

    const history = useHistory();

    const columns = useRef([
        {
            dataField: "descripcion", 
            text: "Descripcion",
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "costo", 
            text: "Costo",
            sort: true,
            headerStyle: () => {
                return { width: '15%', textAlign: "right" }
            },
            style: {
                textAlign: "right"
            }
        },
    ]);

    const getEgresos = useCallback(async()=>{
        tableData.current = [];
        setIsEgSelected(false);
        const periodoInit = Date.parse((tmpStartDate.current.getMonth() + 1)+"/"+1+"/"+tmpStartDate.current.getFullYear());
        const monthLastDay = new Date(tmpStartDate.current.getFullYear(),(tmpStartDate.current.getMonth()+1),0).getDate();
        const periodoEnd = Date.parse((tmpStartDate.current.getMonth() + 1)+"/"+monthLastDay+"/"+tmpStartDate.current.getFullYear());
        const qsp = {
            periodoInit: periodoInit,
            periodoEnd: periodoEnd,
            institucionId: userData.institucionId,
        };
        try{
            const response = await API.get("rocket", "/getEgresos", {
                queryStringParameters: qsp
            });
            if (response.result === "success"){
                tableData.current = response.description;
            } else {
                console.log(response);
            }
            setIsLoading(false);
        } catch(error){
            console.log(error);
            setIsLoading(false);
        }
    },[userData]);

    useEffect(()=>{
        if(userData.rolName === "financiero" || userData.rolName === "manager"){
            if(isFirstRender){
                getEgresos();
                setIsFirstRender(false);
            }
        } else {
            history.push("/");
        }
    },[history, userData, getEgresos, isFirstRender]);

    const handlePreviousMonth = () =>{
        setIsLoading(true);
        var previousYear = 0;
        var previousMonth = 1;
        if (startDate.getMonth() === 0){
            previousMonth = -11;
            previousYear = 1;
        }
        const newDate = Date.parse(((startDate.getMonth() + 1) - previousMonth)+"/"+startDate.getDate()+"/"+(startDate.getFullYear() - previousYear));
        tmpStartDate.current = new Date(newDate);
        setStartDate(new Date(newDate));
        getEgresos();
    };

    const handleNextMonth = () =>{
        setIsLoading(true);
        var nextYear = 0;
        var nextMonth = 1;
        if (startDate.getMonth() === 11){
            nextMonth = -11;
            nextYear = 1;
        }
        const newDate = Date.parse(((startDate.getMonth() + 1) + nextMonth)+"/"+startDate.getDate()+"/"+(startDate.getFullYear() + nextYear));
        tmpStartDate.current = new Date(newDate);
        setStartDate(new Date(newDate));
        getEgresos();
    };

    const handleOnSelect = (row, isSelect) => {
        if (isSelect){
            egresoSelected.current = row;
            setIsEgSelected(true);
        } else {
            egresoSelected.current = "";
            setIsEgSelected(false);
        }
    };

    const handleErrors = (exception) => {
        msgModal.current = "";
        switch (exception.code) {
            //hay que adicionar un control para que no puedan ingresar texto en vez de numeros, en el costo
            case "SaveEgresoException":
                msgModal.current = "No se ha podido guardar el cambio, intentalo de nuevo. Si el problema persiste comunicate con nosotros."
                break;
            case "AdicionarEgresoExcepcion":
                msgModal.current = "No se ha podido guardar el nuevo egreso, intentalo de nuevo. Si el problema persiste comunicate con nosotros."
                break;
            default:
                msgModal.current = "Error en la aplicación, intentalo de nuevo. Si el problema persiste comunicate con nosotros."
                break;
        }
        setShowAlertModal(true);
    };
    
    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        clickToEdit:  true,
        onSelect: handleOnSelect
    };

    const handleSaveValue = async(oldValue, newValue, row, column) => {
        if(oldValue !== newValue){
            var data = "";
            var sendToDb = false;
            if(column.dataField === "costo"){
                newValue = parseFloat(newValue.replace(/,/g, "."));
                if(!isNaN(newValue)){
                    data = {
                        propiedad: column.dataField,
                        value: newValue
                    }
                    sendToDb = true
                    row.costo = newValue;
                } else {
                    row.costo = oldValue;
                }                
            } else {
                data = {
                    propiedad: column.dataField,
                    value: newValue.trim().toLowerCase()
                }
                sendToDb = true
            }
            if(sendToDb){
                try {
                    const response = await API.put("rocket","/egresos/"+row.egresoId, {
                        body: data
                    });
                    if(response.result === "fail"){
                        console.log(response);
                        const exc = {
                            code: "SaveEgresoException"
                        }
                        handleErrors(exc);
                    } else {
                        console.log("Se actualizo correctamente");
                    }
                }catch(error) {
                    console.log(error);
                    handleErrors("Exc");
                }
            }
        }
    };

    const handleCloseAlertModal = () => {
        setShowAlertModal(false);
    };

    const handleUpdateData = () => {
        setIsLoading(true);
        getEgresos();
    };

    const handleDeleteEgreso = async() => {
        setIsLoading(true);
        const response = await API.del("rocket", "/egresos/"+egresoSelected.current.egresoId);
        if(response.result !== "success"){
            const exc = {
                code: "SaveEgresoException"
            }
            handleErrors(exc);
        }
        egresoSelected.current = "";
        getEgresos();
    };

    const enableNuevoEgreso = () => {
        return descripcion.length > 0 &&
        costo > 0
    };

    const handleSaveNuevoEgreso = async() => {
        setIsSaving(true);
        setTextButton("");
        const periodo = Date.parse((tmpStartDate.current.getMonth() + 1)+"/"+1+"/"+tmpStartDate.current.getFullYear());
        const element = {
            periodo: periodo,
            descripcion: descripcion.trim().toLowerCase(),
            costo: parseFloat(costo),
            institucionId: userData.institucionId
        };
        try{
            const response = await API.post("rocket", "/crearEgreso",{
                body: element
            });
            if(response.result === "success"){
                handleCloseAddEgresoModal();
                setIsLoading(true);
                getEgresos();
            } else {
                console.log(response);
                const error = {
                    code: "AdicionarEgresoExcepcion"
                };
                handleCloseAddEgresoModal();
                handleErrors(error);
            }
        }catch(error){
            console.log(error);
            const err = {
                code: "ApiRequestExcepcion"
            };
            handleCloseAddEgresoModal();
            handleErrors(err);
        }
    };

    const handleCloseAddEgresoModal = () => {
        setDescripcion("");
        setCosto(0);
        setIsSaving(false);
        setTextButton("Guardar");
        setShowAddEgresoModal(false);
    };

    const handleEnableActionButtons = () => {
        //habilita el boton de adicionar egreso si el mes es el actual o menor
        //Muestra el boton pasar al siguiente mes, si el mes es menor al actual
        var actualMonth = new Date().getMonth();
        if(actualMonth < startDate.getMonth()){
            return false;
        } else {
            return true;
        }
    };

    return (
        <div>
            <Modal 
                show={showAddEgresoModal}
                onHide={()=>handleCloseAddEgresoModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo egreso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="egreso-descripcion">Descripción</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder="Descripción de este egreso"
                                    aria-label="descripcion"
                                    aria-describedby="egreso-descripcion"
                                    value={descripcion}
                                    onChange={e=>setDescripcion(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="costo-egreso">Costo</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder="Costo de este egreso"
                                    type="number"
                                    aria-label="costo"
                                    aria-describedby="costo-egreso"
                                    value={costo}
                                    onChange={e=>setCosto(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col className="text-right">
                            <LoaderButton
                                variant="outline-primary"
                                onClick={()=>handleSaveNuevoEgreso()}
                                isLoading={isSaving}
                                disabled={!enableNuevoEgreso()}
                            > {textButton}
                            </LoaderButton>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal 
                show={showAlertModal}
                onHide={()=>handleCloseAlertModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>
            <h4>Registrar egresos</h4>
            <hr></hr>
            <Row className="align-items-end">
                <Col className="text-right">
                    <Button variant="outline-primary" onClick={()=>handlePreviousMonth()}><MdNavigateBefore /></Button>
                </Col>
                <Col className="mt-2 text-center" xs="auto">
                    <DatePicker
                        className="form-control text-center"
                        selected={startDate}
                        dateFormat="MM/yyyy"
                        onChange={date => {
                            setIsLoading(true); 
                            tmpStartDate.current = date;
                            getEgresos();
                            setStartDate(date);
                        }}
                        showMonthYearPicker
                    />                    
                </Col>
                <Col className="text-left">
                    {handleEnableActionButtons() && <Button variant="outline-primary" onClick={()=>handleNextMonth()}><MdNavigateNext /></Button>}
                </Col>
            </Row>
            <hr></hr>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-1 pt-1">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :   
                <div>
                    {handleEnableActionButtons() && 
                        <Row className="justify-content-end mb-1"> 
                            <Col className="pl-0 pr-0 text-right" xs={"auto"}>
                                <Button onClick={()=>setShowAddEgresoModal(true)} variant="outline-success"><MdAdd /></Button>
                            </Col>
                            <Col className="pl-0 pr-0 text-right" xs={"auto"}>
                                <Button onClick={()=>handleUpdateData()} className="ml-1 mr-1" variant="outline-primary"><MdRefresh /></Button>
                            </Col>
                            <Col className="pl-0 text-right" xs={"auto"}>
                                <Dropdown>
                                    <Dropdown.Toggle disabled={!isEgSelected} title="Eliminar obligacion" variant="outline-danger" id="dropdown-delete">
                                        <MdDelete />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item >¿Seguro?</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={()=>handleDeleteEgreso()}>Si, elimina esta obligación</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>    
                        </Row>
                    }
                    <Row>
                        <Col>
                            <BootstrapTable
                                keyField="egresoId"
                                data={tableData.current}
                                columns={columns.current}
                                pagination={paginationFactory(25)}
                                noDataIndication={bootstrapTableMsg.current}
                                selectRow={selectRow}

                                cellEdit={
                                    cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    afterSaveCell: handleSaveValue
                                })}
                            />
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrarEgresos);