import React from "react";
import { Navbar, Nav, Dropdown, Col, Row } from "react-bootstrap";
import instLogo from "../img/Institution.svg";
import { FaUserTie, FaUserGraduate, FaUserCheck, FaUserSecret, FaUserCog } from "react-icons/fa";
import { connect } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";

const mapStateToProps = (state, props) => ({
    setPage: props.setPage,
    institucionData: state.institucionData,
    userData: state.userAuthenticated
});

const mapDispatchToProps = dispatch => ({

});

const Header = ({setPage, institucionData, userData}) => {

    const history = useHistory();

    const handleLogout = async() => {
        await Auth.signOut()
        history.push("/login")
    }

    const renderuserIcon = () => {
        switch (userData.rolName) {
            case "estudiante":
                return <FaUserGraduate size={'20'}/>
            case "docente":
                return <FaUserCheck size={'20'}/>
            case "manager":
                return <FaUserSecret size={'20'}/>
            case "coordinador":
                return <FaUserCog size={'20'}/>
            default:
                return <FaUserTie size={'20'}/>
        }
    }

    return (
        <header>
            
            <Navbar bg="dark" expand="lg">
                <LinkContainer to="/">
                    <Navbar.Brand href="/" onClick={()=>setPage(0)}>
                        <img 
                            alt="Intitutional Logo"
                            src={instLogo}
                            className="logo-header"
                        ></img><span className="text-header">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{institucionData.alias}</span>
                    </Navbar.Brand>
                </LinkContainer>
                <Nav className="mr-auto">
      
                </Nav>
                <Row style={{marginLeft: 'auto'}} className='align-items-center'>                
                    <Col sm={'auto'} className='pr-0 text-right'>
                        <span className="text-header capitalize">{userData.nombre} {userData.apellido}&nbsp;&nbsp;</span>
                    </Col> 
                    <Col sm={'auto'} className='text-right'>
                        <Dropdown>                
                            <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
                                {renderuserIcon()}
                            </Dropdown.Toggle>
                            
                            <Dropdown.Menu className="dropdown-menu-right">
                                {/*<Dropdown.Item href="#/action-1">Mi perfil</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Mi cuenta</Dropdown.Item>*/}
                                <Dropdown.Item onClick={()=>handleLogout()} >Cerrar sesión</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> 
                    </Col>  
                </Row>
            </Navbar>
        </header>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);