import React, { useState } from "react";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import AsignarObligaciones from "./asignarObligaciones";
import ConsultarObligaciones from "./consultarObligaciones";

const Finanzas = () => {
    const [activeTab, setActiveTab] = useState('asignar');
    return (
        <div>
            <Row>
                <Col>
                    <Tabs
                        id='finanzas'
                        activeKey={activeTab}
                        onSelect={k=>setActiveTab(k)}
                    >
                        <Tab
                            eventKey='asignar' 
                            title='Asignar'
                        >
                            {activeTab==='asignar' ? <AsignarObligaciones /> : <></>}
                        </Tab>
                        <Tab
                            eventKey='consultar'
                            title='Consultar'
                        >
                            {activeTab==='consultar' ? <ConsultarObligaciones /> : <></>}
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </div>
    );
};

export default Finanzas;