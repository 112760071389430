import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Spinner, Modal, Form, 
    InputGroup, Button, Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import "react-datepicker/dist/react-datepicker.css";
import { MdErrorOutline, MdDelete } from "react-icons/md";
import { connect } from "react-redux";
import { API } from "aws-amplify";

const mapStateToProps = state => ({
    //userData: state.userAuthenticated,
    institucionId: state.institucionData.institucionId,
    rolesData: state.rolesData,
});

const mapDispatchToProps = dispatch => ({});

const ConsultarObligaciones = ({institucionId, rolesData}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [filtroBusqueda, setFiltroBusqueda] = useState('1');
    const [searchCriteria, setSearchCriteria] = useState('');
    const [studentSelected, setStudentSelected] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [enableDelBtn, setEnableDelBtn] = useState(false);

    const bootstrapStudentsTableMsg = useRef("No hay estudiantes que mostrar");
    const bootstrapObligacionesTableMsg = useRef("No hay obligaciones para el estudiante");
    const alertModalTitle = useRef(<MdErrorOutline />);
    const msgModal = useRef("");
    const tableStudentsData = useRef([]);
    const tableObligacionesData = useRef("");
    const isFirstRender = useRef(true);
    const rolStudentId = useRef(null);
    const studentSelectedData = useRef(null);
    const obligacionesSelected = useRef([]);

    useEffect(()=>{
        if(isFirstRender.current){
            isFirstRender.current = false;
            for (let index = 0; index < rolesData.length; index++) {
                const element = rolesData[index];
                if(element.rol === 'estudiante'){
                    rolStudentId.current = element.rolId;
                }
            }
            if(!rolStudentId.current){
                console.log('No se pudo cargar el id del rol estudiante');
                handleErrors('RolStudentException');
            }
        }
    },[rolesData])

    const columnsStudentsData = useRef([
        {
            dataField: 'identificacion',
            text: 'Identificación',
            headerStyle: () => {
                return { width: '20%' }
            }
        },
        {
            dataField: "estudiante", 
            text: "Estudiante",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        }
    ]);

    const columnsObligacionesData = useRef([
        {
            dataField: "term", 
            text: "Periodo",
            sort: true,
            headerStyle: () => {
                return { width: '15%', textAlign: "left" }
            },
            style: {
                textAlign: "left"
            },
        },
        {
            dataField: "descripcion", 
            text: "Obligacion",
            sort: true,
        },
        {
            dataField: "costo", 
            text: "Costo",
            sort: true,
            editable: true,
            headerStyle: () => {
                return { width: '15%', textAlign: "right" }
            },
            style: {
                textAlign: "right"
            },
        },
    ]);

    const optionsTableStudentsData = useRef({
        sizePerPageList: [5]
    });

    const handleEnabled = () => {
        var email = false;
        if(filtroBusqueda === '3'){
            if(searchCriteria.includes('@')){
                email = true;
            }
        } else {
            email = true;
        }
        return searchCriteria.length > 3 && email
    };

    const handleSearch = async() => {
        setIsSearching(true);
        tableStudentsData.current = [];
        try{
            const searchData = await API.get('rocket','/getUsers', {
                'queryStringParameters': {
                    institucionId: institucionId,
                    filtroBusqueda: filtroBusqueda,
                    searchCriteria: searchCriteria.trim().toLowerCase()
                }
            });
            if(searchData.result === 'fail'){
                console.log(searchData);
                setIsSearching(false);
            } else {
                for (let index = 0; index < searchData.description.length; index++) {
                    const element = searchData.description[index];
                    if (element.rol === rolStudentId.current){
                        element.estudiante = element.nombre + " " + element.apellido;
                        tableStudentsData.current.push(element);
                    }
                }
                setIsSearching(false);
            }
        } catch (error) {
            console.log(error);
            const exc = 'GetStudentsException';
            setIsSearching(false);
            handleErrors(exc);
        }
    };

    const handleErrors = (exception) => {
        msgModal.current = "";
        switch (exception) {
            case "GetStudentsException":
                msgModal.current = "No se puede realizar la consulta en este momento, intentalo de nuevo. Si el problema persiste comunicate con nosotros.";
                break;
            case "RolStudentException":
                msgModal.current = "Error en el módulo, vuelve a ingresar en él, si el problema persiste comunicate con nosotros.";
                break;
            case "GetObligacionesException":
                msgModal.current = "No se pueden obtener las obligaciones del estudiante en este momento, intentalo de nuevo. Si el problema persiste comunicate con nosotros.";
                break;
            case "DeleteObligacionesException":
                msgModal.current = "No se pueden eliminar las obligaciones seleccionadas, intentalo de nuevo. Si el problema persiste comunicate con nosotros.";
                break;
            default:
                msgModal.current = "Error en la aplicación, intentalo de nuevo. Si el problema persiste comunicate con nosotros.";
                break;
        }
        setShowAlertModal(true);
    };

    const handleCloseAlertModal = () => {
        setShowAlertModal(false);
    };

    const getObligaciones = async() => {
        setIsLoading(true);
        tableObligacionesData.current = [];
        const qsp = {
            usuarioId: studentSelectedData.current.usuarioId,
            institucionId: institucionId,
            modo_cobranza: false
        }
        try {
            const response = await API.get("rocket", "/getObligacionesEstudiante", {
                queryStringParameters: qsp
            });
            if(response.result === 'fail'){
                console.log(response);
                setIsLoading(false);
            } else {
                for (let index = 0; index < response.description.length; index++) {
                    const element = response.description[index];
                    const periodo = new Date(element.periodo);
                    const month = periodo.getMonth()+1;
                    const mes = month < 10 ? '0'+String(month) : String(month);
                    element.term = mes+"/"+String(periodo.getFullYear());
                    element.costo = parseFloat(element.costo);
                    element.descripcion = element.descripcion.charAt(0).toUpperCase() + element.descripcion.slice(1);
                    tableObligacionesData.current.push(element);
                }
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            handleErrors('GetObligacionesException');
            setIsLoading(false);
        }
    };

    const handleMassDelete = async() => {
        setIsLoading(true);
        try {
            const bd = {
                institucionId: institucionId,
                obligaciones: obligacionesSelected.current
            };
            const response = await API.del('rocket','/deleteObligaciones',{
                body: bd
            });
            if(response.result === 'fail'){
                obligacionesSelected.current = [];
                setEnableDelBtn(false);
                console.log(response);
                setIsLoading(false);
                const err = 'DeleteObligacionesException';
                throw (err);
            }
            obligacionesSelected.current = [];
            setEnableDelBtn(false);
            console.log('Obligaciones eliminadas correctamente');
            getObligaciones();
        } catch (error) {
            obligacionesSelected.current = [];
            setEnableDelBtn(false);
            console.log(error);
            setIsLoading(false);
            handleErrors(error);
        }
    }

    const handleOnSelect = (row, isSelect) => {
        if (isSelect){
            setStudentSelected(true);
            studentSelectedData.current = row;
            getObligaciones();
        } else {
            setStudentSelected(false);
            studentSelectedData.current = null;
        }
    };

    const selectStudentRow = {
        mode: 'radio',
        clickToSelect: true,
        clickToEdit:  false,
        onSelect: handleOnSelect
    };
    
    const handleOnSelectAllObligaciones = (isSelect, rows) => {
        if(isSelect){
            for (let index = 0; index < rows.length; index++) {
                const element = rows[index];
                if(!obligacionesSelected.current.includes(element.obligacionId)){
                    obligacionesSelected.current.push(element.obligacionId);
                }
            }
        } else {
            for (let index = 0; index < rows.length; index++) {
                const element = rows[index];
                const idx = obligacionesSelected.current.indexOf(element.obligacionId);
                if(idx > -1){
                    obligacionesSelected.current.splice(idx,1);
                }
            }
        }
        obligacionesSelected.current.length > 0 ? setEnableDelBtn(true) : setEnableDelBtn(false);
    };

    const handleOnSelectObligacion = (row, isSelect) => {
        if(isSelect){
            if(!obligacionesSelected.current.includes(row.obligacionId)){
                obligacionesSelected.current.push(row.obligacionId);
            }
        } else {
            const index = obligacionesSelected.current.indexOf(row.obligacionId);
            if(index !== -1){
                obligacionesSelected.current.splice(index,1);
            }
        }
        obligacionesSelected.current.length > 0 ? setEnableDelBtn(true) : setEnableDelBtn(false);
    };

    const selectObligacionesRow = {
        mode: 'checkbox',
        clickToSelect: true,
        clickToEdit:  false,
        onSelect: handleOnSelectObligacion,
        onSelectAll: handleOnSelectAllObligaciones
    };

    return(
        <div className="mt-3">
            <Modal 
                show={showAlertModal}
                onHide={()=>handleCloseAlertModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>
            <h4>Consultar obligaciones</h4>
            <hr></hr>
            <Row>
                <Col sm={"auto"}>
                    <Form.Label className="mr-sm-2" htmlFor="opciones-busqueda" srOnly>
                        Opciones de busqueda
                    </Form.Label>
                    <Form.Control
                        as="select"
                        className="mr-sm-2"
                        id="opciones-busqueda"
                        value={filtroBusqueda}
                        onChange={e=>setFiltroBusqueda(e.target.value)}
                        custom
                    >
                        <option value="1">Nombre</option>
                        <option value="2">Apellido</option>
                        <option value="3">email</option>
                        <option value="5">Identificación</option>
                    </Form.Control>
                </Col>
                <Col>
                    <InputGroup className="mb-3">
                        <Form.Control
                            autoFocus
                            placeholder="Criterios de busqueda"
                            aria-label="Criterios de busqueda"
                            aria-describedby="basic-addon2"
                            value={searchCriteria}
                            onChange={e=>setSearchCriteria(e.target.value)}
                        />
                        <InputGroup.Append>
                            <Button disabled={!handleEnabled()} onClick={()=>handleSearch()} variant="outline-secondary">Buscar</Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Row>
            {isSearching ? 
                <Row>
                    <Col className="text-center mt-1 pt-1">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :   
                <Row>
                    <Col>
                        {/*Tabla ESTUDIANTES */}
                        <BootstrapTable
                            keyField="usuarioId"
                            data={tableStudentsData.current}
                            columns={columnsStudentsData.current}
                            pagination={paginationFactory(optionsTableStudentsData.current)}
                            noDataIndication={bootstrapStudentsTableMsg.current}
                            selectRow={selectStudentRow}
                        />
                    </Col>
                </Row>
            }
            {studentSelected ? 
                isLoading ? 
                    <Row>
                        <Col className="text-center mt-1 pt-1">
                            <Spinner animation="border" variant="primary" />
                        </Col>
                    </Row>
                : 
                    <div>
                        <hr></hr>
                        <Row className="align-items-center">
                            <Col>
                                <h5>Obligaciones <span className='capitalize'>{studentSelectedData.current.estudiante}</span></h5>
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row className="justify-content-end mb-1">
                            <Col className="pl-0 text-right" xs={"auto"}>
                                <Dropdown>
                                    <Dropdown.Toggle disabled={!enableDelBtn} title="Eliminar" variant="outline-danger" id="dropdown-delete">
                                        <MdDelete />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item >¿Seguro?</Dropdown.Item>
                                        <Dropdown.Divider />
                                        <Dropdown.Item onClick={()=>handleMassDelete()}>Si, elimina esta obligación</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {/*Tabla OBLIGACIONES */}
                                <BootstrapTable
                                    keyField="obligacionId"
                                    data={tableObligacionesData.current}
                                    columns={columnsObligacionesData.current}
                                    pagination={paginationFactory()}
                                    noDataIndication={bootstrapObligacionesTableMsg.current}
                                    selectRow={selectObligacionesRow}
                                />
                            </Col>
                        </Row>
                    </div>              
            :
                <></>
            }
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsultarObligaciones);