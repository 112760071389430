import React, { useState, useRef, useEffect, useCallback } from "react";
import { API } from 'aws-amplify';
import { Row, Col, Spinner, Tabs, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import Integraciones from "./integraciones";
import Configuraciones from "../components/configuraciones";
import MatriculasConfig from "../components/matriculasConfig";

const mapStateToProps = state => ({
    userData: state.userAuthenticated,
});

const mapDispatchToProps = dispatch => ({});

const Config = ({userData}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('general');
    
    const isFirstRender = useRef(true);
    const institutionData = useRef({});

    const handleErrors = useCallback((exception) => {
        //msgModal.current = "";
        switch (exception.code) {
            case "GetInstitutionDataException":
                console.log("Error: No se puede obtener información de la institución");
                break;
            default :
                break;
        }
        //setShowAlertModal(true);
    },[]);

    useEffect(()=>{

        const getInstitutionLicense = async() => {
            try {
                const respuesta = await API.get("rocket","/getInstitucion", {
                    queryStringParameters: {
                        institucionId: userData.institucionId
                    }
                });
                if(respuesta.result === 'fail'){
                    console.log(respuesta);
                    const error = 'GetInstitutionDataException';
                    throw error;
                }
                institutionData.current = respuesta.description[0]; 
                setIsLoading(false);
            } catch (error) {
                handleErrors(error);
            }
        }

        if(isFirstRender){
            isFirstRender.current = false;
            getInstitutionLicense();
        }
    },[userData, handleErrors]);

    const handleActiveTab = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-1 pt-1">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            : 
            <div>
                <h4>Configuraciones</h4>
                <hr></hr>
                <Row>
                    <Col>
                        <Tabs 
                            id='config'
                            activeKey = {activeTab}
                            onSelect={k=>handleActiveTab(k)}
                        >
                            <Tab eventKey='general' title='General' >
                                {activeTab === 'general' ? <Configuraciones /> : <></>}
                            </Tab>
                            {institutionData.current.licencia === 'rC' || institutionData.current.licencia === 'rE' ? 
                                <Tab eventKey='integraciones' title='Integraciones'>
                                    {activeTab === 'integraciones' ? <Integraciones /> : <></>}
                                </Tab>
                                : <></>
                            }
                            <Tab eventKey='matriculas' title='Matriculas'>
                                {activeTab === 'matriculas' ? <MatriculasConfig /> : <></>}
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Config);