import React, { useState, useEffect, useRef, useCallback } from "react";
import { Col, Row, Modal, Spinner, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { MdErrorOutline } from "react-icons/md";
import { MdAdd, MdRemove } from "react-icons/md";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import API from "@aws-amplify/api";
import { useHistory } from "react-router";
import DatePicker from "react-datepicker";

const mapStateToProps = (state, props) => ({
    userData: state.userAuthenticated,
    rolesData: state.rolesData,
    props: props
});

const mapDispatchToProps = dispatch => ({});

const Asistencia = ({userData, props, rolesData}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [startDate, setStartDate] = useState(new Date());

    const msgModal = useRef("");
    const alertModalTitle = useRef(<MdErrorOutline />);
    const bootstrapTableMsg = useRef("No hay datos de asistencia");
    const enrollsData = useRef([]);
    const estudianteRolId = useRef("");
    const asistenciaToSave = useRef([]);

    const asis = useRef([]);
    const changeFlag = useRef(false);
    const asisIndex = useRef([]);
    const firstRender = useRef(true);

    const history = useHistory();

    const columnsDocente = useRef([
        {
            dataField: "identificacion", 
            text: "Identificacion",
            headerStyle: () => {
                return { width: '20%' }
            }
        },
        {
            dataField: "nombreCompleto", 
            text: "Nombre",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
    ])

    const setAsisIndex = async() => {
        asisIndex.current = [];
        for (let index = 0; index < enrollsData.current.length; index++) {
            const element = enrollsData.current[index];
            if(asis.current.includes(element.usuarioId)){
                asisIndex.current.push(element.usuarioId);
            }
        }
    }

    const handleErrors = (exception) => {
        switch (exception.code) {
            case "GetEnrollsException":
                msgModal.current = "No es posible recuperar las inscripciones, por favor intentalo de nuevo, si el problema persiste comunicate con nosotros.";
                break;
            default:
                msgModal.current = "Error en la aplicación, intentalo de nuevo, si el problema persiste comunicate con nosotros.";
                break;
        }
        setShowAlertModal(true);
    }

    const getAsistencia = useCallback(async(fecha) => {
        const qsp = {
            fecha: fecha
        };
        const asistencia = await API.get("rocket", "/asistencia/"+props.cursoData.cursoId,{
            "queryStringParameters": qsp
        });
        if (asistencia.result === "success"){
            asis.current = asistencia.description;
            changeFlag.current = false;
        } else {
            console.log(asistencia);
        }
    },[props.cursoData]);

    const getParticipants = useCallback(async(enrolls) => {
        for (let index = 0; index < enrolls.length; index++) {
            const element = enrolls[index];
            if(element.estado){
                const uData = await API.get("rocket","/usuarios/"+element.usuarioId,{
                    "queryStringParameters":{
                        institucionId: userData.institucionId
                    }
                });
                if(uData.result === "success"){
                    if(uData.description.estado){
                        if(uData.description.rol === estudianteRolId.current){
                            const ud = {
                                usuarioId: uData.description.usuarioId,
                                identificacion: uData.description.identificacion,
                                nombreCompleto: uData.description.nombre + " " + uData.description.apellido
                            };
                            enrollsData.current.push(ud);
                        }
                    }
                } else {
                    console.log("No se encuentran datos del usuario: " + element.usuarioId);
                }
            }
        }
    },[userData])

    useEffect(()=>{
        if(firstRender.current){
        for (let index = 0; index < rolesData.length; index++) {
            const element = rolesData[index];
            if(element.rol === "estudiante"){
                estudianteRolId.current = element.rolId;
            }
        }
        if(userData.rolName === "docente"){
            const qsp = {
                cursoId: props.cursoData.cursoId,
                enrollTypeFlag: false
            };
            API.get("rocket","/getInscripciones",{
                "queryStringParameters": qsp
            }).then(async(enrolls)=>{
                const unixDate = Date.parse((startDate.getMonth()+1)+"/"+startDate.getDate()+"/"+startDate.getFullYear());
                getAsistencia(unixDate);
                switch (enrolls.result) {
                    case "success":
                        await getParticipants(enrolls.description);
                        await setAsisIndex();
                        firstRender.current = false;
                        setIsLoading(false);
                        break;
                    case "fail":
                        console.log(enrolls);
                        setIsLoading(false);
                        break;
                    default:
                        break;
                }
            }).catch(error => {
                const err = {
                    code: "GetEnrollsException",
                    description: error
                };
                setIsLoading(false);
                handleErrors(err);
            })
        } else if(userData.rolName === "estudiante"){

        } else {
            history.push("/");
        }
        }
    },[getAsistencia,firstRender, getParticipants, history, props.cursoData, rolesData, startDate, userData])

    const handleOnSelect = (row, isSelect) => {
        if(isSelect){
            if(!asis.current.includes(row.usuarioId)){
                asis.current.push(row.usuarioId);
            }
        } else {
            const index = asis.current.indexOf(row.usuarioId);
            if (index > -1){
                asis.current.splice(index, 1);
            }
        }
        changeFlag.current = true;
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        selected: asisIndex.current,
        onSelect: handleOnSelect
    }

    const handlePreviousDay = async() => {
        setIsLoading(true);
        if (changeFlag.current){
            //salvar cambios
            const unixDate = Date.parse((startDate.getMonth()+1)+"/"+startDate.getDate()+"/"+startDate.getFullYear());
            const paraAsis = {
                fecha: unixDate,
                values: asis.current
            };
            asistenciaToSave.current.push(paraAsis);
            await handleSaveAsistencia();
        }
        const actualDate = (new Date(startDate));
        actualDate.setDate(actualDate.getDate() - 1);
        setStartDate(actualDate);
        const unixDate = Date.parse((actualDate.getMonth()+1)+"/"+actualDate.getDate()+"/"+actualDate.getFullYear());
        await getAsistencia(unixDate);
        await setAsisIndex();
        setIsLoading(false);
    }

    const handleNextDay = async() => {
        setIsLoading(true);
        if (changeFlag.current){
            //salvar cambios
            const unixDate = Date.parse((startDate.getMonth()+1)+"/"+startDate.getDate()+"/"+startDate.getFullYear());
            const paraAsis = {
                fecha: unixDate,
                values: asis.current
            };
            asistenciaToSave.current.push(paraAsis);
            await handleSaveAsistencia();
        }
        const actualDate = (new Date(startDate));
        actualDate.setDate(actualDate.getDate() + 1);
        setStartDate(actualDate);
        const unixDate = Date.parse((actualDate.getMonth()+1)+"/"+actualDate.getDate()+"/"+actualDate.getFullYear());
        await getAsistencia(unixDate);
        await setAsisIndex();
        setIsLoading(false);
    }

    const handleSaveAsistencia = async() => {
        try{
            const response = await API.put("rocket", "/asistencia/"+props.cursoData.cursoId,{
                body: asistenciaToSave.current
            });
            console.log(response);
        } catch(error){
            console.log(error)
        }
    }

    const handlePicDate = async(fecha) => {
        setIsLoading(true);
        if (changeFlag.current){
            //salvar cambios
            const unixDate = Date.parse((startDate.getMonth()+1)+"/"+startDate.getDate()+"/"+startDate.getFullYear());
            const paraAsis = {
                fecha: unixDate,
                values: asis.current
            };
            asistenciaToSave.current.push(paraAsis);
            await handleSaveAsistencia();
        }
        const actualDate = (new Date(fecha));
        setStartDate(actualDate);
        const unixDate = Date.parse((actualDate.getMonth()+1)+"/"+actualDate.getDate()+"/"+actualDate.getFullYear());
        await getAsistencia(unixDate);
        await setAsisIndex();
        setIsLoading(false);
    }

    return (
        <div>
            <Modal 
                show={showAlertModal}
                onHide={()=>setShowAlertModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>
            {userData.rolName==="estudiante" ? 
                <div>
                    <Row>
                        <Col className="text-center">
                            {isLoading ? 
                                <Spinner animation="border" variant="primary" />
                            :
                                <BootstrapTable
                                    keyField="usuarioId"
                                    data={enrollsData.current}
                                    columns={columnsDocente.current}
                                    striped
                                    hover
                                    selectRow={selectRow}
                                    bordered={ false }
                                    pagination={paginationFactory({sizePerPage: 25})}
                                    noDataIndication={bootstrapTableMsg.current}
                                />
                            }  
                        </Col>
                    </Row>                   
                </div>
            :
                <div>
                    {isLoading ? 
                        <Row>
                            <Col className="text-center">
                                <Spinner animation="border" variant="primary" />
                            </Col>
                        </Row> 
                    :
                        <div>
                            <Row className="justify-content-center mb-3">
                                <Col className="text-right">
                                    <Button onClick={()=>handlePreviousDay()}><MdRemove /></Button>
                                </Col>
                                <Col sm={"auto"} className="text-center">
                                    <DatePicker
                                        className="form-control"
                                        selected = {startDate}
                                        value={startDate}
                                        onChange = {date => handlePicDate(date)}
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode = 'select'
                                        fixedHeight
                                    />
                                </Col>
                                <Col className="text-left">
                                    <Button onClick={()=>handleNextDay()}><MdAdd /></Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BootstrapTable
                                        keyField="usuarioId"
                                        data={enrollsData.current}
                                        columns={columnsDocente.current}
                                        striped
                                        hover
                                        selectRow={selectRow}
                                        bordered={ false }
                                        pagination={paginationFactory({sizePerPage: 25})}
                                        noDataIndication={bootstrapTableMsg.current}
                                    />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col className="text-right">
                                    <Button onClick={()=>handlePicDate(startDate)}>Guardar</Button>
                                </Col>
                            </Row>
                        </div>
                    }  
                        
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Asistencia);