import { Auth, API } from "aws-amplify";
import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Modal, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../containers/header";
import { MdErrorOutline } from "react-icons/md";
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader, SidebarFooter, SidebarContent } from "react-pro-sidebar";
import { FaUserFriends, FaPortrait } from "react-icons/fa";
import { ImBooks } from "react-icons/im";
import { IoIosWallet } from "react-icons/io";
import { HiMenu } from "react-icons/hi";
import { HiDocumentReport } from "react-icons/hi";
import { BsGearFill } from "react-icons/bs";
import RocketLogo from "../img/apple-touch-icon.png";
import Home from "./home";
import Usuarios from "./usuarios";
import Usuario from "./usuario";
import QuickAccess from "./quickAccess";
import Cursos from "./cursos.js";
import Curso from "./curso.js";
//import AsignarObligaciones from "./asignarObligaciones";
import RegistrarIngresos from "./registrarIngresos";
import RegistrarEgresos from "./registrarEgresos";
import TodosLosCursos from "./todosLosCursos";
import Reports from "./reports.js";
import Config from "./config.js";
import Finanzas from "./finanzas";
import Matriculas from "./matriculas";

const mapStateToProps = state => ({
    userData: state.userAuthenticated,
});
const mapDispatchToProps = dispatch => ({
    setLoginState(auth_user_data){
        dispatch({
            type: "USER_PERFORM_LOGIN",
            auth_user_data
        })
    },
    setInstitucionData(institucion_data){
        dispatch({
            type: "CARGAR_INSTITUCION_DATA",
            institucion_data
        })
    }
});

const Rocket = ({userData, setLoginState, setInstitucionData}) => {
    const history = useHistory();
    const msgModal = useRef("");
    const alertModalTitle = useRef(<MdErrorOutline />);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [toggled, setToggled] = useState(false);
    const [page, setPage] = useState(-1);
    var sincro = require("../libs/sincro");

    const handleErrors = (exception) => {
        switch (exception.code) {
            case "MetodoPagoException":
                msgModal.current = "Tu cuenta no esta habilitada para ingresar a Rocket, por favor ponte en contacto con nosotros.";
                break;
            case "NoExisteUsuarioException":
                msgModal.current = "No se encuentra la información de tu cuenta, no es posible iniciar sesión, por favor ponte en contacto con nosotros.";
                break;
            case "DisabledUserException":
                msgModal.current = "Tu usuario no se encuentra habilitado, comunicate con nosotros.";
                break;
            case "GetInstitutionDataException":
                msgModal.current = "Tu institución no esta configurada para usar Rocket, comunicate con nosotros.";
                break;
            case "GetRolException":
                console.log("No es posible acceder a los roles");
                console.log(exception.description);
                break;
            default:
                msgModal.current = "Error en la aplicación, intentalo de nuevo, si el problema persiste comunicate con nosotros.";
                break;
        }
        setShowAlertModal(true);
    }
    
    useEffect(()=>{
        Auth.currentSession()
        .then(async()=>{
            try {
                const cui = await Auth.currentUserInfo();
                const userInfo = await API.get("rocket", "/getUser", {
                    'queryStringParameters': {
                        "usuarioId": cui.username,
                    }
                });
                if(userInfo.result === "success"){
                    const auth_user_data = userInfo.description[0];
                    if(auth_user_data.estado){
                        const rolData = await API.get("rocket", "/roles/"+auth_user_data.rol);
                        if(rolData.result === "success"){
                            API.get("rocket", "/getInstitucion", {
                                'queryStringParameters': {
                                    "institucionId": auth_user_data.institucionId,
                                }
                            }).then(instInfo=>{
                                if(instInfo.result === "fail"){
                                    const error = {
                                        code: "GetInstitutionDataException"
                                    }
                                    handleErrors(error);
                                    Auth.signOut();
                                } else {
                                    const institucion_data = instInfo.description[0];
                                    setInstitucionData(institucion_data);
                                    sincro.sincroIntegraciones();
                                    sincro.sincroRoles();
                                }
                            }).catch(error=>{
                                console.log(error);
                                const err = {
                                    code: "GetInstitutionDataException"
                                }
                                handleErrors(err);
                                Auth.signOut();
                            });               
                            auth_user_data.rolName = rolData.description.rol;             
                            if(rolData.description.rol === "manager"){
                                if (auth_user_data.metodoPago){
                                    auth_user_data.autenticado = true;
                                    setLoginState(auth_user_data);
                                    setPage(0);
                                    setIsLoading(false);
                                    console.log("Rocket listo y atento! Bienvenido!");
                                } else {
                                    const error = {
                                        code: "MetodoPagoException"
                                    }
                                    handleErrors(error);
                                    Auth.signOut();
                                }
                            } else if (rolData.description.rol === "estudiante"){
                                setLoginState(auth_user_data);
                                //setIsLoading(false);
                                console.log("Bienvenido querido estudiante.");
                                history.push("/estudiantes");
                            } else if (rolData.description.rol === "docente"){
                                setLoginState(auth_user_data);
                                //setIsLoading(false);
                                console.log("Estimado colega docente. Bienvenido.");
                                history.push("/docentes");
                            } else if (rolData.description.rol === "coordinador"){
                                setLoginState(auth_user_data);
                                //setIsLoading(false);
                                console.log("Bienvenido sr(a) coordinador(a).");
                                history.push("/coordinador");
                            }
                        } else {
                            const err = {
                                code: "GetRolException",
                                description: rolData
                            }
                            handleErrors(err);
                        }
                    } else {
                        const error = {
                            code: "DisabledUserException"
                        }
                        handleErrors(error);
                        Auth.signOut();
                    }
                } else {
                    const fail = {
                        code: "NoExisteUsuarioException"
                    }
                    handleErrors(fail);
                    Auth.signOut();
                }
            } catch(error){
                console.log(error)
                handleErrors("error");
                Auth.signOut();
            }
        }).catch(error=>{
            if(error === "No current user"){
                history.push("/login");
            } else {
                console.log(error);
                handleErrors("error");
            }
        })
    }, [history, setLoginState, setInstitucionData, sincro])

    const handleCloseAlertModal = () => {
        history.push("/login");
    }

    const handlePageChange = () => {
        switch (page) {
            case 0:
                return <Home />
            case 1:
                return <Usuarios setEditarUsuario={setPage}/>
            case 2:
                return <Usuario />
            case 3:
                return <Reports institucionId = {userData.institucionId}/>
            case 4:
                return <Config />
            case 5:
                return <Cursos setEditarCurso={setPage}/>
            case 6: 
                return <Curso />
            case 7: 
                //return <AsignarObligaciones />
                return <Finanzas />
            case 8:
                return <RegistrarIngresos />
            case 9:
                return <TodosLosCursos />
            case 10:
                return <RegistrarEgresos />
            case 11:
                return <Matriculas />
            default:
                break;
        }
    }

    return (
        <div>
            <Modal 
                show={showAlertModal}
                onHide={()=>handleCloseAlertModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-5 pt-5">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            : 
                <div>
                    <button onClick={()=>setToggled(true)} className='btn-toggle'><HiMenu /></button>
                    <Row>
                        <Col>
                            <Header setPage={setPage}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={"auto"}>
                            <ProSidebar className="md" toggled={toggled} onToggle={()=>setToggled(false)}>
                                <SidebarHeader>

                                </SidebarHeader>
                                <SidebarContent>
                                    <Menu iconShape="circle">
                                        <SubMenu title={<label className="menu-label" onClick={()=>setPage(1)}>Usuarios</label>} icon={<FaUserFriends />}>
                                            <MenuItem ><label className="menu-label" onClick={()=>setPage(2)}>Crear</label></MenuItem>
                                        </SubMenu>
                                        <SubMenu title={<label className="menu-label" onClick={()=>setPage(5)}>Cursos</label>} icon={<ImBooks />}>
                                            <MenuItem ><label className="menu-label" onClick={()=>setPage(6)}>Crear</label></MenuItem>
                                            <MenuItem ><label className="menu-label" onClick={()=>setPage(9)}>Todos los cursos</label></MenuItem>
                                        </SubMenu>
                                        <SubMenu title={<label className="menu-label" onClick={()=>setPage(7)}>Finanzas</label>} icon={<IoIosWallet />}>
                                            <MenuItem ><label className="menu-label" onClick={()=>setPage(8)}>Ingresos</label></MenuItem>
                                            <MenuItem ><label className="menu-label" onClick={()=>setPage(10)}>Egresos</label></MenuItem>
                                        </SubMenu>
                                        {/*<SubMenu title="Reportes" icon={<HiDocumentReport />}>
                                            <MenuItem>Academicos</MenuItem>
                                            <MenuItem>Financieros</MenuItem>
                                        </SubMenu>*/}
                                        <MenuItem icon={<FaPortrait />}><label className="menu-label" onClick={()=>setPage(11)}>Matrículas</label></MenuItem>
                                        <MenuItem icon={<HiDocumentReport />}><label className="menu-label" onClick={()=>setPage(3)}>Reportes</label></MenuItem>
                                        <MenuItem icon={<BsGearFill />}><label className="menu-label" onClick={()=>setPage(4)}>Configuración</label></MenuItem>
                                    </Menu>
                                </SidebarContent>
                                <SidebarFooter className="GVIS-Background">
                                    <Col className="text-center mt-2 mb-2 rounded">
                                        <img 
                                            alt="logo rocket"
                                            src={RocketLogo}
                                            className="Sidebar-rocket-logo"
                                        ></img>
                                        <span style={{color:"white"}}>&nbsp;Rocket</span>
                                        <Col>
                                            <i className="footer-text">© Grupo Valor International Solutions.</i>
                                        </Col>
                                    </Col>
                                </SidebarFooter>
                            </ProSidebar>  
                        </Col>
                        <Col className="mt-3">
                            <QuickAccess setIsLoading={setIsLoading}/>
                            {handlePageChange()}
                        </Col>
                    </Row> 
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Rocket);