import React, { useEffect, useRef, useState } from "react";
import { Row, Col, InputGroup, Form, FormControl, 
    Button, DropdownButton, Dropdown, ButtonGroup, 
    Modal, Alert } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoaderButton from "../components/loaderButton";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { IoMdAdd, IoMdCheckmark } from "react-icons/io";
import { connect } from "react-redux";
import LoadingModal from "../components/loadingModal";
import { API } from "aws-amplify";
import { MdErrorOutline } from "react-icons/md";

const mapStateToProps = state => ({
    usuarioData: state.usuarioData,
    institucionId: state.institucionData.institucionId,
    rolesData: state.rolesData
});

const mapDispatchToProps = dispatch => ({
    setUsuarioData(usuarioData){
        dispatch({
            type: "CARGAR_USUARIO_EDITAR",
            usuarioData
        })
    }
});

const CrearUsuario = ({usuarioData, setUsuarioData, rolesData, institucionId}) => {
    const [startDate, setStartDate] = useState((usuarioData.usuarioId ? new Date(usuarioData.nacimiento) : new Date()));
    const usuarioId = useRef((usuarioData.usuarioId ? usuarioData.usuarioId : ""));
    const [DNI, setDNI] = useState((usuarioData.usuarioId ? usuarioData.identificacion : ""));
    const [Nombre, setNombre] = useState(usuarioData.usuarioId ? usuarioData.nombre : "");
    const [Apellido, setApellido] = useState(usuarioData.usuarioId ? usuarioData.apellido : "");
    const [email, setEmail] = useState(usuarioData.usuarioId ? usuarioData.email : "");
    const [clave, setClave] = useState("");
    const [cclave, setCclave] = useState("");
    const [enable, setEnable] = useState(usuarioData.usuarioId ? usuarioData.estado : true);
    const [Rol, setRol] = useState(usuarioData.usuarioId ? usuarioData.rol : "0");
    const [showModal, setShowModal] = useState(false);
    const [textTitle, setTextTitle] = useState("");
    const [textButton, setTextButton] = useState("Guardar");
    const [isLoading, setIsLoading] = useState(false);
    const [acudienteDNI, setAcudienteDNI] = useState("");
    const [acudienteNombre, setAcudienteNombre] = useState("");
    const [acudienteApellido, setAcudienteApellido] = useState("");
    const [acudienteEmail, setAcudienteEmail] = useState("");
    const [acudienteTelefono, setAcudienteTelefono] = useState("");
    const [acudienteVinculo, setAcudienteVinculo] = useState("0");
    const [payPerson, setPayPerson] = useState(true);
    const [showLoadingModal, setShowLoadingModal] = useState(true);
    const [showAlertModal, setShowAlertModal] = useState(false);

    const ctrlAcudientesCargados = useRef(false);
    const forDummyData = useRef([]);
    const textoBody = useRef("");
    const buttonType = useRef("");
    const msgModal = useRef("");
    const alertModalTitle = useRef(<MdErrorOutline />);
    const bootstrapTableMsg = useRef("No hay acudientes registrados para este estudiante");
    const changeAcudienteFlag = useRef(false);
    const rolEstudianteId = useRef("");

    //20-05-2021
    const isFirstRender = useRef(true);
    const config = useRef(null);
    //

    const columns = useRef([
        {dataField: "Acudiente", text: "Acudiente"},
        {dataField: "email", text: "email"},
        {dataField: "Telefono", text: "Telefono"},
        {dataField: "Vinculo", text: "Vinculo"}
    ]);

    const acudienteEditIndex = useRef("");

    var dummyDat = useRef([]);

    const editAcudiente = (index) => {
        setTextTitle("Editar acudiente");
        setAcudienteDNI(dummyDat.current[index].ID);
        setAcudienteNombre(dummyDat.current[index].Nombre);
        setAcudienteApellido(dummyDat.current[index].Apellido);
        setAcudienteEmail(dummyDat.current[index].email);
        setAcudienteTelefono(dummyDat.current[index].Telefono);
        setAcudienteVinculo(dummyDat.current[index].Vinculo);
        setPayPerson(dummyDat.current[index].payPerson);
        acudienteEditIndex.current = index;
        setShowModal(true);
    };

    const handleError = (exception) =>{
        switch (exception.code) {
            case "UnableCreateUser":
                msgModal.current = "No es posible guardar este usuario. Intentalo de nuevo, si el problema persiste, por favor ponte en contacto con nosotros.";
                break;
            case "NoUpdatesToSave":
                msgModal.current = "No hay cambios que actualizar";
                break;
            case "UserExistException":
                msgModal.current = "Este usuario ya se encuentra registrado en Rocket. No es posible crear el usuario.";
                setTextButton("Guardar");
                setIsLoading(false);
                setShowLoadingModal(false);
                break;
            case "BadClaveException":
                msgModal.current = "La clave ingresada no cumple con la política de seguridad. No es posible crear el usuario.";
                setTextButton("Guardar");
                setIsLoading(false);
                setShowLoadingModal(false);
                break;
            case 'UnableGetConfig':
                msgModal.current = 'No fue posible cargar las configuraciones de la institución, si el problema persiste, comunicate con nosotros.';
                console.log(exception.message);
                break;
            default:
                msgModal.current = "Error en la aplicación. Intentalo de nuevo, si el problema persiste, comunicate con nosotros.";
                break;
        }
        setShowAlertModal(true);
    };

    useEffect(()=>{
        return function cleanUp(){
            setUsuarioData({});
        }
    },[setUsuarioData]);

    useEffect(()=>{

        const getConfig = async() => {
            try {
                if(!usuarioData.usuarioId){
                    const instConfig = await API.get('rocket','/config/'+institucionId);
                    if(instConfig.result === 'success'){
                        config.current = instConfig.description;
                    } else {
                        setShowLoadingModal(false);
                        const err = {
                            code: 'UnableGetConfig',
                            message: instConfig.description
                        };
                        throw (err);
                    }
                }
                //console.log(config.current);
                setShowLoadingModal(false);
            } catch (error) {
                const err = {
                    code: 'UnableGetConfig',
                    message: error
                }
                setShowLoadingModal(false);
                handleError(err);
            }
        };

        if(isFirstRender.current){
            isFirstRender.current = false;
            getConfig();
        }
    },[institucionId, usuarioData]);

    useEffect(()=>{
        for (let index = 0; index < rolesData.length; index++) {
            const element = rolesData[index];
            switch (element.rol) {
                case "estudiante":
                    rolEstudianteId.current = element.rolId;
                    break;
            
                default:
                    break;
            }
            
        }
    },[rolesData])

    const prepararAcudientesLista = (index, element) => {
        const acudiente = 
            {
                Acudiente:
                    <DropdownButton variant="link" as={ButtonGroup} title={<span className="capitalize">{element.Nombre+" "+element.Apellido}</span>} id={element.ID}>
                        <Dropdown.Item onClick={()=>editAcudiente(index)} eventKey="1">Editar</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={()=>handleEliminarAcudiente(index, "danger")} eventKey="3">Eliminar acudiente</Dropdown.Item>
                    </DropdownButton>, 
                Telefono: element.Telefono, 
                email: element.email, 
                Vinculo: element.Vinculo
            };
        return acudiente;
    }

    if (usuarioData.usuarioId && !ctrlAcudientesCargados.current){
        for (let index = 0; index < usuarioData.acudientes.length; index++) {
            const element = usuarioData.acudientes[index];
            dummyDat.current.push(element); //aca se almacenan los acudientes puros
        }
        for (let index = 0; index < dummyDat.current.length; index++) {
            const element = dummyDat.current[index];
            const acudiente = prepararAcudientesLista(index, element);
            forDummyData.current.push(acudiente); //aca se almacenan los acudientes preparados para mostrar en la tabla de acudientes
        }
        ctrlAcudientesCargados.current = true;
    }

    const addAcudiente = () => {
        setTextTitle("Nuevo acudiente");
        setShowModal(true);
    }

    const salvarAcudiente = (event) => {
        event.preventDefault();
        setIsLoading(true);
        const newAcudiente = {
            ID: acudienteDNI.trim(),
            Nombre: acudienteNombre.trim().toLowerCase(),
            Apellido: acudienteApellido.trim().toLowerCase(),
            email: acudienteEmail.trim(),
            Telefono: acudienteTelefono,
            Vinculo: acudienteVinculo,
            payPerson: payPerson
        }
        if (acudienteEditIndex.current === ""){
            acudienteEditIndex.current = dummyDat.current.length
            dummyDat.current.push(newAcudiente);
            const acudiente = prepararAcudientesLista(acudienteEditIndex.current, newAcudiente);
            forDummyData.current.push(acudiente);
        } else {
            dummyDat.current[acudienteEditIndex.current] = newAcudiente;
            const acudiente = prepararAcudientesLista(acudienteEditIndex.current, newAcudiente);
            forDummyData.current[acudienteEditIndex.current] = acudiente;
        }
        changeAcudienteFlag.current = true;
        acudienteEditIndex.current = "";
        //setDummyData(forDummyData.current);
        setAcudienteDNI("");
        setAcudienteNombre("");
        setAcudienteApellido("");
        setAcudienteEmail("");
        setAcudienteTelefono("");
        setAcudienteVinculo("");
        setPayPerson(true);
        setShowModal(false);
        setIsLoading(false);
        //Crear json con la data del acudiente y 
        //push ese json a la matriz de acudientes
        //console.log(dummyDat.current);
    }

    const handleClose = () => {
        setShowModal(false);
    }

    const validarCreacion = () => {
        var ctrl = false;
        if(config.current){
            if(!config.current.userCanSetOwnPassword){
                if(clave.length > 6 && (clave === cclave)){
                    ctrl = true;
                }
            } else {
                ctrl = true;
            }
        } else {
            ctrl = true;
        }
        return DNI.length > 2 &&
        Nombre.length > 2 &&
        Apellido.length > 2 &&
        email.length > 2 &&
        email.includes("@") &&
        Rol !== "0" && ctrl;
    }

    const validarAcudiente = () => {
        return acudienteNombre.length > 3 &&
        acudienteDNI.length > 3 &&
        acudienteApellido.length > 3 &&
        acudienteEmail.includes("@") &&
        acudienteTelefono.length > 3 &&
        acudienteVinculo !== "0"
    }

    const blankData = (editFlag) => {
        setNombre("");
        setApellido("");
        setRol("");
        setEmail("");
        setDNI("");
        setClave("");
        setCclave("");
        setStartDate(new Date());
        setEnable(true);
        dummyDat.current = [];
        forDummyData.current=[];
        if(editFlag){
            const blankUsuarioData = {};
            setUsuarioData(blankUsuarioData)
        }
        setTextButton("Guardar");
        setIsLoading(false);
        setShowLoadingModal(false);
    }

    const enviarCreacion = (event) => {
        event.preventDefault();
        setShowLoadingModal(true);
        setTextButton("");
        setIsLoading(true);
        if(usuarioData.usuarioId){
            var cambiosFlag = false;
            const userData = {};
            const propiedades = [];
            var bornDate = Date.parse((startDate.getMonth()+1)+"/"+startDate.getDate()+"/"+startDate.getFullYear());
            if(DNI.trim() !== usuarioData.identificacion){
                userData.identificacion = DNI.trim();
                propiedades.push("identificacion");
                cambiosFlag = true;
            }
            if(Nombre.trim().toLowerCase() !== usuarioData.nombre){
                userData.nombre = Nombre.trim().toLowerCase();
                propiedades.push("nombre");
                cambiosFlag = true;
            }
            if(Apellido.trim().toLowerCase() !== usuarioData.apellido){
                userData.apellido = Apellido.trim().toLowerCase();
                propiedades.push("apellido");
                cambiosFlag = true;
            }
            if(email.trim() !== usuarioData.email){
                userData.email = email.trim();
                propiedades.push("email");
                cambiosFlag = true;
            }
            if(bornDate !== usuarioData.nacimiento){
                userData.nacimiento = bornDate;
                propiedades.push("nacimiento");
                cambiosFlag = true;
            }
            if(Rol !== usuarioData.rol){
                userData.rol = Rol;
                propiedades.push("rol");
                cambiosFlag = true;
            }
            if(enable !== usuarioData.estado){
                userData.status = enable;
                propiedades.push("estado");
                cambiosFlag = true;
            }
            if(changeAcudienteFlag.current){
                userData.acudientes = dummyDat.current;
                propiedades.push("acudientes");
                cambiosFlag = true;
            }
            if(cambiosFlag){
                userData.institucionId = institucionId;
                userData.propiedades = propiedades;
                API.put("rocket", "/usuarios/"+usuarioId.current, {
                    body: userData
                }).then(value=>{
                    switch (value.result) {
                        case "success":
                            blankData(true);
                            break;
                        case "fail":
                            const err = {
                                code: "UnableCreateUser"
                            }
                            blankData(true);
                            handleError(err);
                            break;
                        default:
                            console.log(value);
                            break;
                    }
                }).catch(error=>{
                    console.log(error);
                    blankData(true);
                    handleError("error");
                })
            } else {
                const err = {
                    code: "NoUpdatesToSave"
                }
                setTextButton("Guardar");
                setIsLoading(false);
                setShowLoadingModal(false);
                handleError(err);
            }
            changeAcudienteFlag.current = false;
        } else {
            var bDate = Date.parse((startDate.getMonth()+1)+"/"+startDate.getDate()+"/"+startDate.getFullYear());
            const userData = {
                nombre: Nombre.trim().toLowerCase(),
                apellido: Apellido.trim().toLowerCase(),
                institucionId: institucionId,
                rol: Rol,
                email: email.trim(),
                identificacion: DNI.trim(),
                nacimiento: bDate,
                status: enable,
                acudientes: dummyDat.current,
                clave: clave
            };
            API.post("rocket", "/crearUsuario", {
                body: userData
            }).then((value)=>{
                switch (value.result) {
                    case "success":
                        blankData();
                        break;
                    case "fail":
                        console.log(value);
                        switch (value.description) {
                            case "Este usuario ya existe":
                                const err = {
                                    code: "UserExistException"
                                }
                                handleError(err);
                                break;
                            case "La clave no cumple con la política de seguridad":
                                const err2 = {
                                    code: "BadClaveException"
                                }
                                handleError(err2);
                                break;
                            default:
                                blankData();
                                const err3 = {
                                    code: "UnableCreateUser"
                                }
                                handleError(err3);
                                break;
                        }
                        break;
                    default:
                        break;
                }
            }).catch(error=>{
                console.log(error);
            })
        }
    };

    const handleEliminarAcudiente = (index, modalType) => {
        (modalType === "warning" ? 
        textoBody.current = "¿?"
        :
        textoBody.current = "¿Esta seguro de eliminar este acudiente?"
        )
        buttonType.current = modalType;
    };

    const renderRols = () => {
        return (
            rolesData.map((rol)=>{
                return(
                    (rol.rol === "manager" || rol.rol === "integrador"
                        ? ""
                        : <option key={rol.rolId} value={rol.rolId}>{rol.rol}</option>
                    )
                )
                
            })
        )
    }

    const renderUserRol = () => {
        const mRol = [];
        rolesData.map(rol=>{
            if (usuarioData.rol === rol.rolId ){
                const option = {
                    opt: <option key={rol.rolId} value={rol.rolId}>{rol.rol}</option>
                }
                mRol.push(option.opt)
            }
            return true;
        });
        rolesData.map(rol=>{
            if (usuarioData.rol !== rol.rolId){
                if(rol.rol !== "manager"){
                    const option = {
                        opt: <option key={rol.rolId} value={rol.rolId}>{rol.rol}</option>
                    }
                    mRol.push(option.opt)
                }
            }
            return true;
        })
        return (
            mRol.map((rol)=>{
                return(
                    rol
                )
            })
        )
    }

    return (
        <div>
            <Modal 
                show={showAlertModal}
                onHide={()=>setShowAlertModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>
            <LoadingModal show={showLoadingModal}/>
            <Modal
                show={showModal}
                onHide={handleClose}
            >
                <form onSubmit={salvarAcudiente}>
                    <Modal.Header>
                        <Modal.Title>{textTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="ID-acudiente">DNI</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder="Documento de identificacion"
                                    aria-label="ID-acudiente"
                                    aria-describedby="ID-acudiente"
                                    value={acudienteDNI}
                                    onChange={e=>setAcudienteDNI(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="nombre-acudiente">Nombre</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    className="capitalize"
                                    placeholder="Nombre del acudiente"
                                    aria-label="Nombre-acudiente"
                                    aria-describedby="nombre-acudiente"
                                    value={acudienteNombre}
                                    onChange={e=>setAcudienteNombre(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="apellido-acudiente">Apellido</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    className="capitalize"
                                    placeholder="Apellido del acudiente"
                                    aria-label="Apellido-acudiente"
                                    aria-describedby="Apellido-acudiente"
                                    value={acudienteApellido}
                                    onChange={e=>setAcudienteApellido(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="email-acudiente">email</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder="email del acudiente"
                                    aria-label="email-acudiente"
                                    aria-describedby="email-acudiente"
                                    value={acudienteEmail}
                                    onChange={e=>setAcudienteEmail(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="telefono-acudiente">Telefono</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    type="number"
                                    placeholder="Telefono del acudiente"
                                    aria-label="telefono-acudiente"
                                    aria-describedby="telefono-acudiente"
                                    value={acudienteTelefono}
                                    onChange={e=>setAcudienteTelefono(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="vinculo-acudiente">Vinculo</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    as="select"
                                    placeholder="Vinculo del acudiente"
                                    aria-label="vinculo-acudiente"
                                    aria-describedby="vinculo-acudiente"
                                    value={acudienteVinculo}
                                    onChange={e=>setAcudienteVinculo(e.target.value)}
                                >
                                    <option value="0">Seleccione...</option>
                                    <option value="Madre">Madre</option>
                                    <option value="Padre">Padre</option>
                                    <option value="Otro">Otro</option>
                                </FormControl>
                            </InputGroup>
                        </Col>
                        <Col md={"auto"} sm={"auto"} xs={"auto"}>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="enable-check">¿Responsable de pagos?</InputGroup.Text>
                                </InputGroup.Prepend>
                                    <span className="input-group-text form-control">
                                        <input 
                                            type="checkbox"
                                            checked={payPerson}
                                            onChange={e=>setPayPerson(e.target.checked)} 
                                            aria-label="Responsable de pagos"
                                        />
                                    </span>        
                            </InputGroup>
                        </Col>
                    </Modal.Body>
                    <Modal.Footer>
                        <LoaderButton 
                            isLoading={isLoading}
                            variant="success"
                            type="submit"
                            disabled={!validarAcudiente()}
                            //onClick={()=>salvarAcudiente()}
                        ><IoMdCheckmark />
                        </LoaderButton>
                    </Modal.Footer>
                </form>
            </Modal>
            <h4>Datos usuario</h4><hr></hr>
            <form onSubmit={enviarCreacion}>
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="ID-usuario">DNI</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="Documento de identificacion"
                                aria-label="ID"
                                aria-describedby="ID-usuario"
                                value={DNI}
                                onChange={e=>setDNI(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="nombre-usuario">Nombre</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="capitalize"
                                placeholder="Nombre del usuario"
                                aria-label="Nombre"
                                aria-describedby="nombre-usuario"
                                value={Nombre}
                                onChange={e=>setNombre(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="apellido-usuario">Apellido</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="capitalize"
                                placeholder="Apellido del usuario"
                                aria-label="Apellido"
                                aria-describedby="apellido-usuario"
                                value={Apellido}
                                onChange={e=>setApellido(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="email-usuario">@</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                placeholder="e-mail del usuario"
                                aria-label="Email"
                                aria-describedby="email-usuario"
                                readOnly={usuarioData.usuarioId ? true : false}
                                value={email}
                                onChange={e=>setEmail(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <Row className="align-items-center">
                    <Col >
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="basic-addon6">¿Nacimiento?</InputGroup.Text>
                            </InputGroup.Prepend>
                                <DatePicker
                                    className="form-control"
                                    selected = {startDate}
                                    value={startDate}
                                    onChange = {date => setStartDate(date)}
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode = 'select'
                                    fixedHeight
                                />
                        </InputGroup>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="rol-usuario">Rol</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl
                                className="capitalize"
                                as="select"
                                placeholder="rol del usuario"
                                aria-label="Rol"
                                aria-describedby="rol-usuario"
                                value={Rol}
                                onChange={e=>setRol(e.target.value)}
                            >
                                {usuarioData.usuarioId ? "" : <option key={"0"} value={"0"}>Seleccione...</option>}
                                {usuarioData.usuarioId ? renderUserRol() : renderRols()}
                            </FormControl>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group controlId="enable-check">
                            <Form.Check 
                                type="checkbox" 
                                label="¿Activo?" 
                                checked={enable}
                                onChange={e=>setEnable(e.target.checked)}    
                            />
                        </Form.Group>
                    </Col>
                </Row>
                {Rol === rolEstudianteId.current
                    ? 
                        <div>
                            <hr></hr>
                            <Row>
                                <Col>
                                    <h4>Acudientes</h4>
                                </Col>
                                <Col className="text-right">
                                    <Button onClick={()=>addAcudiente()}><IoMdAdd /></Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BootstrapTable 
                                        keyField="email"
                                        data={forDummyData.current}
                                        columns={columns.current}
                                        pagination={paginationFactory()}
                                        noDataIndication={bootstrapTableMsg.current}
                                    />
                                </Col>
                            </Row>
                        </div>
                    : ""                
                }
                <hr></hr>
                {config.current ? 
                    !config.current.userCanSetOwnPassword && 
                    <> 
                        <Row>
                            <Col>
                                <h4>Control de acceso</h4>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="clave">Clave</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        placeholder="Contraseña"
                                        type='password'
                                        aria-label="clave"
                                        aria-describedby="clave"
                                        value={clave}
                                        onChange={e=>setClave(e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup className="mb-3">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text id="cclave">Confirmar clave</InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <FormControl
                                        placeholder="Contraseña"
                                        type='password'
                                        aria-label="cclave"
                                        aria-describedby="cclave"
                                        value={cclave}
                                        onChange={e=>setCclave(e.target.value)}
                                    />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Alert variant={'warning'}>
                                    La contraseña debe tener mínimo 8 caracteres, debe incluir numeros, letras mayúsculas, minúsculas y caracteres especiales (*,#)
                                </Alert>
                            </Col>
                        </Row>
                    </>
                : 
                    <></>
                }
                <Row>
                    <Col className="text-right">
                        <LoaderButton 
                            type="submit"
                            variant="success"
                            isLoading={isLoading}
                            disabled={!validarCreacion()}
                        > {textButton}
                        </LoaderButton>
                    </Col>
                </Row>
                <hr></hr>
            </form>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CrearUsuario);