import React, { useRef, useState } from "react";
import { Row, Col, Dropdown, DropdownButton, Form, InputGroup, Button, ButtonGroup, Modal } from "react-bootstrap";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";
import ModalCambiarEstado from "../components/modalCambiarEstado";
import ModalEliminar from "../components/modalEliminar";
import { API } from "aws-amplify";
import LoadingModal from "../components/loadingModal";
import { MdErrorOutline } from "react-icons/md";
import ModalInscripciones from "../components/modalInscripciones";
import { integraCursoCroom } from "../libs/integra";

const mapStateToProps = (state, props) => ({
    institucionId: state.institucionData.institucionId,
    setEditarCurso: props.setEditarCurso
});

const mapDispatchToProps = dispatch => ({
    setCursoData(cursoData){
        dispatch({
            type: "CARGAR_CURSO_DATA",
            cursoData
        })
    }
});

const Cursos = ({setCursoData, institucionId, setEditarCurso}) => {
    const [showModal, setShowModal] = useState(false);
    const [showModalEliminar, setShowModalEliminar] = useState(false);
    const [busqueda, setBusqueda] = useState("");
    const [showLoadingModal, setShowLoadingModal] = useState(false);
    const [bootstrapTableMsg, setBootstrapTableMsg] = useState("Sin cursos para mostrar");
    const cambiarStatusCurso = useRef("");
    const busquedaSelected = useRef("0");
    const [tableData, setTableData] = useState([]);
    const msgModal = useRef("");
    const alertModalTitle = useRef(<MdErrorOutline />);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showModalInscripciones, setShowModalInscripciones] = useState(false);
    const cursoDataSelected = useRef([]);

    const columns = useRef([
        {
            dataField: "internalId", 
            text: "ID",
            headerStyle: () => {
                return { width: '20%' }
            }
        },
        {
            dataField: "nombre", 
            text: "Nombre",
            sort: true,
            style: {
                textTransform: "capitalize"
            }},
        {
            dataField: "status", 
            text: "Estado",
            headerStyle: () => {
                return { width: '15%', textAlign: "right" }
            },
            sort: true,
            style: {
                textAlign: "right"
            }
        }
    ]);

    const handleEditarCurso = (cursoData) => {
        setCursoData(cursoData);
        setEditarCurso(6);
    };

    const handleCurseChange = (curso) => {
        cambiarStatusCurso.current = curso;
        setShowModal(true);
    };

    const handleCurseDelete = (curso) => {
        cambiarStatusCurso.current = curso;
        setShowModalEliminar(true);
    };

    const handleError = (exception) =>{
        switch (exception.code) {
            case "UnableSearchCourse":
                msgModal.current = "No es posible buscar este curso. Intentalo de nuevo, si el problema persiste, por favor ponte en contacto con nosotros.";
                break;
            default:
                msgModal.current = "Error en la aplicación. Intentalo de nuevo, si el problema persiste, comunicate con nosotros.";
                break;
        }
        setShowAlertModal(true);
    };

    const handleCourseParticipants = (cursoData) => {
        cursoDataSelected.current = cursoData;
        setShowModalInscripciones(true);
    };

    const handleIntegrationRequest = (cursoData) => {
        integraCursoCroom(cursoData);
    }

    const getCourses = () =>{
        setShowLoadingModal(true);
        setTableData([]);
        API.get("rocket", "/getCursos", {
            "queryStringParameters": {
                institucionId: institucionId,
                busqueda: busqueda.trim().toLowerCase(),
                index: busquedaSelected.current
            }
        }).then((value)=>{
            switch (value.result) {
                case "success":
                    const tableDataTemp = [];
                    for (let index = 0; index < value.description.length; index++) {
                        const element = value.description[index];
                        const courseData = {
                            cursoId: element.cursoId,
                            internalId: 
                                <DropdownButton variant="link" as={ButtonGroup} title={element.internalId} id={element.internalId}>
                                    <Dropdown.Item onClick={()=>handleEditarCurso(element)} eventKey="1">Editar</Dropdown.Item>
                                    <Dropdown.Item onClick={()=>handleCourseParticipants(element)} eventKey="4">Participantes</Dropdown.Item>
                                    {element.status ? <Dropdown.Item onClick={()=>handleIntegrationRequest(element)} eventKey="5">Integración</Dropdown.Item> : "" }
                                    <Dropdown.Item onClick={()=>handleCurseChange(element)} eventKey="2">Activar/Deshabilitar</Dropdown.Item>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item onClick={()=>handleCurseDelete(element)} eventKey="3">Eliminar curso</Dropdown.Item>
                                </DropdownButton>,
                            nombre: element.nombre,
                            status: (element.status ? "Activo" : "Deshabilitado")
                        };
                        tableDataTemp.push(courseData);
                    }
                    setTableData(tableDataTemp);
                    break;
                case "fail":
                    setBootstrapTableMsg("No se encontraron cursos");
                    break;
                default:
                    break;
            }            
            setShowLoadingModal(false);
        }).catch(error=>{
            console.log(error);
            handleError("error");
        })
    };

    const handleEnabled = () => {
        return busqueda.length > 3;
    };

    return (
        <div>
            <ModalInscripciones 
                show={showModalInscripciones}
                setShow={setShowModalInscripciones}
                dataData={cursoDataSelected.current} 
                inscripcionTypeFlag={false}
                manageInscripcionesFlag={true}
            />
            <Modal 
                show={showAlertModal}
                onHide={()=>setShowAlertModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>
            <LoadingModal showLoadingModal={showLoadingModal}/>
            {cambiarStatusCurso.current ? 
                <ModalCambiarEstado 
                    setShow={setShowModal}
                    show={showModal}
                    elementData={cambiarStatusCurso.current}
                    object='cursos'
                ></ModalCambiarEstado>
            :
                <></>
            }
            {cambiarStatusCurso.current ? 
                <ModalEliminar 
                    setShow={setShowModalEliminar}
                    show={showModalEliminar}
                    elementData={cambiarStatusCurso.current}
                    object='cursos'
                ></ModalEliminar>
            :
                <></>
            }
            <h4>Encontrar cursos</h4><hr></hr>
            <Col>
                <Row>
                    <Col sm={"auto"}>
                        <Form.Label className="mr-sm-2" htmlFor="opciones-busqueda" srOnly>
                            Opciones de busqueda
                        </Form.Label>
                        <Form.Control
                            as="select"
                            onChange={e=>busquedaSelected.current = e.target.value}
                            className="mr-sm-2"
                            id="opciones-busqueda"
                            custom
                        >
                            <option value="0">Nombre curso</option>
                            <option value="1">ID curso</option>
                        </Form.Control>
                    </Col>
                    <Col>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="Criterios de busqueda"
                                aria-label="Criterios de busqueda"
                                aria-describedby="basic-addon2"
                                value={busqueda}
                                onChange={e=>setBusqueda(e.target.value)}
                            />
                            <InputGroup.Append>
                                <Button autoFocus disabled={!handleEnabled()} onClick={()=>getCourses()} variant="outline-secondary">Buscar</Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                </Row>
            </Col>
            <Row>
                <Col>
                    <Col>
                        <BootstrapTable
                            keyField="cursoId"
                            data={tableData}
                            columns={columns.current}
                            pagination={paginationFactory()}
                            noDataIndication={bootstrapTableMsg}
                        />
                    </Col>
                </Col>
            </Row>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(Cursos);