import React, { useState, useEffect, useRef } from "react";
import { Button, Col, Row, Spinner, InputGroup, FormControl, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import API from "@aws-amplify/api";

const mapStateToProps = (state, props) => ({
    userData: state.userAuthenticated,
    props: props
});

const mapDispatchToProps = dispatch => ({});

const CrearAnuncio = ({userData, props}) => {

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const[title, setTitle] = useState(props.anuncio_data ? props.anuncio_data.titulo : "");
    const [showAlertMsg, setShowAlertMsg] = useState(false);

    const [editorState, setEditorState] = useState(props.anuncio_data ? EditorState.createWithContent(convertFromRaw(props.anuncio_data.anuncio)) : EditorState.createEmpty());

    const alertMsg = useRef("");

    useEffect(()=>{
        if(userData.rolName === "docente" || userData.rolName === "coordinador" || userData.rolName === "manager"){
            setIsLoading(false);
        } else {
            history.push("/");
        }
    },[userData, history])

    const handleEditorStateChange = (eState) => {
        setEditorState(eState);
    }

    /**
     * Enviar anuncio por correo a todos los participantes activos del curso
     * ó a todos los miembros de la institución
     */
    const sendAnuncio = async(msg) => {
        const mailData = {
            service: props.cursoId ? 0 : 1,
            TO: [props.cursoId ? props.cursoId : userData.institucionId],
            asunto: title.charAt(0).toUpperCase() + title.slice(1),
            CC: ["principal@brainyfun.pe"],
            message: {
                data: msg
            }
        };
        try{
            const response = await API.post("rocket", "/mailer",{
                body: mailData
            });
            if(response.result === "success"){
                console.log("Correos electrónicos enviados correctamente");
            } else {
                console.log(response);
            }
        } catch (error){
            console.log(error);
        }
    }

    const handlePublish = async() => {
        setShowAlertMsg(false);
        const raw_data = convertToRaw(editorState.getCurrentContent());
        const msg_html = draftToHtml(raw_data);
        if(raw_data.blocks[0].text !== ""){
            if(props.anuncio_data){
                const anon_data = {
                    institucionId: userData.institucionId,
                    propiedades: [
                        {
                            propiedad: "tituloAnuncio",
                            value: title.trim().toLowerCase()
                        },
                        {
                            propiedad: "textoAnuncio",
                            value: raw_data
                        },
                        {
                            propiedad: "fecha",
                            value: Date.now()
                        },
                    ]
                };
                try{
                    setIsLoading(true);
                    const response = await API.put("rocket", "/anuncios/"+props.anuncio_data.anuncioId, {
                        body: anon_data
                    });
                    if (response.result === "success"){
                        props.onFinish(false);
                        sendAnuncio(msg_html);
                    } else {
                        setIsLoading(false);
                        alertMsg.current = "No es posible actualizar el anuncio";
                        setShowAlertMsg(true);
                    }
                } catch(error){
                    console.log(error);
                    setIsLoading(false);
                    alertMsg.current = "Error: No es posible actualizar el anuncio";
                    setShowAlertMsg(true);
                }
            } else {
                const anuncio_data = {
                    institucionId: userData.institucionId,
                    tituloAnuncio: title.trim().toLowerCase(),
                    textoAnuncio: raw_data,
                    cursoId: props.cursoId,
                    estado: true
                };
                try{
                    setIsLoading(true);
                    const response = await API.post("rocket","/crearAnuncio",{
                        body: anuncio_data
                    })
                    if(response.result === "success"){
                        props.onFinish(false); 
                        sendAnuncio(msg_html);
                    } else {
                        console.log(response);
                        setIsLoading(false);
                        alertMsg.current = "No es posible publicar el anuncio";
                        setShowAlertMsg(true);
                    }
                } catch(error){
                    console.log(error);
                    setIsLoading(false);
                    alertMsg.current = "Error: No es posible publicar el anuncio";
                    setShowAlertMsg(true);
                }
            }
        } else {
            alertMsg.current = "Parece que falta el cuerpo del anuncio..."
            setShowAlertMsg(true);
        }
    }

    const handleDisabled = () => {
        return title.length > 4
    }

    return (
        <div>
            {isLoading ? 
                <Row>
                    <Col className="text-center">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <div>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="title-anuncio">Título</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    className="capitalize"
                                    placeholder="Título del anuncio"
                                    aria-label="title"
                                    aria-describedby="title-anuncio"
                                    value={title}
                                    onChange={e=>setTitle(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Editor 
                                editorState={editorState}
                                wrapperClassName="editor-border"
                                editorClassName="text-editor"
                                onEditorStateChange={estate=>handleEditorStateChange(estate)}
                                toolbar={{
                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'history'],
                                    inline: {
                                        options: ['bold', 'italic', 'underline']
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Alert show={showAlertMsg} variant={"warning"}>
                                {alertMsg.current}
                            </Alert>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-right">
                            <Button disabled={!handleDisabled()} onClick={()=>handlePublish()}>Publicar</Button>
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(CrearAnuncio)