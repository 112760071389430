import { createStore } from "redux";

const initialState = {
    institucionData: {},

    rolesData: [],

    userAuthenticated: {
        autenticado: false,
        usuarioId: "",
        nombre: "",
        apellido: "",
        intitucionId: "",
        rol: "",
        email: "",
        identificación: "",
        status: "",
        nacimiento: ""
    },

    cursoData: {},

    usuarioData: {},

    integraciones: {},

    formularioId: null,

    configMatriculas: {}
}

const reducer = (state = initialState, action) => {

    switch (action.type) {
        case "USER_PERFORM_LOGIN":
            return {
                ...state,
                userAuthenticated: action.auth_user_data
            }
        case "CARGAR_CURSO_DATA":
            return {
                ...state,
                cursoData: action.cursoData
            }
        case "CARGAR_USUARIO_EDITAR":
            return {
                ...state,
                usuarioData: action.usuarioData
            }
        
        case "CARGAR_INSTITUCION_DATA":
            return {
                ...state,
                institucionData: action.institucion_data
            }

        case "SET_INTEGRACIONES":
            return {
                ...state,
                integraciones: action.integracionesData
            }

        case "SET_ROLES":
            return {
                ...state,
                rolesData: action.rolesData
            }
        
        case "MATRICULAS_FORMULARIO_EDIT":
            return {
                ...state,
                formularioId: action.formularioId
            }
            
        case "SET_MATRICULAS_CONFIG":
            return {
                ...state,
                configMatriculas: action.configMatriculas
            }
        default:
            break;
    }
    return state;
}

export default createStore(reducer)