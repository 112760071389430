import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Row, Col, Spinner, Form, Modal } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { connect } from 'react-redux';
import { onError } from '../libs/errorLib';

const mapStateToProps = state => ({
    userData: state.userAuthenticated,
});

const mapDispatchToProps = dispatch => ({});

const MatriculasConfig = ({userData}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [enableMatriculas, setEnableMatriculas] = useState(false)
    const [urlReenvio, setUrlReenvio] = useState('');
    const [urlCondiciones, setUrlCondiciones] = useState('');
    const [defaultForm, setDefaultForm] = useState({
        formularioId: 0,
        nombre: 'Ninguno'
    });
    const [showErrorModal, setShowErrorModal] = useState(false);

    const errorContent = useRef('');
    const isFirstRender = useRef(true);
    const formsAvailables = useRef([]);
    const matriculasConfig = useRef({});

    const handleErrors = (msg) => {
        setShowErrorModal(false);
        errorContent.current = msg;
        setShowErrorModal(true);
    };

    const getMatriculasConfig = useCallback(async()=>{
        setIsLoading(true);
        formsAvailables.current = [];
        matriculasConfig.current = {};
        try {
            const qsp = {
                get: ['matriculas']
            };
            const response = await API.get('rocket','/config/'+userData.institucionId,{
                queryStringParameters: qsp,
            });
            if(response.result === 'fail'){
                onError(response);
                const err = 'GetMatriculasConfigException';
                throw (err);
            } else {
                const qsp2 = {
                    institucionId: userData.institucionId,
                    get: ['formularioId', 'nombre']
                };
                const dataRespond = await API.get('rocket','/getMatriculasFormularios',{
                    queryStringParameters: qsp2,
                });
                if(Array.isArray(dataRespond)){
                    formsAvailables.current = dataRespond;
                    formsAvailables.current.splice(0,0,defaultForm);
                    matriculasConfig.current = response.description.matriculas;
                    if(matriculasConfig.current.hasOwnProperty('activas')){
                        setEnableMatriculas(matriculasConfig.current.activas);
                    }
                    if(matriculasConfig.current.hasOwnProperty('formulario')){
                        setDefaultForm(matriculasConfig.current.formulario);
                    }
                    if(matriculasConfig.current.hasOwnProperty('urlReenvio')){
                        setUrlReenvio(matriculasConfig.current.urlReenvio);
                    }
                    if(matriculasConfig.current.hasOwnProperty('urlCondiciones')){
                        setUrlCondiciones(matriculasConfig.current.urlCondiciones);
                    }
                } else {
                    if(!dataRespond.result){
                        onError(dataRespond);
                        const err = 'GetFormulariosException';
                        throw (err);
                    }
                }
            }
            setIsLoading(false);
        } catch (error) {
            onError(error);
            handleErrors('No se puede obtener la configuración de las matrículas en este momento, intentalo de nuevo mas tarde');
        }
    },[userData, defaultForm]);

    useEffect(()=>{
        
        if(isFirstRender.current){
            isFirstRender.current = false;
            getMatriculasConfig();
        }
    },[getMatriculasConfig]);

    /**Modelo datos almacenar config de matriculas
        * matriculasConfig = {
        *  activas: true | false,
        *  formulario: {
        *      formularioId: String,
        *      nombre: String
        *  },
        *  urlReenvio: String
        * }
    */

    const handleEnableMatriculas = (checked) => {
        setEnableMatriculas(checked);
        matriculasConfig.current = {
            activas: Boolean(checked),
        };
        updateMatriculasConfig();
    };

    const handleDefaultFormChange = (value, nombre) => {
        if(value === '0'){
            matriculasConfig.current = {
                formulario: {
                    formularioId: 0,
                    nombre: 'Ninguno'
                },
            };
        } else {
            matriculasConfig.current = {
                formulario: {
                    formularioId: value,
                    nombre: nombre
                },
            };
        }
        setDefaultForm(matriculasConfig.current.formulario);
        updateMatriculasConfig();
        
    };

    const handleUrlChange = (value) => {
        matriculasConfig.current = {
            urlReenvio: value,
        };
        updateMatriculasConfig();
    };

    const handleCondicionesChange = (value) => {
        matriculasConfig.current = {
            urlCondiciones: value,
        };
        updateMatriculasConfig();
    };

    const updateMatriculasConfig = async() =>{
        const newConfigData = {
            propiedad: 'matriculas',
            value: matriculasConfig.current,
        };
        try {
            const response = await API.put('rocket','/config/'+userData.institucionId,{
                body: newConfigData,
            });
            if(response.result === 'fail'){
                onError(response);
                const err = 'SaveConfigMatriculasException';
                throw (err);
            } else {
                console.log(response);
            }
        } catch(error){
            onError(error);
            handleErrors('No se pueden guardar los cambios en la configuración en este momento, intentalo de nuevo mas tarde.');
        }
    };

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    };

    return (
        <div>
            {showErrorModal && 
            <Modal 
                show={showErrorModal}
                onHide={handleCloseErrorModal}
                size={'lg'}
            >
                <Modal.Header closeButton>
                    Configuración
                </Modal.Header>
                <Modal.Body>
                    {errorContent.current}
                </Modal.Body>
            </Modal>
            }
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-1 pt-1">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <div>
                    <Row className='mt-3'>
                        <Col>
                            <Form.Group className='mb-3' controlId="enableMatriculas">
                                <Form.Check 
                                    type="checkbox" 
                                    checked={enableMatriculas}
                                    onChange={(e) => handleEnableMatriculas(e.target.checked)}                                        
                                    label="¿Habilitar matrículas?" 
                                />
                                    <Form.Text className="text-muted">
                                        Si seleccionas esta opción, Rocket habilitará el acceso a los formularios de matrículas.
                                    </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className='mb-3' controlId="formularioPorDefecto">
                                <Form.Label>Formulario de matrícula por defecto</Form.Label>
                                <Form.Control 
                                    as="select"
                                    value={defaultForm.formularioId}
                                    onChange={(e)=>handleDefaultFormChange(e.target.value, e.target.options[e.target.selectedIndex].label)}
                                >
                                    {/* <option label={defaultForm.nombre} value={defaultForm.formularioId}>{defaultForm.nombre}</option> */}
                                    {formsAvailables.current.map((f)=>{
                                        return (
                                            <option key={f.formularioId} label={f.nombre} value={f.formularioId}>{f.nombre}</option>
                                        )
                                    })}
                                </Form.Control>
                                <Form.Text className="text-muted">
                                    Aunque puedes usar diferentes formularios para matricular a nuevos estudiantes, al seleccionar uno por defecto, los estudiantes actuales tendrán acceso a este formulario desde el menú de accesos directos de Rocket.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group 
                                className='mb-3'
                                controlId="urlReenvio"
                            >
                                <Form.Label>Url de reenvío</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="https://rocket.gvalor.co" 
                                    value={urlReenvio}
                                    onBlur={(e)=>handleUrlChange(e.target.value)}
                                    onChange={(e)=>setUrlReenvio(e.target.value)}/>
                            </Form.Group>
                            <Form.Text className="text-muted">
                                Puedes adicionar una dirección web a donde serán redirigidos los candidatos una vez completen el formulario de matrícula, si no deseas que sean redirigidos, deja este campo en blanco.
                            </Form.Text>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col>
                            <Form.Group 
                                className='mb-3'
                                controlId="urlReenvio"
                            >
                                <Form.Label>Url de política/condiciones de servicio</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    placeholder="https://www.gvalor.co/condiciones-de-uso" 
                                    value={urlCondiciones}
                                    onBlur={(e)=>handleCondicionesChange(e.target.value)}
                                    onChange={(e)=>setUrlCondiciones(e.target.value)}/>
                            </Form.Group>
                            <Form.Text className="text-muted">
                                Cuando un candidato completa su formulario de matrícula, Rocket mostrará el enlace de acceso a las condiciones 
                                de uso,  así como la politica de privacidad, si deseas mostrar al candidato las condiciones o política de 
                                datos de tu institución, agrega aquí la dirección de acceso, de lo contrario, 
                                deja este campo en blanco.
                            </Form.Text>
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(MatriculasConfig);