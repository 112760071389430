import React, { useState, useRef, useEffect, useCallback } from "react";
import { Row, Col, Button, Spinner, Modal, Alert } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { API } from "aws-amplify";
import { connect } from "react-redux";
import { AiOutlineFileSearch, 
    AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { MdAdd } from 'react-icons/md';
import { useHistory } from "react-router";
import { onError } from "../libs/errorLib";
import FormMatriculaGenerate from "./formMatriculaGenerate";

const mapStateToProps = state => ({
    institucionData: state.institucionData,
    userData: state.userAuthenticated
});

const mapDispatchToProps = dispatch => ({
    setFormularioId(formularioId){
        dispatch({
            type: "MATRICULAS_FORMULARIO_EDIT",
            formularioId
        })
    }
});

const FormsMatriculaList = ({userData, institucionData, setFormularioId}) => {

    const [isLoading, setIsLoading] = useState(true);
    const [showPreviewFormModal, setShowPreviewFormModal] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showDeleteFormModal, setShowDeleteFormModal] = useState(false);

    const history = useHistory();

    const isFirstRender = useRef(true);
    const fieldsFormPreview = useRef([]);
    const errorContent = useRef('');
    const formToDelete = useRef(null);

    // const formsData = useRef([
    //     {
    //         formularioId: '01',
    //         nombre: 'Formulario dummy',
    //     },
    //     {
    //         formularioId: '02',
    //         nombre: 'Formulario dummy 2',
    //     },
    // ]);

    const formsData = useRef([]);

    const columns = useRef([
        {
            dataField: 'nombre',
            text: 'Formulario',
            headerStyle: () => {
                return { 
                    overflow: 'hidden',
                    textOverflow: 'ellipsis', 
                }
            },
            style: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        },
        {
            dataField: 'editar',
            text: 'Editar',
            headerStyle: () => {
                return { 
                    width: '20%', 
                    textAlign: "center",
                    overflow: 'hidden',
                    textOverflow: 'ellipsis', 
                }
            },
            style: {
                textAlign: "center",
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        },
        {
            dataField: 'preview',
            text: 'Previsualizar',
            headerStyle: () => {
                return { 
                    width: '20%', 
                    textAlign: "center",
                    overflow: 'hidden',
                    textOverflow: 'ellipsis', 
                }
            },
            style: {
                textAlign: "center",
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        },
        {
            dataField: 'eliminar',
            text: 'Borrar',
            headerStyle: () => {
                return { 
                    width: '20%', 
                    textAlign: "center",
                    overflow: 'hidden',
                    textOverflow: 'ellipsis', 
                }
            },
            style: {
                textAlign: "center",
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            },
        },
    ]);
    
    const handleErrors = (error) => {
        setShowErrorModal(false);
        errorContent.current = error;
        setShowErrorModal(true);
    };

    const handleFormPreview = useCallback(async(formularioId) => {
        setShowPreviewFormModal(true);
        setShowForm(false);
        try {
            const qsp = {
                institucionId: institucionData.institucionId,
                formularioId: formularioId
            };
            const respond = await API.get('rocket', '/getMatriculaFormulario', {
                queryStringParameters: qsp,
            });
            if(respond.result){
                fieldsFormPreview.current = respond.description.requiredFields;
                setShowForm(true);
            } else {
                onError(respond.description);
                const err = 'No se puede previsualizar el formulario en este momento, intentalo de nuevo mas tarde';
                throw (err);
            }
        } catch (error) {
            setShowPreviewFormModal(false);
            onError(error);
            handleErrors('No se puede previsualizar el formulario en este momento, intentalo de nuevo mas tarde');
        }
    },[institucionData]);

    const handleEditForm = useCallback((formularioId) => {
        setFormularioId(formularioId);
        history.push("/formulariomatricula");
    },[history, setFormularioId]);

    const handleCreateNewform = () => {
        history.push('/formulariomatricula');
    };

    const handleDeleteForm = (formularioId) => {
        //TODO: mostrar modal para confirmar eliminación, 
        //llamar API para eliminar el formulario, 
        //llamar getFormularios
        setShowDeleteFormModal(true);
        formToDelete.current = formularioId;
    };

    const getFormularios = useCallback(async() => {
        //carga formularios existentes para la institucion
        setIsLoading(true);
        try {
            const qsp = {
                institucionId: userData.institucionId,
                get: ['formularioId', 'nombre']
            };
            const dataRespond = await API.get('rocket','/getMatriculasFormularios',{
                queryStringParameters: qsp,
            });
            if(Array.isArray(dataRespond)){
                formsData.current = dataRespond;
                for (let index = 0; index < formsData.current.length; index++) {
                    const element = formsData.current[index];
                    element.nombre = <a rel="noopener noreferrer" href={'/matriculas?instId='+userData.institucionId+'&fId='+element.formularioId} target='_blank' >{element.nombre}</a>
                    formsData.current[index].editar = <Button onClick={()=>handleEditForm(element.formularioId)} className="action-button" variant="outline-warning" ><AiOutlineEdit size={30}/></Button>;
                    formsData.current[index].preview = <Button onClick={()=>handleFormPreview(element.formularioId)} className="action-button" variant="outline-info" ><AiOutlineFileSearch size={30}/></Button>;
                    formsData.current[index].eliminar = <Button onClick={()=>handleDeleteForm(element.formularioId)} className="action-button" variant="outline-danger" ><AiOutlineDelete size={30}/></Button>;
                }
                setIsLoading(false);
            } else {
                if(!dataRespond.result){
                    onError(dataRespond);
                    const err = 'GetFormulariosException';
                    throw (err);
                }
            }
        } catch (error) {
            setIsLoading(false);
            onError(error);
            handleErrors('No se pueden obtener los formularios en este momento, intentalo de nuevo mas tarde');
        }
    },[userData, handleEditForm, handleFormPreview]);

    useEffect(()=>{
        if(isFirstRender.current){
            isFirstRender.current = false;
            getFormularios();
        }
    },[getFormularios]);

    const handleCloseErrorModal = () => {
        setShowErrorModal(false);
    };

    const handleClosePreviewModal = () => {
        setShowPreviewFormModal(false);
    };

    const deleteFormulario = async() => {
        setIsLoading(true);
        if(formToDelete.current){
            try {
                const data = {
                    institucionId: institucionData.institucionId,
                };
                const respond = await API.del('rocket','/matriculas/formularios/'+formToDelete.current,{
                    body: data,
                });
                if(!respond.result){
                    onError(respond);
                    const err = 'No se puede eliminar el formulario en este momento, intentalo de nuevo mas tarde';
                    throw (err);
                }
                getFormularios();
                setShowDeleteFormModal(false);
            } catch (error) {
                setIsLoading(false);
                onError(error);
                handleErrors('No se puede eliminar el formulario en este momento, intentalo de nuevo mas tarde');
            }
        }
        formToDelete.current = null;
    };

    const handleCloseDeleteFormModal = () => {
        setShowDeleteFormModal(false);
    };

    return (
        <div>
            {showDeleteFormModal && 
            <Modal 
                show={showDeleteFormModal}
                onHide={handleCloseDeleteFormModal}
                size={'lg'}
            >
                <Modal.Header closeButton>
                    Formularios
                </Modal.Header>
                <Modal.Body>
                    <Alert variant='danger'>
                        ¿Estas segur@ de eliminar este formulario?
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=>deleteFormulario()} variant='outline-danger'>Si, elimina el formulario</Button>
                </Modal.Footer>
            </Modal>
            }
            {showErrorModal && 
            <Modal 
                show={showErrorModal}
                onHide={handleCloseErrorModal}
                size={'lg'}
            >
                <Modal.Header closeButton>
                    Formularios
                </Modal.Header>
                <Modal.Body>
                    {errorContent.current}
                </Modal.Body>
            </Modal>
            }
            {showPreviewFormModal && 
            <Modal 
                show={showPreviewFormModal}
                onHide={handleClosePreviewModal}
                size={'lg'}
            >
                <Modal.Header closeButton>
                    Previsualización formulario de matricula
                </Modal.Header>
                <Modal.Body>
                    {showForm ? 
                        <FormMatriculaGenerate 
                            preview={true} 
                            fields={fieldsFormPreview.current}
                        />
                    :
                        <Row>
                            <Col className="text-center mt-1 pt-1">
                                <Spinner animation="border" variant="primary" />
                            </Col>
                        </Row>
                    }
                </Modal.Body>
            </Modal>}
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-1 pt-1">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <div>
                    <Row className='mt-3 mb-1'>
                        <Col className='text-right'>
                            <Button title='Crear nuevo formulario de matrícula' onClick={()=>handleCreateNewform()} variant="outline-success"><MdAdd /></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BootstrapTable 
                                keyField="formularioId"
                                data={formsData.current}
                                columns={columns.current}
                                noDataIndication={'No se encontrarón formularios de matricula'}
                                pagination={paginationFactory()}
                            />
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(FormsMatriculaList);