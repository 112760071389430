import React, { useState } from 'react';
import { Row, Col, InputGroup, Form, Button } from 'react-bootstrap';
import { nanoid } from "nanoid";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from 'react-bootstrap-table2-editor';
import { IoMdAdd, IoMdRemove } from 'react-icons/io';

const FormMatriculaEditSelect = (props) => {

    const [label, setLabel] = useState(props.elementToEdit.label);
    const mandatory = Boolean(props.elementToEdit.mandatory);
    const [required, setRequired] = useState(props.elementToEdit.required);
    const [options, setOptions] = useState(props.elementToEdit.options);

    const handleEnableSave = () =>  {
        return label.length > 3 && options.length > 1;
    };

    const columns = [
        {
            dataField: 'option',
            text: 'Opción',
            headerStyle: () => {
                return { 
                    overflow: 'hidden',
                    textOverflow: 'ellipsis', 
                }
            },
            style: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }
        },
        {
            dataField: 'value',
            text: 'Valor',
            headerStyle: () => {
                return { 
                    overflow: 'hidden',
                    textOverflow: 'ellipsis', 
                    textAlign: 'right',
                }
            },
            style: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'right',
            }
        },
    ]

    const handleSave = () => {
        const elementEdited = props.elementToEdit;
        elementEdited.label = label.trim();
        elementEdited.required = required;
        elementEdited.options = options
        props.onComplete(elementEdited);
    }

    const handleAddOption = () => {
        const newOption = {
            id: nanoid(3),
            option: 'nueva opción',
            value: 'nuevo valor',
        };
        const localOptions = [...options];
        localOptions.push(newOption);
        setOptions(localOptions);
    };

    const handleRemoveOption = () => {
        const localOptions = [...options];
        localOptions.splice(localOptions.length -1);
        setOptions(localOptions);
    };

    return (
        <div>
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Etiqueta</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control 
                            type="text" 
                            value={label}                    
                            onChange={e=>setLabel(e.target.value)}
                            required
                            isInvalid={!(label.length > 3)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Ingresa un texto para la etiqueta.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check 
                        type='checkbox'
                        label='¿Requerido?'
                        checked={Boolean(required)}
                        disabled={mandatory}
                        onChange={()=>setRequired(!Boolean(required))}
                    />
                </Col>
            </Row>
            <Row className='justify-content-end mt-5 mb-1'>
                <Col><h4>Opciones de la lista</h4></Col>
                <Col xs='auto' className='mr-0 pr-0'>
                    <Button onClick={()=>handleAddOption()} variant='outline-success'><IoMdAdd /></Button>
                </Col>
                <Col xs='auto'>
                    <Button onClick={()=>handleRemoveOption()} variant='outline-danger'><IoMdRemove /></Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <BootstrapTable 
                        keyField="id"
                        data={options}
                        columns={columns}
                        pagination={paginationFactory()}
                        noDataIndication={'No hay opciones, debes ingresar al menos dos opciones'}
                        cellEdit={
                            cellEditFactory({
                            blurToSave: true,
                            mode: 'click',
                        })}
                    />
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col className='mt-3 text-right'>
                    <Button 
                        onClick={()=>handleSave()} 
                        disabled={!handleEnableSave()} 
                        variant='outline-primary'
                    >Guardar</Button>
                </Col>
            </Row>
        </div>
    )
};

export default FormMatriculaEditSelect;