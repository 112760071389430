import React, { useState, useRef, useEffect, useCallback } from "react";
import { Col, Row, Spinner, Modal, Button, Dropdown, InputGroup, FormControl } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MdRefresh, MdAdd, MdNavigateNext, MdNavigateBefore, MdDelete, MdErrorOutline } from "react-icons/md";
import cellEditFactory from 'react-bootstrap-table2-editor';
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { useHistory } from "react-router";
import LoaderButton from "../components/loaderButton";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

const mapStateToProps = state => ({
    userData: state.userAuthenticated
});

const mapDispatchToProps = dispatch => ({});

const RegistrarIngresos = ({userData}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [textButton, setTextButton] = useState("Guardar");
    const [startDate, setStartDate] = useState(new Date());
    const tmpStartDate = useRef(new Date());
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showAddIngresoModal, setShowAddIngresoModal] = useState(false);
    const [isObSelected, setIsObSelected] = useState(false);
    const [descripcion, setDescripcion] = useState("");
    const [costo, setCosto] = useState(0);

    const bootstrapTableMsg = useRef("No hay datos que mostrar");
    const alertModalTitle = useRef(<MdErrorOutline />);
    const msgModal = useRef("");
    const tableData = useRef([]);
    const obligacionSelected = useRef("");

    const history = useHistory();

    const columns = useRef([
        // {
        //     dataField: "identificacion", 
        //     text: "Identificación",
        //     headerStyle: () => {
        //         return { width: '20%' }
        //     }
        // },
        {
            dataField: "estudiante", 
            text: "Estudiante",
            sort: true,
            filter: textFilter({
                placeholder: 'Filtro',
            }),
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "descripcion", 
            text: "Obligacion",
            sort: true,
            filter: textFilter({
                placeholder: 'Filtro',
            }),
        },
        {
            dataField: "costo", 
            text: "Costo",
            sort: true,
            filter: textFilter({
                placeholder: 'Filtro',
            }),
            editable: true,
            headerStyle: () => {
                return { width: '15%', textAlign: "right" }
            },
            style: {
                textAlign: "right"
            },
        },
        {
            dataField: "pago", 
            text: "Pagos",
            sort: true,
            headerStyle: () => {
                return { width: '15%', textAlign: "right" }
            },
            style: {
                textAlign: "right"
            },
        },
        {
            dataField: "saldo", 
            text: "Saldo",
            sort: true,
            editable: false,
            headerStyle: () => {
                return { width: '15%', textAlign: "right" }
            },
            style: {
                textAlign: "right"
            },
        },
    ]);

    const getObligaciones = useCallback(async()=>{
        tableData.current = [];
        setIsObSelected(false);
        const periodoInit = Date.parse((tmpStartDate.current.getMonth() + 1)+"/"+1+"/"+tmpStartDate.current.getFullYear());
        const monthLastDay = new Date(tmpStartDate.current.getFullYear(),(tmpStartDate.current.getMonth()+1),0).getDate();
        const periodoEnd = Date.parse((tmpStartDate.current.getMonth() + 1)+"/"+monthLastDay+"/"+tmpStartDate.current.getFullYear());
        const qsp = {
            periodoInit: periodoInit,
            periodoEnd: periodoEnd,
            institucionId: userData.institucionId,
        }
        try{
            const response = await API.get("rocket", "/getObligaciones", {
                queryStringParameters: qsp
            });
            if(response.result === "success"){
                for (let index = 0; index < response.description.length; index++) {
                    const element = response.description[index];
                    const data = {
                        obligacionId: element.obligacionId,
                        estudiante: element.nombre ? element.nombre + " " + element.apellido : "",
                        identificacion: element.identificacion,
                        descripcion: element.descripcion.charAt(0).toUpperCase() + element.descripcion.slice(1),
                        costo: parseFloat(element.costo),
                        saldo: element.pago ? parseFloat(element.costo) - parseFloat(element.pago) : parseFloat(element.costo),
                        pago: element.pago ? parseFloat(element.pago) : 0,
                    }
                    tableData.current.push(data);
                }
                setIsLoading(false);
            } else {
                console.log(response);
                setIsLoading(false);
            }
        } catch(error){
            console.log(error);
            setIsLoading(false);
        }
    },[userData])

    useEffect(()=>{
        if(userData.rolName === "financiero" || userData.rolName === "manager"){
            if(isFirstRender){
                getObligaciones();
                setIsFirstRender(false);
            }
        } else {
            history.push("/");
        }
    },[history, userData, getObligaciones, isFirstRender]);

    const handlePreviousMonth = () =>{
        setIsLoading(true);
        var previousYear = 0;
        var previousMonth = 1;
        if (startDate.getMonth() === 0){
            previousMonth = -11;
            previousYear = 1;
        }
        const newDate = Date.parse(((startDate.getMonth() + 1) - previousMonth)+"/"+startDate.getDate()+"/"+(startDate.getFullYear() - previousYear));
        tmpStartDate.current = new Date(newDate);
        setStartDate(new Date(newDate));
        getObligaciones();
    }

    const handleNextMonth = () =>{
        setIsLoading(true);
        var nextYear = 0;
        var nextMonth = 1;
        if (startDate.getMonth() === 11){
            nextMonth = -11;
            nextYear = 1;
        }
        const newDate = Date.parse(((startDate.getMonth() + 1) + nextMonth)+"/"+startDate.getDate()+"/"+(startDate.getFullYear() + nextYear));
        tmpStartDate.current = new Date(newDate);
        setStartDate(new Date(newDate));
        getObligaciones();
    }

    const handleOnSelect = (row, isSelect) => {
        if (isSelect){
            obligacionSelected.current = row;
            setIsObSelected(true);
        } else {
            obligacionSelected.current = "";
            setIsObSelected(false);
        }
    }

    const handleErrors = (exception) => {
        msgModal.current = "";
        switch (exception.code) {
            case "ValorPagoInvalido":
                msgModal.current = "El monto del pago no puede ser mayor al monto del saldo."
                break;
            case "SaveObligacionException":
                msgModal.current = "No se ha podido guardar el cambio, intentalo de nuevo. Si el problema persiste comunicate con nosotros."
                break;
            case "AdicionarIngresoExcepcion":
                msgModal.current = "No se ha podido guardar el nuevo ingreso, intentalo de nuevo. Si el problema persiste comunicate con nosotros."
                break;
            default:
                msgModal.current = "Error en la aplicación, intentalo de nuevo. Si el problema persiste comunicate con nosotros."
                break;
        }
        setShowAlertModal(true);
    }

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        clickToEdit:  true,
        onSelect: handleOnSelect
    }

    const handleSaveValue = (oldValue, newValue, row, column) => {
        if(oldValue !== newValue){
            var data = "";
            var sendToDb = false;
            if(column.dataField === "pago"){
                var cost = parseFloat(row.costo);
                newValue = parseFloat(newValue.replace(/,/g, "."));
                if(!isNaN(newValue)){
                    if(newValue > cost){
                        const exc = {
                            code: "ValorPagoInvalido"
                        }
                        handleErrors(exc);
                        row.pago = oldValue;
                    } else {
                        row.saldo = parseFloat(row.costo) - parseFloat(newValue);
                        row.pago = newValue;
                        data = {
                            propiedad: column.dataField,
                            value: newValue
                        }
                        sendToDb = true
                    }
                } else {
                    row.pago = oldValue;
                }                
            } else {
                data = {
                    propiedad: column.dataField,
                    value: newValue.trim().toLowerCase()
                }
                sendToDb = true
            }
            if(sendToDb){
                API.put("rocket","/obligaciones/"+row.obligacionId, {
                    body: data
                }).then(value => {
                    if(value.result === "fail"){
                        console.log(value);
                        const exc = {
                            code: "SaveObligacionException"
                        }
                        handleErrors(exc);
                    } else {
                        console.log("Se actualizo correctamente");
                    }
                }).catch(error => {
                    console.log(error);
                    handleErrors("Exc");
                })
            }
        }
    }

    const handleCloseAlertModal = () => {
        setShowAlertModal(false);
    }

    const handleUpdateData = () => {
        setIsLoading(true);
        getObligaciones();
    }

    const handleDeleteObligacion = async() => {
        setIsLoading(true);
        const response = await API.del("rocket", "/obligaciones/"+obligacionSelected.current.obligacionId);
        if(response.result !== "success"){
            const exc = {
                code: "SaveObligacionException"
            }
            handleErrors(exc);
        }
        obligacionSelected.current = "";
        getObligaciones();
    }

    const enableNuevoIngreso = () => {
        return descripcion.length > 0 &&
        costo > 0
    }

    const handleSaveNuevoIngreso = async() => {
        setIsSaving(true);
        setTextButton("");
        const ingresos = [];
        const periodo = Date.parse((tmpStartDate.current.getMonth() + 1)+"/"+1+"/"+tmpStartDate.current.getFullYear());
        const element = {
            periodo: periodo,
            descripcion: descripcion.trim().toLowerCase(),
            costo: parseFloat(costo),
            institucionId: userData.institucionId
        };
        ingresos.push(element);
        try{
            const response = await API.post("rocket", "/crearObligacionFinanciera",{
                body: ingresos
            });
            if(response.result === "success"){
                handleCloseAddIngresoModal();
                setIsLoading(true);
                getObligaciones();
            } else {
                console.log(response);
                const error = {
                    code: "AdicionarIngresoExcepcion"
                };
                handleCloseAddIngresoModal();
                handleErrors(error);
            }
        }catch(error){
            console.log(error);
            const err = {
                code: "ApiRequestExcepcion"
            };
            handleCloseAddIngresoModal();
            handleErrors(err);
        }
    }

    const handleCloseAddIngresoModal = () => {
        setDescripcion("");
        setCosto(0);
        setIsSaving(false);
        setTextButton("Guardar");
        setShowAddIngresoModal(false);
    }

    return (
        <div>
            <Modal 
                show={showAddIngresoModal}
                onHide={()=>handleCloseAddIngresoModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Nuevo ingreso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="ingreso-descripcion">Descripción</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder="Descripción de este ingreso"
                                    aria-label="descripcion"
                                    aria-describedby="ingreso-descripcion"
                                    value={descripcion}
                                    onChange={e=>setDescripcion(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="costo-obligacion">Costo</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder="Costo de este ingreso"
                                    type="number"
                                    aria-label="costo"
                                    aria-describedby="costo-ingreso"
                                    value={costo}
                                    onChange={e=>setCosto(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Row>
                        <Col className="text-right">
                            <LoaderButton
                                variant="outline-primary"
                                onClick={()=>handleSaveNuevoIngreso()}
                                isLoading={isSaving}
                                disabled={!enableNuevoIngreso()}
                            > {textButton}
                            </LoaderButton>
                        </Col>
                    </Row>
                </Modal.Footer>
            </Modal>
            <Modal 
                show={showAlertModal}
                onHide={()=>handleCloseAlertModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>
            <h4>Registrar ingresos</h4>
            <hr></hr>
            <Row className="align-items-end">
                <Col className="text-right">
                    <Button variant="outline-primary" onClick={()=>handlePreviousMonth()}><MdNavigateBefore /></Button>
                </Col>
                <Col className="mt-2 text-center" xs="auto">
                    <DatePicker
                        className="form-control text-center"
                        selected={startDate}
                        dateFormat="MM/yyyy"
                        onChange={date => {
                            setIsLoading(true); 
                            tmpStartDate.current = date;
                            getObligaciones();
                            setStartDate(date);
                        }}
                        showMonthYearPicker
                    />                    
                </Col>
                <Col className="text-left">
                    <Button variant="outline-primary" onClick={()=>handleNextMonth()}><MdNavigateNext /></Button>
                </Col>
            </Row>
            <hr></hr>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-1 pt-1">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :   
                <div>
                    <Row className="justify-content-end mb-1">
                        <Col className="pl-0 pr-0 text-right" xs={"auto"}>
                            <Button onClick={()=>setShowAddIngresoModal(true)} variant="outline-success"><MdAdd /></Button>
                        </Col>
                        <Col className="pl-0 pr-0 text-right" xs={"auto"}>
                            <Button onClick={()=>handleUpdateData()} className="ml-1 mr-1" variant="outline-primary"><MdRefresh /></Button>
                        </Col>
                        <Col className="pl-0 text-right" xs={"auto"}>
                            <Dropdown>
                                <Dropdown.Toggle disabled={!isObSelected} title="Eliminar obligacion" variant="outline-danger" id="dropdown-delete">
                                    <MdDelete />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item >¿Seguro?</Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item onClick={()=>handleDeleteObligacion()}>Si, elimina esta obligación</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BootstrapTable
                                keyField="obligacionId"
                                data={tableData.current}
                                columns={columns.current}
                                pagination={paginationFactory(25)}
                                noDataIndication={bootstrapTableMsg.current}
                                selectRow={selectRow}
                                filter={filterFactory()}
                                cellEdit={
                                    cellEditFactory({
                                    mode: 'dbclick',
                                    blurToSave: true,
                                    afterSaveCell: handleSaveValue
                                })}
                            />
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(RegistrarIngresos);