import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { MdDeleteForever } from "react-icons/md";
import { API } from 'aws-amplify';
import LoaderButton from "./loaderButton";

const ModalEliminar = ({
    setShow,
    show = false,
    elementData,
    buttonType = "danger",
    object,
    ...props
}) => {
    const handleClose = () => {
        setIsLoading(false);
        setShow(false);
    }
    //console.log(elementData);
    const [isLoading, setIsLoading] = useState(false);
    const nombre = elementData.nombre.charAt(0).toUpperCase() + elementData.nombre.slice(1);
    const apellido = object === 'usuarios' ? elementData.apellido.charAt(0).toUpperCase() + elementData.apellido.slice(1) : '';
    const title = object === 'usuarios' ? nombre + " " + apellido : 'curso ' + nombre;
    
    const eliminarObjeto = async() => {
        setIsLoading(true);
        try {
            const eD = {
                institucionId: elementData.institucionId,
            };
            var result = null;
            if(object === 'usuarios'){
                result = await API.del('rocket','/usuarios/'+elementData.usuarioId,{
                    body: eD
                });
            } else {
                result = await API.del('rocket','/cursos/'+elementData.cursoId,{
                    body: eD
                });
            }
            console.log(result);
            setIsLoading(false);
            setShow(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setShow(false);
        }
    };

    return (
        <Modal 
            show={show}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {object === 'usuarios' ? 
                    <p>¿Realmente quiere eliminar este usuario?.</p>
                :
                    <p>¿Realmente quiere eliminar este curso?.</p>
                }
            </Modal.Body>

            <Modal.Footer>
                <LoaderButton 
                    isLoading={isLoading}
                    variant={buttonType}
                    onClick={()=>eliminarObjeto()}
                >
                    <MdDeleteForever /> Si, Seguro
                </LoaderButton>
            </Modal.Footer>
            {props.children}
        </Modal>
    )
}

export default ModalEliminar;
