import React, { useState, useRef, useEffect, useCallback } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import API from "@aws-amplify/api";

const mapStateToProps = state => ({
    userData: state.userAuthenticated
});

const mapDispatchToProps = dispatch => ({});

const EstadoCuenta = ({userData}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isFirstRender, setIsFirstRender] = useState(true);
    const [startDate, setStartDate] = useState(new Date());
    
    const tmpStartDate = useRef(new Date());
    const tableData = useRef([]);
    const bootstrapTableMsg = useRef("No hay datos que mostrar");

    const history = useHistory();

    const columns = useRef([
        {
            dataField: "descripcion", 
            text: "Descripción",
            sort: true,
            style: {
                textTransform: "capitalize"
            },
        },
        {
            dataField: "costo", 
            text: "Costo",
            sort: true,
            headerStyle: () => {
                return { width: '15%', textAlign: "right" }
            },
            style: {
                textAlign: "right"
            },
        },
        {
            dataField: "saldo", 
            text: "Saldo",
            sort: true,
            headerStyle: () => {
                return { width: '15%', textAlign: "right" }
            },
            style: {
                textAlign: "right"
            },
        },
    ]);

    const getObligacionesEstudiante = useCallback(async() => {
        tableData.current = [];
        const periodoInit = Date.parse((tmpStartDate.current.getMonth() + 1)+"/"+1+"/"+tmpStartDate.current.getFullYear());
        const monthLastDay = new Date(tmpStartDate.current.getFullYear(),(tmpStartDate.current.getMonth()+1),0).getDate();
        const periodoEnd = Date.parse((tmpStartDate.current.getMonth() + 1)+"/"+monthLastDay+"/"+tmpStartDate.current.getFullYear());
        const qsp = {
            institucionId: userData.institucionId,
            usuarioId: userData.usuarioId,
            modo_cobranza: false,
            periodoInit: periodoInit,
            periodoEnd: periodoEnd
        };
        try{
            const response = await API.get("rocket","/getObligacionesEstudiante",{
                queryStringParameters: qsp
            });
            if(response.result === "success"){
                const obligaciones = response.description;
                for (let index = 0; index < obligaciones.length; index++) {
                    const element = obligaciones[index];
                    const data = {
                        obligacionId: element.obligacionId,
                        descripcion: element.descripcion,
                        costo: parseFloat(element.costo),
                        saldo: element.pago ? parseFloat(element.costo) - parseFloat(element.pago) : parseFloat(element.costo),
                    }
                    tableData.current.push(data);
                }
                setIsLoading(false);
            } else {
                console.log(response);
                setIsLoading(false);
            }
        } catch (error){
            console.log(error);
            setIsLoading(false);
        }
    },[userData]);

    useEffect(()=>{
        if(userData.rolName === "estudiante"){
            if(isFirstRender){
                getObligacionesEstudiante();
                setIsFirstRender(false);
            }
        } else {
            history.push("/")
        }
    },[history, userData, getObligacionesEstudiante, isFirstRender]);

    const handlePreviousMonth = () =>{
        setIsLoading(true);
        var previousYear = 0;
        var previousMonth = 1;
        if (startDate.getMonth() === 0){
            previousMonth = -11;
            previousYear = 1;
        }
        const newDate = Date.parse(((startDate.getMonth() + 1) - previousMonth)+"/"+startDate.getDate()+"/"+(startDate.getFullYear() - previousYear));
        tmpStartDate.current = new Date(newDate);
        setStartDate(new Date(newDate));
        getObligacionesEstudiante();
    };

    const handleNextMonth = () =>{
        setIsLoading(true);
        var nextYear = 0;
        var nextMonth = 1;
        if (startDate.getMonth() === 11){
            nextMonth = -11;
            nextYear = 1;
        }
        const newDate = Date.parse(((startDate.getMonth() + 1) + nextMonth)+"/"+startDate.getDate()+"/"+(startDate.getFullYear() + nextYear));
        tmpStartDate.current = new Date(newDate);
        setStartDate(new Date(newDate));
        getObligacionesEstudiante();
    };

    

    return (
        <div>
            <h4>Tu estado de cuenta</h4>
            <hr></hr>
            <Row className="align-items-end">
                <Col className="text-right">
                    <Button variant="outline-primary" onClick={()=>handlePreviousMonth()}><MdNavigateBefore /></Button>
                </Col>
                <Col className="mt-2 text-center" xs="auto">
                    <DatePicker
                        className="form-control text-center"
                        selected={startDate}
                        dateFormat="MM/yyyy"
                        onChange={date => {
                            setIsLoading(true); 
                            tmpStartDate.current = date;
                            getObligacionesEstudiante();
                            setStartDate(date);
                        }}
                        showMonthYearPicker
                    />                    
                </Col>
                <Col className="text-left">
                    <Button variant="outline-primary" onClick={()=>handleNextMonth()}><MdNavigateNext /></Button>
                </Col>
            </Row>
            <hr></hr>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-1 pt-1">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            : 
                <Row className="mb-3">
                    <Col>
                        <BootstrapTable
                            keyField="obligacionId"
                            data={tableData.current}
                            columns={columns.current}
                            pagination={paginationFactory(25)}
                            noDataIndication={bootstrapTableMsg.current}
                        />
                    </Col>
                </Row>
            }
        </div>
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(EstadoCuenta);