import React from "react";
import { Modal } from "react-bootstrap";

const InfoModal = ({
    showInfoModal,
    infoModalTitle,
    infoModalBody,
    setShowInfoModal,
    ...props
}) => {
    const handleClose = () => {
        setShowInfoModal(false);
    }
    return (
        <div>
            <Modal 
                show={showInfoModal}
                centered
                onHide={handleClose} 
                {...props}>
                <Modal.Header closeButton>
                    <Modal.Title>{infoModalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {infoModalBody}
                </Modal.Body>
            </Modal>
        </div>
    )
};

export default InfoModal;