import React, { useState } from 'react';
import { Row, Col, InputGroup, Form, Button } from 'react-bootstrap';

const FormMatriculaEditInput = (props) => {

    const [label, setLabel] = useState(props.elementToEdit.label);
    const mandatory = Boolean(props.elementToEdit.mandatory);
    const [required, setRequired] = useState(props.elementToEdit.required);

    const handleEnableSave = () =>  {
        return label.length > 3;
    };

    const handleSave = () => {
        const elementEdited = props.elementToEdit;
        elementEdited.label = label.trim();
        elementEdited.required = required;
        props.onComplete(elementEdited);
    }

    return (
        <div>
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Etiqueta</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control 
                            type="text" 
                            value={label}                    
                            onChange={e=>setLabel(e.target.value)}
                            required
                            isInvalid={!(label.length > 3)}
                        />
                        <Form.Control.Feedback type="invalid">
                            Ingresa un texto para la etiqueta.
                        </Form.Control.Feedback>
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Check 
                        type='checkbox'
                        label='¿Requerido?'
                        checked={Boolean(required)}
                        disabled={mandatory}
                        onChange={()=>setRequired(!Boolean(required))}
                    />
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col className='mt-3 text-right'>
                    <Button 
                        onClick={()=>handleSave()} 
                        disabled={!handleEnableSave()} 
                        variant='outline-primary'
                    >Guardar</Button>
                </Col>
            </Row>
        </div>
    )
};

export default FormMatriculaEditInput;