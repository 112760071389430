import React, { useState, useRef, useEffect } from "react";
import { Modal, Row, Col, Form, InputGroup, Button, Spinner, DropdownButton, Dropdown, ButtonGroup } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { API } from "aws-amplify";
import { connect } from "react-redux";
import { FaRegAddressBook } from "react-icons/fa";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { BiExport } from "react-icons/bi";
import { MdDelete } from "react-icons/md";
import { FiRefreshCw } from "react-icons/fi";
import { integraInscripcionesCroom } from "../libs/integra";

const mapStateToProps = (state, props) => ({
    rolesData: state.rolesData,
    setShow: props.setShow,
    show: props.show,
    dataData: props.dataData,
    inscripcionTypeFlag: props.inscripcionTypeFlag,
    manageInscripcionesFlag: props.manageInscripcionesFlag
});

const mapDispatchToProps = dispatch => ({});

/** 
 * Modal para mostrar las inscripciones de un usuario o en un curso
 * @param {function} setShow - oculta el modal
 * @param {boolean} show - el estado del modal, true-> se muestra el modal
 * @param {Array} dataData - array con la data del usuario o el curso al que 
 *           se le van a mostrar las inscripciones
 * @param {boolean} inscripcionTypeFlag - indica que tipo de data se envia, 
 *           true -> para cargar inscripciones para un usuario / 
 *           false -> para cargar inscripciones de un curso
 * @param {boolean} manageInscripcionesFlag - Indica si el modal muestra las funcionalidades 
 *           de administracion de inscripciones, tales como, deshablitar, cancelar o adicionar 
 *           nuevas inscripciones, si el flag es false el modal solo muestra 
 *           a manera informativa las inscripciones de la data dada
*/
const ModalInscripciones = ({
    rolesData,
    setShow,
    show,
    dataData,
    inscripcionTypeFlag,
    manageInscripcionesFlag = false,
}) => {
    const [filtroBusqueda, setFiltroBusqueda] = useState("1");
    const [searchCriteria, setSearchCriteria] = useState("");
    const bootstrapTableAddParticipantMsg = useRef("");
    const bootstrapTableCurrentParticipantMsg = useRef("");
    const [newParticipantsData, setNewParticipantsData] = useState([]);
    const [loadingAddParticipants, setLoadingAddParticipants] = useState(false);
    const [loadingCurrentparticipants, setLoadingCurrentparticipants] = useState(true);
    const rolDocenteId = useRef("");
    const rolEstudianteId = useRef("");
    const actualEnrollsData = useRef([]);
    const firstRender = useRef(true);
    const [errorMsg, setErrorMsg] = useState("");

    const columns = useRef([
        {
            dataField: "internalId", 
            text: "ID",
            headerStyle: () => {
                return { width: '20%' }
            }
        },
        {
            dataField: "nombre", 
            text: "Nombre",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "addAction",
            text: "Acciones",
            headerStyle: () => {
                return { 
                    width: '25%',
                    textAlign: "right"
                 }
            },
            style: {
                textAlign: "right"
            }
        }
    ]);

    const columnsUsuarios = useRef([
        {
            dataField: "identificacion", 
            text: "Identificación",
            headerStyle: () => {
                return { width: '20%' }
            }
        },
        {
            dataField: "nombreCompleto", 
            text: "Nombre",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "addAction",
            text: "Acciones",
            headerStyle: () => {
                return { 
                    width: '25%',
                    textAlign: "right"
                 }
            },
            style: {
                textAlign: "right"
            }
        }
    ]);
    
    useEffect(()=>{
        rolesData.map(rol=>{
            if (rol.rol === "docente"){
                rolDocenteId.current = rol.rolId;
            } else if(rol.rol === "estudiante"){
                rolEstudianteId.current = rol.rolId;
            }
            return true;
        })
    },[rolesData,rolDocenteId,rolEstudianteId])

    const handleEnrollStatus = (eData) => {
        const data = {
            cursoId: eData.cursoId,
            estado: !eData.estado
        }
        API.put("rocket", "/inscripciones/"+eData.inscripcionId, {
            body: data
        }).then(value=>{
            switch (value.result) {
                case "success":
                    console.log(value.result)
                    break;
                case "fail":
                    console.log(value)
                    break;
                default:
                    console.log(value);
                    break;
            }
            handleRefreshEnrolls();
        }).catch(error=>{
            console.log(error)
            handleRefreshEnrolls();
        })
    }
    
    const handleEnrollDelete = (eData) => {
        //Hay interación?, tambien deberia eliminarse la integracion, no se esta haciendo
        const data = {
            cursoId: eData.cursoId
        }
        API.del("rocket", "/inscripciones/"+eData.inscripcionId, {
            body: data
        }).then(value=>{
            switch (value.result) {
                case "success":
                    console.log(value.result)
                    break;
                case "fail":
                    console.log(value)
                    break;
                default:
                    console.log(value);
                    break;
            }
            handleRefreshEnrolls();
        }).catch(error=>{
            console.log(error)
            handleRefreshEnrolls();
        })
    }

    const handleClose = () => {
        actualEnrollsData.current = [];
        firstRender.current = true;
        setSearchCriteria("");
        setFiltroBusqueda("1");
        bootstrapTableAddParticipantMsg.current = "";
        bootstrapTableCurrentParticipantMsg.current = "";
        setErrorMsg("");
        setNewParticipantsData([]);
        setLoadingAddParticipants(false);
        setShow(false)
        setLoadingCurrentparticipants(true);
    }

    const handleEnabled = () => {
        return searchCriteria.length > 3;
    }

    const handleEnrollUser = (userData) => {
        setLoadingAddParticipants(true);
        const enrollData = {
            cursoId: dataData.cursoId,
            usuarioId: userData.usuarioId,
            rol: userData.rol,
            institucionId: dataData.institucionId,
            estado: true
        }
        API.post("rocket", "/crearInscripcion", {
            body: enrollData
        }).then((value)=>{
            switch (value.result) {
                case "success":
                    setSearchCriteria("");
                    setNewParticipantsData([]);
                    setLoadingAddParticipants(false);
                    break;
                case "fail":
                    console.log(value);
                    setSearchCriteria("");
                    setNewParticipantsData([]);
                    setLoadingAddParticipants(false);
                    setErrorMsg(value.description);
                    break;
                default:
                    break;
            }
        }).catch(error=>{
            console.log(error);
            setSearchCriteria("");
            setNewParticipantsData([]);
            setLoadingAddParticipants(false);
            setErrorMsg(error);
        })
    }

    const handleSearch = () => {
        setLoadingAddParticipants(true);
        setNewParticipantsData([]);
        API.get("rocket","/getUsers",{
            "queryStringParameters": {
                institucionId: dataData.institucionId,
                filtroBusqueda: filtroBusqueda,
                searchCriteria: searchCriteria.trim().toLowerCase()
            }
        }).then(value=>{
            //console.log(value);
            switch (value.result) {
                case "success":
                    const tdt = []
                    const tableDataTemp = [];
                    for (let index = 0; index < value.description.length; index++) {
                        const element = value.description[index];
                        if((element.rol === rolDocenteId.current || element.rol === rolEstudianteId.current) && (element.estado)){
                            tdt.push(element);
                        }
                    }
                    // console.log(actualEnrollsData.current);
                    // console.log(tdt);
                    for (let index = 0; index < tdt.length; index++) {
                        const element = tdt[index];
                        // console.log(element.usuarioId);
                        if(actualEnrollsData.current.length > 0){
                            var coincidencia = false;
                            for (let index = 0; index < actualEnrollsData.current.length; index++) {
                                const elementB = actualEnrollsData.current[index];
                                if(element.usuarioId === elementB.usuarioId){
                                    //console.log("coincidencia");
                                    coincidencia = true
                                    //console.log(elementB.usuarioId);
                                }
                            }
                            if(!coincidencia){
                                element.nombreCompleto = [element.nombre + " " + element.apellido, <span className="to-lower-case form-text" key="ft787">{element.email}</span>]
                                element.addAction = <Button variant="success" title={"Inscribir usuario al curso"} onClick={()=>handleEnrollUser(element)}>{<FaRegAddressBook />}</Button>
                                tableDataTemp.push(element);
                            }
                        } else {
                            element.nombreCompleto = [element.nombre + " " + element.apellido, <span className="to-lower-case form-text" key="ft787">{element.email}</span>]
                            element.addAction = <Button variant="success" title={"Inscribir usuario al curso"} onClick={()=>handleEnrollUser(element)}>{<FaRegAddressBook />}</Button>
                            tableDataTemp.push(element);
                        }
                    }
                    //console.log(tableDataTemp);
                    setNewParticipantsData(tableDataTemp);
                    break;
                case "fail":
                    console.log(value);
                    if (value.description === "no se encontraron usuarios"){
                        bootstrapTableAddParticipantMsg.current = "No se encontro el usuario";
                    }
                    break;
                default:
                    break;
            }
            setLoadingAddParticipants(false);
        }).catch(error=>{
            console.log(error);
            setLoadingAddParticipants(false);
        })
    }

    const renderAddParticipants = () => {
        
        bootstrapTableAddParticipantMsg.current = "Sin usuarios que mostrar";
        if(loadingAddParticipants){
            return (
                <Row>
                    <Col className="text-center">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
                )
        } else {
            return (
                <div>
                    <Row >
                        <Col>
                            <h4>Adicionar participantes</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={"auto"}>
                            <Form.Label className="mr-sm-2" htmlFor="opciones-busqueda" srOnly>
                                Opciones de busqueda
                            </Form.Label>
                            <Form.Control
                                as="select"
                                disabled={loadingCurrentparticipants}
                                className="mr-sm-2"
                                id="opciones-busqueda"
                                value={filtroBusqueda}
                                onChange={e=>setFiltroBusqueda(e.target.value)}
                                custom
                            >
                                <option value="1">Nombre</option>
                                <option value="2">Apellido</option>
                                <option value="3">email</option>
                                <option value="5">Identificación</option>
                            </Form.Control>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    autoFocus
                                    disabled={loadingCurrentparticipants}
                                    placeholder="Criterios de busqueda"
                                    aria-label="Criterios de busqueda"
                                    aria-describedby="basic-addon2"
                                    value={searchCriteria}
                                    onChange={e=>setSearchCriteria(e.target.value)}
                                />
                                <InputGroup.Append>
                                    <Button disabled={!handleEnabled()} onClick={()=>handleSearch()} variant="outline-secondary">Buscar</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BootstrapTable
                                keyField="usuarioId"
                                data={newParticipantsData}
                                columns={columnsUsuarios.current}
                                pagination={paginationFactory({sizePerPage: 5})}
                                noDataIndication={bootstrapTableAddParticipantMsg.current}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    const handleIntegrationRequest = (data, eData) => {
        if (data.cursoId){
            integraInscripcionesCroom(data.integracionId, dataData.integracionId, eData);
        } else {
            integraInscripcionesCroom(dataData.integracionId, data.integracionId, eData);
        }
    }

    const handleRefreshEnrolls = () =>{
        setLoadingCurrentparticipants(true);
        actualEnrollsData.current = [];
        var qsp = {};
        if(inscripcionTypeFlag){
            qsp = {
                usuarioId: dataData.usuarioId,
                enrollTypeFlag: true
            }
        } else {
            qsp = {
                cursoId: dataData.cursoId,
                enrollTypeFlag: false
            }
        }
        API.get("rocket", "/getInscripciones", {
            "queryStringParameters": qsp
        }).then(value=>{
            switch (value.result) {
                case "success":
                    for (let index = 0; index < value.description.length; index++) {
                        const element = value.description[index];
                        if(inscripcionTypeFlag){
                            API.get("rocket","/cursos/"+element.cursoId,{
                                "queryStringParameters":{
                                    institucionId: dataData.institucionId
                                }
                            }).then(cData=>{
                                switch (cData.result) {
                                    case "success":
                                        element.internalId = cData.description.internalId;
                                        element.nombre = cData.description.nombre;
                                        element.addAction = <Row className="justify-content-end">
                                            <Col md={'auto'} className="pl-0 pr-0">
                                                <Button 
                                                    className="mr-1" 
                                                    key="btnHIR" 
                                                    variant="success" 
                                                    title="Integrar participante" 
                                                    onClick={()=>handleIntegrationRequest(cData.description, element)}
                                                ><BiExport /></Button>
                                            </Col>
                                            <Col md={'auto'} className="pl-0 pr-0">
                                                <Button 
                                                    className="mr-1" 
                                                    key="btnHES" 
                                                    variant="warning" 
                                                    title={element.estado ? "Deshabilitar inscripción" : "Habilitar inscripción"} 
                                                    onClick={()=>handleEnrollStatus(element)}
                                                >{element.estado ? <AiFillEyeInvisible /> : <AiFillEye />}</Button>
                                            </Col>
                                            <Col md={'auto'} className="pl-0">
                                                <DropdownButton as={ButtonGroup} key="btnHED" variant="danger" title={<MdDelete />}>
                                                    <Dropdown.Item >¿Seguro?</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item onClick={()=>handleEnrollDelete(element)}>Si, cancela esta inscripción</Dropdown.Item>
                                                </DropdownButton>
                                            </Col>
                                        </Row>
                                        actualEnrollsData.current.push(element);
                                        if(actualEnrollsData.current.length === value.description.length){
                                            setLoadingCurrentparticipants(false);
                                        }
                                        break;
                                    case "fail":
                                        console.log(cData);
                                        if(index === ((value.description.length) - 1)){
                                            setLoadingCurrentparticipants(false);
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            }).catch(error=>{
                                console.log(error);
                                setErrorMsg(error);
                                setLoadingCurrentparticipants(false);
                            })
                        } else {
                            API.get("rocket", "/usuarios/"+element.usuarioId,{
                                "queryStringParameters":{
                                    institucionId: dataData.institucionId
                                }
                            })
                            .then(uData=>{
                                switch (uData.result) {
                                    case "success":
                                        element.identificacion = uData.description.identificacion;
                                        element.nombreCompleto = [uData.description.nombre + " " + uData.description.apellido, <Form.Text className="to-lower-case" key="ft787">{uData.description.email}</Form.Text>];
                                        element.addAction = <Row className="justify-content-end">
                                            <Col md={'auto'} className="pl-0 pr-0">
                                                <Button 
                                                    className="mr-1" 
                                                    key="btnHIR" 
                                                    variant="success" 
                                                    title="Integrar participante" 
                                                    onClick={()=>handleIntegrationRequest(uData.description, element)}
                                                ><BiExport /></Button>
                                            </Col>
                                            <Col md={'auto'} className="pl-0 pr-0">
                                                <Button 
                                                    className="mr-1" 
                                                    key="btnHES" 
                                                    variant="warning" 
                                                    title={element.estado ? "Deshabilitar inscripción" : "Habilitar inscripción"} 
                                                    onClick={()=>handleEnrollStatus(element)}
                                                >{element.estado ? <AiFillEyeInvisible /> : <AiFillEye />}</Button>
                                            </Col>
                                            <Col md={'auto'} className="pl-0">
                                                <DropdownButton as={ButtonGroup} key="btnHED" variant="danger" title={<MdDelete />}>
                                                    <Dropdown.Item >¿Seguro?</Dropdown.Item>
                                                    <Dropdown.Divider />
                                                    <Dropdown.Item onClick={()=>handleEnrollDelete(element)}>Si, cancela esta inscripción</Dropdown.Item>
                                                </DropdownButton>
                                            </Col>
                                        </Row>
                                        actualEnrollsData.current.push(element);
                                        if(actualEnrollsData.current.length === value.description.length){
                                            setLoadingCurrentparticipants(false);
                                        }
                                        break;
                                    case "fail":
                                        console.log(uData);
                                        if(index === ((value.description.length) - 1)){
                                            setLoadingCurrentparticipants(false);
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            }).catch(error=>{
                                console.log(error);
                                setErrorMsg(error);
                                setLoadingCurrentparticipants(false);
                            })
                        }
                    }
                    break;
                case "fail":
                    console.log(value);
                    setLoadingCurrentparticipants(false);
                    break;
                default:
                    break;
            }
        }).catch(error=>{
            console.log(error);
            setErrorMsg(error);
            setLoadingCurrentparticipants(false);
        })
    }

    if (show && firstRender.current){
        handleRefreshEnrolls(); 
        firstRender.current = false
     }
    
    const renderCurrentParticipants = () => {
        bootstrapTableCurrentParticipantMsg.current="Sin inscripciones para este curso";
        if(loadingCurrentparticipants){
            return (
                <Row>
                    <Col className="text-center">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
                )
        } else {
            return (
                <div>
                    <Row>
                        <Col>
                            {inscripcionTypeFlag ? <h4>Cursos actuales</h4> : <h4>Participantes actuales</h4>}
                        </Col>
                        <Col className="text-right">
                            <Button onClick={()=>handleRefreshEnrolls()}><FiRefreshCw /></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <BootstrapTable
                                keyField={"inscripcionId"}
                                data={actualEnrollsData.current}
                                columns={inscripcionTypeFlag ? columns.current : columnsUsuarios.current}
                                pagination={paginationFactory({sizePerPage: 5})}
                                noDataIndication={bootstrapTableCurrentParticipantMsg.current}
                            />
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    return (
        <Modal 
            show={show}
            onHide={handleClose}
            size={"lg"}
        >
            <Modal.Header closeButton>
                <Modal.Title className="capitalize">
                    {(inscripcionTypeFlag 
                        ? dataData.nombre + " " + dataData.apellido 
                        : dataData.nombre)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {manageInscripcionesFlag && !inscripcionTypeFlag ? renderAddParticipants() : ""}
                {renderCurrentParticipants()}
            </Modal.Body>
            {errorMsg.length > 0 
            ? <Modal.Footer>
                {errorMsg}
            </Modal.Footer>   
            : ""
            }
        </Modal>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalInscripciones);