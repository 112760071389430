import React, { useState, useRef } from "react";
import { Row, Col, Image, Form, Modal, Alert, InputGroup, FormControl } from "react-bootstrap";
import LoaderButton from "../components/loaderButton";
import { Auth } from "aws-amplify";
import logo from "../img/logo.png"
import { useHistory } from "react-router-dom";
import InfoModal from "../components/infoModal";
import RecuperarClave from "../components/recuperarClave.js"
import { onError } from "../libs/errorLib";

const Login = () => {
    const [mailUsuario, setEmailUsuario] = useState("");
    const [claveUsuario, setClaveUsuario] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingResend, setIsLoadingResend] = useState(false);
    const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
    const [enabledResendCode, setEnabledResendCode] = useState(true);
    const [textButton, setTextButton] = useState("Iniciar sesión");
    const [textButtonResend, setTextButtonResend] = useState("Re-enviar codigo");
    const [textButtonConfirm, setTextButtonConfirm] = useState("Confirmar")
    const [showModal, setShowModal] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState("");
    const [alertShow, setAlertShow] = useState(false);
    const [alerType, setAlerType] = useState("");
    const [alertMSG, setAlertMsg] = useState("");
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [infoModalTitle, setInfoModalTitle] = useState("");
    const [infoModalBody, setInfoModalBody] = useState("");
    const [showModalNewPass, setShowModalNewPass] = useState(false);
    const [newPass, setNewPass] = useState("");
    const [newPassConfirm, setNewPassConfirm] = useState("");
    const [isLoadingNewPass, setIsLoadingNewPass] = useState(false);
    const [showRecoverPassModal, setShowRecoverPassModal] = useState(false);
    const history = useHistory();
    const userAuth = useRef("");

    const handleLogin = async() =>{
        setTextButton(" Ingresando");
        setIsLoading(true);
        try{
            userAuth.current = await Auth.signIn(mailUsuario, claveUsuario);
            if(userAuth.current.challengeName === 'NEW_PASSWORD_REQUIRED') {
                setAlerType("info");
                setAlertMsg([<Alert.Heading key="002">Ten en cuenta que:</Alert.Heading>,
                    <p key="001">
                        La contraseña debe tener mínimo 8 caracteres, debe incluir numeros, 
                        letras mayúsculas, minúsculas y caracteres especiales (*,#)
                    </p>]);
                setAlertShow(true);
                setShowModalNewPass(true);
            } else { 
                handleRedirect(userAuth.current);
            }
        } catch (error){
            switch (error.code) {
                case "UserNotConfirmedException":
                    setShowModal(true);
                    break;
                default:
                    handleSingInError(error,0);
                    setIsLoading(false);
                    setTextButton("Iniciar Sesión");
                    break;
            }
        }
    }

    const handleEnabled = () => {
        return mailUsuario.includes("@") &&
        claveUsuario.length > 7;
    }

    const handleCloseModal = () =>{
        setShowModal(false);
        setTextButton("Iniciar Sesión");
        setIsLoading(false);
    }

    const handleResendCode = () => {
        setAlertShow(false);
        setIsLoadingResend(true);
        setIsLoadingConfirm(true);
        setTextButtonResend(" Re-enviando");
        Auth.resendSignUp(mailUsuario)
        .then((value)=>{
            console.log(value);
            setIsLoadingResend(false);
            setIsLoadingConfirm(false);
            setTextButtonResend("Re-enviar codigo");
            setAlertMsg("Se re-envio el codigo, revisa tu correo electrónico");
            setAlerType("success");
            setAlertShow(true);
        }).catch(error=>{
            onError(error);
            setIsLoadingResend(false);
            setIsLoadingConfirm(false);
            setTextButtonResend("Re-enviar codigo");
            setAlertMsg("Se presento un error re-enviando el codigo, intentalo de nuevo si el problema persiste, comunicate con nosotros");
            setAlerType("danger");
            setAlertShow(true);
        })
    }

    const handleRedirect = (userAuth) => {
        history.push("/");
    };

    const handleSingInError = (error, type) => {
        var msgError = "";
        var titleError = "Error";
        onError(error);
        switch (error.code) {
            case "UserNotFoundException":
                msgError = "Al parecer este usuario no existe...";
                break;
            case "NetworkError":
                msgError = "No es posible iniciar sesión, ¿tienes conexión a internet?";
                break;
            case "NotAuthorizedException":
                msgError = "Clave o usuario incorrectos";
                break;
            case "MetodoPagoException":
                msgError = "Tu cuenta no esta habilitada para ingresar a Rocket, por favor ponte en contacto con nosotros";
                break;
            case "NoExisteUsuarioException":
                msgError = "No se encuentra la información de tu cuenta, no es posible iniciar sesión, por favor ponte en contacto con nosotros";
                break;
            case "DisabledUserException":
                msgError = "Tu usuario no se encuentra habilitado, comunicate con nosotros";
                break;
            case "InvalidPasswordException":
                msgError = "La clave que ingresaste no cumple la politica de seguridad. Recuerda: La contraseña debe tener mínimo 8 caracteres, debe incluir numeros, letras mayúsculas, minúsculas y caracteres especiales (*,#)";
                break;
            default:
                console.log(error);
                msgError = "Error en la aplicación, intentalo de nuevo, si el problema persiste comunicate con nosotros";
                break;
        }
        if (type === 1){
            setShowModal(false);
        }
        setInfoModalTitle(titleError);
        setInfoModalBody(msgError);
        setShowInfoModal(true);
        setIsLoading(false);
        setTextButton("Iniciar Sesión");
    }

    const handleConfirmSignUp = () => {
        setAlertShow(false);
        setEnabledResendCode(false);
        setIsLoadingConfirm(true);
        setTextButtonConfirm(" Confirmando...");
        Auth.confirmSignUp(mailUsuario, confirmationCode)
        .then((value)=>{
            console.log(value);
            Auth.signIn(mailUsuario,claveUsuario)
            .then((value2)=>{
                handleRedirect(value2);
            }).catch(error=>{
                handleSingInError(error,1);
                setIsLoadingConfirm(false);
                setTextButtonConfirm("Confirmar")
            })
        }).catch(error=>{
            onError(error);
            switch (error.code) {
                case "CodeMismatchException":
                    setAlertMsg("El codigo ingresado no es el correcto, intentalo de nuevo");
                    setAlerType("warning");
                    setAlertShow(true);
                    setIsLoadingConfirm(false);
                    setTextButtonConfirm("Confirmar")
                    break;
            
                default:
                    setAlertMsg("Se presento un error confirmando tu correo electrónico, intentalo de nuevo si el problema persiste, comunicate con nosotros");
                    setAlerType("danger");
                    setAlertShow(true);
                    break;
            }
        })
    }

    const handleEnabledConfirmSignUp = () => {
        return confirmationCode.length > 4;
    }

    const handleCloseModalNewPass = () => {
        setShowModalNewPass(false);
        setNewPass("");
        setNewPassConfirm("");
        setIsLoadingNewPass(false);
        setAlertMsg("");
        setAlerType("");
        setTextButton("Iniciar Sesión");
        setIsLoading(false);
        setAlertShow(false);
    }

    const handleEnabledConfirmNewPass = () => {
        return newPass.length > 7 && 
        newPassConfirm === newPass;
    }

    const handleConfirmNewPass = async() => {
        setIsLoadingNewPass(true);
        setShowModalNewPass(false);
        setNewPass("");
        setNewPassConfirm("");
        setIsLoadingNewPass(false);
        setAlertMsg("");
        setAlerType("");
        try {
            userAuth.current = await Auth.completeNewPassword(
                userAuth.current,
                newPass
            );
            handleRedirect(userAuth.current);
        } catch(error){
            switch (error.code) {
                case "NotAuthorizedException":
                    handleCloseModalNewPass();
                    setInfoModalTitle("Error");
                    setInfoModalBody("Tu sesión ha expirado, no fue posible realizar el cambio de contraseña. Por favor ingresa de nuevo");
                    setShowInfoModal(true);
                    setIsLoading(false);
                    setTextButton("Iniciar Sesión");
                    break;
            
                default:
                    handleSingInError(error, 0);
                    Auth.signOut();
                    break;
            }
        }
    }

    return (
        <div>
            {showRecoverPassModal && 
                <Modal 
                    show={showRecoverPassModal}
                    onHide={()=>setShowRecoverPassModal(false)}
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title >Recuperación de clave </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <RecuperarClave />
                    </Modal.Body>
                </Modal>
            }
            <Modal 
                show={showModalNewPass}
                onHide={handleCloseModalNewPass}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title >Asignar clave</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Label>Vamos a asignar una nueva clave para tu cuenta</Form.Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="newPass">Clave</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder="Nueva clave"
                                    value={newPass}
                                    type="password"
                                    onChange={e=>setNewPass(e.target.value)}
                                    aria-describedby="newPass"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="newPass">Confirmación</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder="Ingresa tu nueva clave de nuevo"
                                    value={newPassConfirm}
                                    type="password"
                                    onChange={e=>setNewPassConfirm(e.target.value)}
                                    aria-describedby="newPassConfirm"
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alert show={alertShow} variant={alerType}>
                                {alertMSG}
                            </Alert>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <LoaderButton
                        onClick={()=>handleConfirmNewPass()}
                        isLoading={isLoadingNewPass}
                        disabled={!handleEnabledConfirmNewPass()}
                        variant="primary"
                    > Ingresar</LoaderButton>                
                </Modal.Footer>
            </Modal>
            <InfoModal
                showInfoModal={showInfoModal}
                infoModalTitle={infoModalTitle}
                infoModalBody={infoModalBody} 
                setShowInfoModal={setShowInfoModal}
            />
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                centered>
                <Modal.Header closeButton>
                    <Modal.Title >Confirmación requerida </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group controlId="confirm-user-email">
                                <Form.Label>Debes confirmar tu correo electrónico para continuar. Ingresa el codigo de verificación enviado a tu correo {mailUsuario}</Form.Label>
                                <Form.Control
                                    autoFocus
                                    type="tel"
                                    onChange={e=>setConfirmationCode(e.target.value)}
                                    value={confirmationCode}
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alert show={alertShow} variant={alerType}>
                                {alertMSG}
                            </Alert>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <LoaderButton
                        onClick={()=>handleResendCode()}
                        isLoading={isLoadingResend}
                        disabled={!enabledResendCode}
                        variant="primary"
                    >{textButtonResend}</LoaderButton>
                    <LoaderButton
                        onClick={()=>handleConfirmSignUp()}
                        isLoading={isLoadingConfirm}
                        disabled={!handleEnabledConfirmSignUp()}
                        variant="success"
                    >{textButtonConfirm}</LoaderButton>                
                </Modal.Footer>
            </Modal>
            <Row className="mt-5 pt-5 align-items-center justify-content-center">
                <Col className="logo-container" md={4}> 
                    <Image src={logo} className="logo-login" />
                </Col>
                <Col md={4}>
                    <Form.Group controlId="login-form-usuario">
                        <Form.Label>Usuario</Form.Label>
                        <Form.Control 
                            autoFocus
                            type="email" 
                            placeholder="Ingresa tu usuario"
                            value={mailUsuario}
                            onChange={e => setEmailUsuario(e.target.value)}
                        ></Form.Control>
                        <Form.Text className="text-muted">
                            Tu usuario es tu correo electrónico
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="login-form-pass">
                        <Form.Label>Clave</Form.Label>
                        <Form.Control 
                            type="password" 
                            placeholder="Ingresa tu clave"
                            onChange={e => setClaveUsuario(e.target.value)}
                            className='mb-2'
                        ></Form.Control>
                        <button onClick={()=>setShowRecoverPassModal(true)} className="styless-button">
                            <Form.Text className="text-muted">
                                ¿Olvidaste tu clave?
                            </Form.Text>
                        </button>
                    </Form.Group>

                </Col>
            </Row>
            <Row className="justify-content-center mt-3">
                <Col md={8}>
                    <hr></hr>
                </Col>
            </Row>
            <Row>
                <Col className="text-center">
                    <LoaderButton
                        autoFocus
                        onClick={()=>handleLogin()}
                        isLoading={isLoading}
                        disabled={!handleEnabled()}
                        variant="primary"
                    >{textButton}</LoaderButton>                
                </Col>
            </Row>
            <Row className="login-footer">
                <Col className="text-center align-self-center">
                    <Form.Text className="text-muted footer-login-text">
                        © Grupo Valor International Solutions - Todos los derechos reservados.
                    </Form.Text>
                </Col>
            </Row>
        </div>
    )
};

export default Login;