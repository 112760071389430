import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Spinner, Alert, Form, Modal } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { connect } from 'react-redux';
import { MdErrorOutline } from 'react-icons/md';

const mapStateToProps = state => ({
    userData: state.userAuthenticated,
});

const mapDispatchToProps = dispatch => ({});

const Configuraciones = ({userData}) => {

    const isFirstRender = useRef(true);
    const institutionalConfigs = useRef([]);
    const alertModalTitle = useRef(<MdErrorOutline />);
    const msgModal = useRef("");

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [userCanSetOwnPassword, setUserCanSetOwnPassword] = useState(true);
    const [userCanChangePassword, setUserCanChangePassword] = useState(true);
    const [showAlertModal, setShowAlertModal] = useState(false);

    useEffect(()=>{

        const getConfig = async() => {
            try {
                const configuraciones = await API.get('rocket', '/config/'+userData.institucionId);
                if(configuraciones.result === 'fail'){
                    console.log(configuraciones);
                    const error = 'GetConfigException';
                    throw error;
                }
                institutionalConfigs.current = configuraciones.description;
                if(institutionalConfigs.current.userCanSetOwnPassword !== undefined){
                    setUserCanSetOwnPassword(institutionalConfigs.current.userCanSetOwnPassword);
                }
                if(institutionalConfigs.current.userCanChangePassword !== undefined){
                    setUserCanChangePassword(institutionalConfigs.current.userCanChangePassword);
                }
                setIsLoading(false);
            } catch (error) {
                console.log(error);
                setError(true);
                setIsLoading(false);
            }
        }

        if(isFirstRender.current){
            isFirstRender.current = false;
            getConfig();
        }
    },[userData]);

    const handleError = (exception) => {
        msgModal.current = '';
        switch (exception) {
            case 'userCanSetOwnPassword':
                msgModal.current = "Se presento un error guardando la configuración, intentalo de nuevo, si el problema persiste, comunicate con nosotros";
                break;
        
            default:
                msgModal.current = "Se presento un error guardando la configuración.";
                break;
        }
        setShowAlertModal(true);
    };

    const updateConfig = async(parameter) => {
        var param = null;
        var myParam = null;
        switch (parameter) {
            case 0:
                const myVal = !userCanSetOwnPassword
                setUserCanSetOwnPassword(!userCanSetOwnPassword);
                myParam = {
                    propiedad: 'userCanSetOwnPassword',
                    value: myVal
                };
                param = myParam;
                break;
            case 1:
                const myValu = !userCanChangePassword
                setUserCanChangePassword(!userCanChangePassword);
                myParam = {
                    propiedad: 'userCanChangePassword',
                    value: myValu
                };
                param = myParam;
                break;
            default:
                break;
        }
        if(param){
            try {
                const result = await API.put("rocket","/config/"+userData.institucionId,{
                    body: myParam
                });
                if(result.result === 'fail'){
                    console.log(result);
                    const err = 'UnableSaveConfigException';
                    throw (err);
                }
                console.log('actualizado correctamente');
            } catch (error) {
                console.log(error);
                handleError(error);
            }
        }
    };

    return (
        <div>
            <Modal 
                show={showAlertModal}
                onHide={()=>setShowAlertModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>
            {isLoading ?
                <Row>
                    <Col className="text-center mt-1 pt-1">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                error ? 
                    <Row className='mt-3'>
                        <Col>
                            <Alert variant='danger'>
                                Ocurrio un error cargando las configuración, intentalo de nuevo, si el problema persiste, comunicate con nosotros.
                            </Alert>
                        </Col>
                    </Row>
                    
                :
                    <div>
                        <Row className="mt-3">
                            <Col>
                                <Form.Group controlId="userCanSetOwnPassword">
                                    <Form.Check 
                                        type="checkbox" 
                                        checked={userCanSetOwnPassword}
                                        onChange={() => updateConfig(0)}                                        
                                        label="Permitir a los nuevos usuarios establecer la clave de su cuenta." />
                                        <Form.Text className="text-muted">
                                            Si selecciona esta opción, Rocket enviará un correo de bienvenida a los nuevos usuarios, en él se incluirá una contraseña de acceso temporal. Cuando el nuevo usuario inicie sesión se le solicitará asignar su propia clave.
                                        </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group controlId="userCanChangePassword">
                                    <Form.Check 
                                        type="checkbox" 
                                        checked={userCanChangePassword}
                                        onChange={() => updateConfig(1)}
                                        label="Permitir a los usuarios cambiar la clave de su cuenta desde la interfaz de Rocket." />
                                        <Form.Text className="text-muted">
                                            Si selecciona esta opción, los usuarios podrán cambiar su clave de acceso a Rocket desde su página de perfil.
                                        </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
            }
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(Configuraciones);