import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Row, Col, Spinner, FormControl, InputGroup, Button, Form, Alert, Modal } from 'react-bootstrap';
import { Auth, API } from 'aws-amplify';
import { connect } from 'react-redux';
import { onError } from "../libs/errorLib";
import { useHistory } from 'react-router-dom';
import DOMPurify from 'dompurify';
import config from '../config';

const mapStateToProps = (state, props) => ({
    userData: state.userAuthenticated,
    configMatriculas: state.configMatriculas,
    props: props
});

const mapDispatchToProps = dispatch => ({
    setConfigMatriculas(configMatriculas){
        dispatch({
            type: "SET_MATRICULAS_CONFIG",
            configMatriculas
        })
    }
});

const FormMatriculaGenerate = ({userData, props, configMatriculas, setConfigMatriculas}) => {

    const [fields, setFields] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
    const [candidateEmail, setCandidateEmail] = useState('');
    const [showInvalidFieldArea, setShowInvalidFieldArea] = useState(false);
    const [invalidField, setInvalidField] = useState("");
    const [okSendMatricula, setOkSendMatricula] = useState(false);
    const [showErrorArea, setShowErrorArea] = useState(false);
    const [showRedirectArea, setShowRedirectArea] = useState(false);
    const [showCondicionesModal, setShowCondicionesModal] = useState(false);
    const [isSavingMatricula, setIsSavingMatricula] = useState(false);

    const history = useHistory();

    const isFirstRender = useRef(true);
    const isCurrentUser = useRef(false);
    const mandatoryHeadersFields = useRef([]);
    const otherFields = useRef([]);
    const msgError = useRef('');
    const urlCondiciones = useRef(null);
    const eventFormulario = useRef(null);

    const handleError = (msg) => {
        setShowErrorArea(false);
        msgError.current = msg;
        setShowErrorArea(true);
    };

    const handleRedirect = (msg) => {
        setShowRedirectArea(false);
        msgError.current = msg;
        setShowRedirectArea(true);
    };

    const queryUrl = (name, url = window.location.href) => {
        name = name.replace(/[[]]/g, "\\$&");
  
        const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
        const results = regex.exec(url);
    
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return "";
        }
    
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    const getFormMatricula = useCallback(async(formularioId, institucionId)=>{
        var result = false;
        try {
            const qsp = {
                formularioId: formularioId,
                institucionId: institucionId ? institucionId : userData.institucionId,
            };
            const response = await API.get('rocket', '/getMatriculaFormulario', {
                queryStringParameters: qsp,
            })
            if(response.result){
                result = response.description.requiredFields;
                urlCondiciones.current = response.description.urlCondiciones;
            } else {
                console.log(response);
            }
        } catch (error) {
            onError(error);
        }
        return result;
    },[userData]);

    const prepareFields = useCallback((flds, studentData)=>{
        if(studentData){
            for (let index = 0; index < flds.length; index++) {
                const element = flds[index];
                switch (element.label) {
                    case 'Nombre':
                        flds[index].value = studentData.nombre;
                        break;
                    case 'Apellido':
                        flds[index].value = studentData.apellido;
                        break;
                    case 'DNI':
                        flds[index].value = studentData.identificacion;
                        break;
                    case 'Nacimiento':
                        flds[index].value = studentData.nacimiento;
                        break;
                    default:
                        break;
                }
            }
            if(studentData.hasOwnProperty('acudientes')){
                const acudientes = studentData.acudientes;
                for (let index = 0; index < acudientes.length; index++) {
                    const acudiente = acudientes[index];
                    for (const key in flds) {
                        if (Object.hasOwnProperty.call(flds, key)) {
                            const element = flds[key];
                            if(element.type === 'Parent'){
                                if(!element.hasOwnProperty('value')){
                                    for (let index = 0; index < element.fields.length; index++) {
                                        const acFields = element.fields[index];
                                        
                                        switch (acFields.label) {
                                            case 'DNI':
                                                acFields.value = acudiente.ID;
                                                break;
                                            case 'Nombre':
                                                acFields.value = acudiente.Nombre;
                                                break;
                                            case 'Apellido':
                                                acFields.value = acudiente.Apellido;
                                                break;
                                            case 'Teléfono':
                                                acFields.value = acudiente.Telefono;
                                                break;
                                            case 'email':
                                                acFields.value = acudiente.email;
                                                break;
                                            case 'Vínculo':
                                                acFields.value = acudiente.Vinculo;
                                                break;
                                            case "¿Responsable de pagos?":
                                                acFields.value = acudiente.payPerson;
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                    element.value = true;
                                    break;
                                }
                            }
                        }
                    }
                }
            }
        }
        for (let index = 0; index < flds.length; index++) {
            const element = flds[index];
            if(element.mandatory){
                switch (element.name) {
                    case 'Nombre Formulario':
                    case 'Divisor':
                    case 'Parrafo':
                    case 'email':
                        mandatoryHeadersFields.current.push(element);
                        break;
                    default:
                        otherFields.current.push(element);
                        break;
                }
            
            } else {
                otherFields.current.push(element);
            }
        }
        // console.log(mandatoryHeadersFields.current);
        // console.log(otherFields.current);
        setFields(flds);
        if(studentData){
            setIsUserDataLoaded(true);
        }
        setIsLoading(false);
    },[]);

    const matriculaEstudianteRegistado = useCallback(async() => {
        try {
            if(userData.usuarioId.length > 0){
                console.log('Hola ' + userData.nombre);
                if(configMatriculas.activas && (configMatriculas.formulario.formularioId !== 0)){
                    if(userData.rolName === 'estudiante'){
                        const qsp = {
                            institucionId: userData.institucionId,
                            get: ['email','apellido','identificacion','nacimiento','nombre', 'acudientes', 'dataInstitucional'],
                        }
                        const udata = await API.get('rocket','/usuarios/'+userData.usuarioId, {
                            queryStringParameters: qsp,
                        });
                        if(udata.result === 'fail'){
                            handleError('No se pueden registrar matriculas en este momento, error obteniendo los datos del usuario');
                            const err = 'GetUserDataException';
                            throw (err);
                        }
                        const formFields = await getFormMatricula(configMatriculas.formulario.formularioId);
                        if(!formFields){
                            handleError('No se puede mostrar el formulario de matricula en este momento, intentalo de nuevo mas tarde.');
                            const err = 'GetFormMatriculaException';
                            throw (err);
                        }
                        urlCondiciones.current = configMatriculas.urlCondiciones;
                        prepareFields(formFields, udata.description);
                        setCandidateEmail(userData.email);
                    } else {
                        handleError('Solo los estudiantes pueden matricularse...');
                        const err = 'UserRolException';
                        throw (err);
                    }
                } else {
                    handleError('No se pueden registrar matriculas en este momento.');
                    const err = 'ConfigMatriculasException';
                    throw (err);
                }
            } else {
                handleRedirect('Debes iniciar sesión en rocket para registrar tu matricula, te redirigiremos a rocket en cuanto cierres este mensaje.');
                const err = 'NoUserDataInStoreException';
                throw (err);
            }
        } catch (error) {
            onError(error);
        }
    },[configMatriculas,getFormMatricula, prepareFields, userData]);

    const matriculaEstudianteNuevo = useCallback(async() => {
        console.log('Bienvenido nuevo estudiante');
        const institucionId = queryUrl("instId");
        const formularioId = queryUrl("fId");
        try {
            const userAuth = await Auth.signIn(config.matriculasUser, config.matriculasPass);
            if(userAuth){
                const formFields = await getFormMatricula(formularioId, institucionId);
                if(!formFields){
                    handleError('No se puede mostrar el formulario de matricula en este momento, intentalo de nuevo mas tarde.');
                    const err = 'GetFormMatriculaException';
                    throw (err);
                }
                prepareFields(formFields);
                Auth.signOut();
            }
        } catch (error) {
            onError(error);
            Auth.signOut();
        }
    },[getFormMatricula, prepareFields]);

    useEffect(()=>{
        const validateUserState = async() => {
            //Validar si el usuario esta autenticado
            try {
                await Auth.currentSession();
                isCurrentUser.current = true;
                matriculaEstudianteRegistado();
            } catch (error) {
                onError(error);
                matriculaEstudianteNuevo();
            }
        };

        if(isFirstRender.current){
            isFirstRender.current = false;
            if(props.preview){
                prepareFields(props.fields);
            } else {
                validateUserState();
            }
        }
    },[props, prepareFields, matriculaEstudianteRegistado, matriculaEstudianteNuevo]);

    useEffect(()=>{
        return function cleanUp(){
            setConfigMatriculas({});
        }
    },[setConfigMatriculas])

    const handleEnableNext = () => {
        return candidateEmail.length > 3 && candidateEmail.includes('@')
    };

    const getDataFromEmail = () => {
        if(props.preview){
            setIsUserDataLoaded(true);
        } else {
            //El usuario no esta autenticado, es un nuevo candidato
            setIsUserDataLoaded(true);
        }
    };

    const formatDate = (date) => {
        var stringDate = '';
        const startDate = new Date(date);
        const y = startDate.getFullYear();
        const m = (startDate.getMonth() + 1) < 10 ? '0' + (startDate.getMonth() + 1) : (startDate.getMonth() + 1);
        const d = startDate.getDate() < 10 ? '0' + startDate.getDate() : startDate.getDate();
        stringDate =  y + '-' + m + '-' + d;
        return stringDate;
    };

    const renderField = (field) => {
        var data = null;
        switch (field.type) {
            case 'Divider':
                data = <Row key={field.key}><Col><hr></hr></Col></Row>;
                break;
            case 'Header':
                if(field.class[0] === 'h1'){
                    data = <Row key={field.key}><Col><h1>{field.text}</h1></Col></Row>;
                } else if(field.class[0] === 'h2'){
                    data = <Row key={field.key}><Col><h2>{field.text}</h2></Col></Row>;
                } else if(field.class[0] === 'h3'){
                    data = <Row key={field.key}><Col><h3>{field.text}</h3></Col></Row>;
                } else if(field.class[0] === 'h4'){
                    data = <Row key={field.key}><Col><h4>{field.text}</h4></Col></Row>;
                } else {
                    data = <Row key={field.key}><Col>{field.text}</Col></Row>;
                }                                
                break;
            case 'Input':
                data = 
                    <Row key={field.key}>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text >{field.label}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    className={field.label !== 'Nacimiento' ? 'capitalize' : ''}
                                    type={field.control_type}
                                    name={field.label}
                                    defaultValue={field.label === 'Nacimiento' ? formatDate(field.value) : field.value}
                                    id={field.key}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                break;
            case 'Select':
                data = 
                    <Row key={field.key}>
                        <Col>
                            <Form.Group >
                                <Form.Label>{field.label}</Form.Label>
                                <Form.Control as="select" name={field.label} id={field.key}>
                                    {field.options.map((y)=>{
                                        return <option key={y.id} value={y.value}>{y.option}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                break;
            case 'Checkbox':
            case 'Radio':
                data = 
                    <Row key={field.key}>
                        <Col>
                            <Form.Group >
                                <Form.Label>{field.label}</Form.Label>
                                    {field.options.map((y)=>{
                                        return (
                                            <Form.Check
                                                key={y.id} 
                                                value={y.value}
                                                label={y.option}
                                                name={field.key}
                                                type={field.type.toLowerCase()}
                                                id={y.id}
                                            />
                                        )
                                    })}
                            </Form.Group>
                        </Col>
                    </Row>
                break;
            case 'Textarea':
                data = 
                    <Row key={field.key}>
                        <Col>
                            <Form.Group >
                                <Form.Label>{field.label}</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    rows={3} 
                                    name={field.key}
                                    id={field.key}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                break;
            case 'Parent':
                data = 
                    <div key={field.key}>
                        <Row>
                            <Col>
                                <Form.Label>{field.label}</Form.Label>
                            </Col>
                        </Row>
                        <Row >
                            {field.fields.map((y)=>{
                                switch (y.control_type) {
                                    case 'input':
                                    case 'email':
                                    case 'number':
                                        return(
                                            <Col xs={6} key={y.id}>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text >{y.label}</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <FormControl
                                                        className='capitalize'
                                                        type={y.control_type}
                                                        defaultValue={y.value}
                                                        id={y.id}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        )
                                    case 'select':
                                        return (
                                            <Col xs={6} key={y.id}>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text >{y.label}</InputGroup.Text>
                                                    </InputGroup.Prepend>
                                                    <FormControl 
                                                        as='select'
                                                        defaultValue={y.value}
                                                        id={y.id}
                                                    >
                                                        {y.options.map((z)=>{
                                                            return <option key={z.id} value={z.value}>{z.option}</option>
                                                        })}
                                                    </FormControl>
                                                </InputGroup>
                                            </Col>
                                        )
                                    case 'checkbox':
                                        return (
                                            <Col xs={6} key={y.id}>
                                                <Form.Group >
                                                    <Form.Check 
                                                        type={y.control_type}
                                                        name={y.label}
                                                        id={y.id}
                                                        label={y.label}
                                                        defaultChecked={y.value}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        )
                                    default:
                                        return <></>;
                                }
                            })}
                        </Row>
                    </div>
                break;
            default:
                break;
        }
        return data;
    };

    const validateFormData = async(data) => {
        //console.log(data);
        console.log('Validando campos del formulario');
        var result = {
            isValid: true,
            notValidFields: [],
        };
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if(element.required){
                if(element.type === 'Input' || element.type === 'Textarea'){
                    if(!(element.value.length > 3)){
                        result.isValid = false;
                        result.notValidFields.push(element.field);
                    }
                }
                if(element.type === 'Parent'){
                    for (let index = 0; index < element.value.length; index++) {
                        const pd = element.value[index];
                        switch (pd.type) {
                            case 'number':
                            case 'input':
                                if(!(pd.value.length > 3)){
                                    result.isValid = false;
                                    result.notValidFields.push(element.field+': ' + pd.field);
                                }
                                break;
                            case 'email':
                                if(!((pd.value.length > 3) && pd.value.includes('@'))){
                                    result.isValid = false;
                                    result.notValidFields.push(element.field+': ' + pd.field);
                                }
                                break;
                            default:
                                break;
                        }
                    }
                }
                if(element.type === 'Checkbox' || element.type === 'Radio'){
                    if(!element.value.length > 0){
                        result.isValid = false;
                        result.notValidFields.push(element.field);
                    }
                }
            }
        }
        return result;
    };

    const crearMatricula = async(candidateData) => {
        var result = false;
        try {
            const data = {};
            if(isCurrentUser.current){
                if(configMatriculas.activas && (configMatriculas.formulario.formularioId !== 0)){
                    data.institucionId = userData.institucionId;
                    data.formularioId = configMatriculas.formulario.formularioId;
                    data.usuarioId = userData.usuarioId;
                } else {
                    const err = 'ConfigMatriculasException';
                    throw (err);
                }
            } else {
                //const institucionId = queryUrl("instId");
                //const formularioId = queryUrl("fId");
                data.institucionId = queryUrl("instId");
                data.formularioId = queryUrl("fId");
            }
            data.dataCandidato = candidateData;
            data.emailCandidato = candidateEmail;
            const response = await API.post('rocket','/crearMatriculaNueva',{
                body: data,
            })
            if(!response.result){
                console.log(response);
                const err='UnableSaveMatricula'
                throw (err);
            }
            result = true;
        } catch (error) {
            onError(error);
        }
        return result;
    };

    const enviarMatricula = async() => {
        setShowCondicionesModal(false);
        const event = eventFormulario.current
        //event.preventDefault();
        setShowInvalidFieldArea(false);
        const extractedData = [];
        for (let index = 0; index < fields.length; index++) {
            const element = fields[index];
            try{
                const field = element.label;
                if(field !== undefined){
                    if((element.type === 'Checkbox') || (element.type === 'Radio')){
                        const inputElements = document.getElementsByName(element.key);
                        const checkeds = [];
                        for (let index = 0; index < inputElements.length; index++) {
                            const ie = inputElements[index];
                            if(ie.checked){
                                const option = ie.parentElement.textContent.trim();
                                const iedata = {
                                    option: option,
                                    value: ie.value,
                                }
                                checkeds.push(iedata);
                            }
                        }
                        const data = {
                            field: field,
                            value: checkeds,
                            type: element.type,
                            required: element.mandatory || element.required
                        };
                        extractedData.push(data);
                    } else if (element.type === 'Textarea') {
                        const ta = document.getElementsByName(element.key);
                        const tav = ta[0].value.trim();
                        const data = {
                            field: field,
                            value: tav,
                            type: element.type,
                            required: element.mandatory || element.required
                        };
                        extractedData.push(data);
                    } else if(element.type === 'Parent') {
                        const parentValues = [];
                        for (let index = 0; index < element.fields.length; index++) {
                            const parentField = element.fields[index];
                            switch (parentField.control_type) {
                                case 'input':
                                case 'email':
                                case 'select':
                                case 'number':
                                    const parentData = document.getElementById(parentField.id);
                                    const pd = {
                                        field: parentField.label,
                                        value: parentData.value.trim().toLowerCase(),
                                        type: parentField.control_type
                                    };
                                    parentValues.push(pd)
                                    break;
                                case 'checkbox':
                                    const checkElement = document.getElementById(parentField.id);
                                    const dataCheck = {
                                        field: parentField.label,
                                        value: checkElement.checked,
                                        tupe: parentField.control_type
                                    };
                                    parentValues.push(dataCheck);
                                    break;
                                default:
                                    break;
                            }
                        }
                        const data = {
                            field: field,
                            value: parentValues,
                            type: element.type,
                            required: element.mandatory || element.required
                        };
                        extractedData.push(data);
                    } else {
                        const value = event.target[element.label].value.trim().toLowerCase();
                        const data = {
                            field: field,
                            value: value,
                            type: element.type,
                            required: element.mandatory || element.required
                        };
                        extractedData.push(data);
                    }
                }
            } catch(error){
                console.log('No se puede obtener el valor para el campo: ' + element.label);
                console.log(event.target[element.label]);
            }
        }
        const validationResult = await validateFormData(extractedData);
        if(validationResult.isValid){
            if(props.preview){
                setOkSendMatricula(true);
                console.log(extractedData);
            } else {
                //validar si el envio lo hace un estudiante actual (Autenticado) 
                // o uno nuevo (Sin autenticar)
                console.log('Guardando datos del candidato...');
                setIsSavingMatricula(true);
                try {
                    if(isCurrentUser.current){
                        const registro = await crearMatricula(extractedData);
                        if(!registro){
                            setIsSavingMatricula(false);
                            handleError('No es posible guardar tu información de matricula en este momento, intentalo de nuevo mas tarde.');
                            const err = 'SaveMatriculaException';
                            throw (err);
                        }
                        setIsSavingMatricula(false);
                        setOkSendMatricula(true);
                    } else {
                        await Auth.signIn(config.matriculasUser, config.matriculasPass);
                        const registro = await crearMatricula(extractedData);
                        if(!registro){
                            Auth.signOut();
                            setIsSavingMatricula(false);
                            handleError('No es posible guardar tu información de matricula en este momento, intentalo de nuevo mas tarde.');
                            const err = 'SaveMatriculaException';
                            throw (err);
                        }
                        Auth.signOut();
                        setIsSavingMatricula(false);
                        setOkSendMatricula(true);
                    }
                } catch (error) {
                    onError(error);
                }
            }
        } else {
            var invalidf = '';
            for (let index = 0; index < validationResult.notValidFields.length; index++) {
                const nvf = validationResult.notValidFields[index];
                invalidf = invalidf + nvf + ', ';
            }
            setInvalidField(invalidf);
            setShowInvalidFieldArea(true);
        }
    };

    const handleCondiciones = (event) => {
        event.preventDefault();
        event.persist();
        eventFormulario.current = event;
        setShowCondicionesModal(true);
    };

    useEffect(()=>{
        console.log('Campo Invalido: ' + invalidField);
    },[invalidField]);

    const handleTerminate = () => {
        // href a la pagina que se haya configurado como destino para enviar al finalizar el diligenciamiento
        if(props.preview){
            console.log('Pre-visualización finalizada');
        } else {
            if(isCurrentUser.current){
                if(configMatriculas && (configMatriculas.urlReenvio.length === 0)){
                    history.push('/');
                } else {
                    window.location.replace(configMatriculas.urlReenvio);
                }
            } else {
                history.push('/');
            }    
        }    
    };

    const handleCloseErrorArea = () => {
        setShowErrorArea(false);
    };

    const handleCloseRedirectArea = () => {
        setShowRedirectArea(false);
        history.push('/');
    };

    const handleSavingMatriculaModal = () => {
        setIsSavingMatricula(false);
    };

    const handleCloseCondicionesModal = () => {
        setShowCondicionesModal(false);
    }; 

    return (
        <div>
            {showCondicionesModal && 
                <Modal 
                    show={showCondicionesModal}
                    onHide={handleCloseCondicionesModal}
                    size={'lg'}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        Condiciones y política de privacidad
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col className="text-center mt-2">
                                <p>Al enviar el formulario de matrícula manifiestas el conocimiento y la aceptación de las 
                                    condiciones de uso, política de privacidad y/o políticas institucionales, las cuales puedes 
                                    consultar a continuación:</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                {urlCondiciones.current && <a rel="noopener noreferrer" target='_blank' href={urlCondiciones.current}>Políticas institucionales</a>}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center">
                                <a rel="noopener noreferrer" target='_blank' href={'https://www.gvalor.co/condiciones-de-uso'}>Condiciones de uso</a>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='outline-primary' onClick={()=>enviarMatricula()}>Aceptar</Button>
                        <Button variant='outline-secondary' onClick={()=>handleCloseCondicionesModal()}>Cancelar</Button>
                    </Modal.Footer>
                </Modal>
            }
            {isSavingMatricula && 
                <Modal 
                    show={isSavingMatricula}
                    onHide={handleSavingMatriculaModal}
                    size={'lg'}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        Guardando matrícula
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col className="text-center mt-5">
                                <Spinner animation="border" variant="primary" />
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            }
            {showRedirectArea && 
                <Modal 
                    show={showRedirectArea}
                    onHide={handleCloseRedirectArea}
                    size={'lg'}
                >
                    <Modal.Header closeButton>
                        Matrículas
                    </Modal.Header>
                    <Modal.Body>
                        {msgError.current}
                    </Modal.Body>
                </Modal>
            }
            {showErrorArea && 
                <Modal 
                    show={showErrorArea}
                    onHide={handleCloseErrorArea}
                    size={'lg'}
                >
                    <Modal.Header closeButton>
                        Matrículas
                    </Modal.Header>
                    <Modal.Body>
                        {msgError.current}
                    </Modal.Body>
                </Modal>
            }
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-5">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                mandatoryHeadersFields.current.map((x)=>{
                    switch (x.name) {
                        case 'Nombre Formulario':
                            if(x.class[0] === 'h1'){
                                return <Row className='mt-2' key={x.key}><Col><h1>{x.text}</h1></Col></Row>;
                            } else if(x.class[0] === 'h2'){
                                return <Row className='mt-2' key={x.key}><Col><h2>{x.text}</h2></Col></Row>;
                            } else if(x.class[0] === 'h3'){
                                return <Row className='mt-2' key={x.key}><Col><h3>{x.text}</h3></Col></Row>;
                            } else if(x.class[0] === 'h4'){
                                return <Row className='mt-2' key={x.key}><Col><h4>{x.text}</h4></Col></Row>;
                            } else {
                                return x.text;
                            }
                        case 'Divisor':
                            return <Row key={x.key} className={isUserDataLoaded ? "mb-3" : "mb-5"}><Col><hr></hr></Col></Row>
                        case 'Parrafo':
                            return <Row key={x.key} ><Col dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(x.text) }}></Col></Row>
                        case 'email':
                            return (
                                <div key={x.key}>
                                    <Row  >
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text >{x.label}</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl
                                                    type={x.control_type}
                                                    value={candidateEmail}
                                                    onChange={e=>setCandidateEmail(e.target.value)}
                                                    disabled={isUserDataLoaded}
                                                    isInvalid={!handleEnableNext()}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Falta el correo electrónico. Asegurate de ingresas un correo valido, allí enviaremos todas las comunicaciones referentes a tú proceso de matrícula.
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row style={isUserDataLoaded ? {display:'none'} : {display:'flex'}}>
                                        <Col className='text-right mt-5'>
                                            <Button onClick={()=>getDataFromEmail()} disabled={!handleEnableNext()} variant='outline-primary'>Siguiente</Button>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        default:
                            return null;
                    }
                })        
            }
            {isUserDataLoaded && !okSendMatricula && 
                <form className='mb-5' onSubmit={(event)=>handleCondiciones(event)}>
                    {otherFields.current.map((x)=>(
                        x.type === 'Paragraph' ?
                            <Row key={x.key} ><Col dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(x.text) }}></Col></Row>
                        :
                            renderField(x)
                    ))}
                    {showInvalidFieldArea && 
                        <Row>
                            <Col>
                                <hr />
                                <Alert variant='info'>
                                    <Alert.Heading>Campo Invalido</Alert.Heading>
                                    <p>Encontramos los siguientes errores en el formulario: {invalidField} por favor verifica los datos ingresados e intentalo de nuevo.</p>
                                </Alert>
                                <hr />
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col className='text-right'>
                            <Button  type='submit' variant='outline-success'>Enviar matricula</Button>
                        </Col>
                    </Row>
                </form>

            }
            {okSendMatricula &&
                <div className='mt-5 mb-5'>
                    <Row >
                        <Col className='text-center'>
                            <h4>¡Gracias! Hemos recibido correctamente el formulario</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='text-center mt-3'>
                            <Button onClick={()=>handleTerminate()} variant='outline-primary'>Terminar</Button>
                        </Col>
                    </Row>
                </div>
            }
            <Row className="login-footer" style={props.preview ? {position:'absolute'} : {} }>
                <Col className="text-center align-self-center">
                    <Form.Text className="text-muted footer-login-text">
                        © Grupo Valor International Solutions - Todos los derechos reservados.
                    </Form.Text>
                </Col>
            </Row>
        </div>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(FormMatriculaGenerate);