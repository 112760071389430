import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col, Card, Spinner, Button, Dropdown, Modal } from "react-bootstrap";
import { FixedSizeList as List2 } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Editor } from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import API from "@aws-amplify/api";
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import CrearAnuncio from "./crearAnuncio";

const RenderCurso = (props) => {

    const [ isLoading, setIsLoading ] = useState(true);
    const [showModalAnuncio, setShowModalAnuncio] = useState(false);

    const anuncios_curso = useRef([]);
    const isFirstRender = useRef(true);
    const dummyInd = useRef(false);
    const modalAnuncioTitle = useRef("");
    const modalAnuncioBody = useRef("");

    function timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var time = date + ' ' + month + ' ' + year + '  ' + hour + ':' + min ;
        return time;
      }

    const dummyAnouncementData = [
        {
            titulo: "No hay anuncios!",
            anuncio: "Este curso aún no tiene anuncios. Sin embargo, pronto podria tenerlos, no dejes de visitarlo!",
            anuncioId: "123-abc",
            fecha: timeConverter(Date.now()),
            estado: true
        },
        /*{
            titulo: "Anuncio 1",
            anuncio: "Ea laborum ex aute ad anim elit ullamco nisi deserunt nisi minim do. Nostrud irure minim mollit in ullamco. Enim culpa ipsum non adipisicing anim Lorem quis ad et. Id eiusmod duis do et commodo aliquip et dolor. Dolore ad velit officia aliquip dolore nisi fugiat quis. Laboris quis occaecat laborum quis eu Lorem irure sit et."
        },
        {
            titulo: "Anuncio 2",
            anuncio: "Est et velit incididunt dolor ad non ex magna eiusmod consequat cupidatat labore excepteur. Excepteur et duis culpa id do nostrud nulla. Consequat aliquip esse laborum culpa labore excepteur ipsum dolor.Dolor ex aute irure exercitation aliqua Lorem consectetur. Anim magna cupidatat aliquip dolore sint deserunt irure in exercitation. Labore sit dolor proident non dolore dolor esse esse et exercitation sunt et do officia.Est et velit incididunt dolor ad non ex magna eiusmod consequat cupidatat labore excepteur. Excepteur et duis culpa id do nostrud nulla. Consequat aliquip esse laborum culpa labore excepteur ipsum dolor.Dolor ex aute irure exercitation aliqua Lorem consectetur. Anim magna cupidatat aliquip dolore sint deserunt irure in exercitation. Labore sit dolor proident non dolore dolor esse esse et exercitation sunt et do officia.Est et velit incididunt dolor ad non ex magna eiusmod consequat cupidatat labore excepteur. Excepteur et duis culpa id do nostrud nulla. Consequat aliquip esse laborum culpa labore excepteur ipsum dolor.Dolor ex aute irure exercitation aliqua Lorem consectetur. Anim magna cupidatat aliquip dolore sint deserunt irure in exercitation. Labore sit dolor proident non dolore dolor esse esse et exercitation sunt et do officia.Est et velit incididunt dolor ad non ex magna eiusmod consequat cupidatat labore excepteur. Excepteur et duis culpa id do nostrud nulla. Consequat aliquip esse laborum culpa labore excepteur ipsum dolor.Dolor ex aute irure exercitation aliqua Lorem consectetur. Anim magna cupidatat aliquip dolore sint deserunt irure in exercitation. Labore sit dolor proident non dolore dolor esse esse et exercitation sunt et do officia."
        },
        {
            titulo: "Anuncio 3",
            anuncio: "Est et velit incididunt dolor ad non ex magna eiusmod consequat cupidatat labore excepteur. Excepteur et duis culpa id do nostrud nulla. Consequat aliquip esse laborum culpa labore excepteur ipsum dolor.Dolor ex aute irure exercitation aliqua Lorem consectetur. Anim magna cupidatat aliquip dolore sint deserunt irure in exercitation. Labore sit dolor proident non dolore dolor esse esse et exercitation sunt et do officia."
        },
        {
            titulo: "Anuncio 4",
            anuncio: "Est et velit incididunt dolor ad non ex magna eiusmod consequat cupidatat labore excepteur. Excepteur et duis culpa id do nostrud nulla. Consequat aliquip esse laborum culpa labore excepteur ipsum dolor.Dolor ex aute irure exercitation aliqua Lorem consectetur. Anim magna cupidatat aliquip dolore sint deserunt irure in exercitation. Labore sit dolor proident non dolore dolor esse esse et exercitation sunt et do officia."
        },
        {
            titulo: "Anuncio 5",
            anuncio: "Est et velit incididunt dolor ad non ex magna eiusmod consequat cupidatat labore excepteur. Excepteur et duis culpa id do nostrud nulla. Consequat aliquip esse laborum culpa labore excepteur ipsum dolor.Dolor ex aute irure exercitation aliqua Lorem consectetur. Anim magna cupidatat aliquip dolore sint deserunt irure in exercitation. Labore sit dolor proident non dolore dolor esse esse et exercitation sunt et do officia."
        },
        {
            titulo: "Anuncio 6",
            anuncio: "Est et velit incididunt dolor ad non ex magna eiusmod consequat cupidatat labore excepteur. Excepteur et duis culpa id do nostrud nulla. Consequat aliquip esse laborum culpa labore excepteur ipsum dolor.Dolor ex aute irure exercitation aliqua Lorem consectetur. Anim magna cupidatat aliquip dolore sint deserunt irure in exercitation. Labore sit dolor proident non dolore dolor esse esse et exercitation sunt et do officia."
        },
        {
            titulo: "Anuncio 7",
            anuncio: "Est et velit incididunt dolor ad non ex magna eiusmod consequat cupidatat labore excepteur. Excepteur et duis culpa id do nostrud nulla. Consequat aliquip esse laborum culpa labore excepteur ipsum dolor.Dolor ex aute irure exercitation aliqua Lorem consectetur. Anim magna cupidatat aliquip dolore sint deserunt irure in exercitation. Labore sit dolor proident non dolore dolor esse esse et exercitation sunt et do officia."
        },
        {
            titulo: "Anuncio 8",
            anuncio: "Est et velit incididunt dolor ad non ex magna eiusmod consequat cupidatat labore excepteur. Excepteur et duis culpa id do nostrud nulla. Consequat aliquip esse laborum culpa labore excepteur ipsum dolor.Dolor ex aute irure exercitation aliqua Lorem consectetur. Anim magna cupidatat aliquip dolore sint deserunt irure in exercitation. Labore sit dolor proident non dolore dolor esse esse et exercitation sunt et do officia."
        }*/
    ]   

    const prepareAnnouncement = useCallback(async(anuncios)=>{
        for (let index = 0; index < anuncios.length; index++) {
            const element = anuncios[index];
            if(element.estado){
                const raw_data = element.textoAnuncio;
                const nous_data = {
                    titulo: element.tituloAnuncio,
                    anuncio: raw_data,
                    anuncioId: element.anuncioId,
                    fecha: element.fecha,
                    estado: element.estado,
                    cursoId: element.cursoId
                };
                anuncios_curso.current.push(nous_data);
            } else {
                if(props.rol === "docente" || props.rol === "coordinador" || props.rol === "manager"){
                    const raw_data = element.textoAnuncio;
                    const nous_data = {
                        titulo: element.tituloAnuncio,
                        anuncio: raw_data,
                        anuncioId: element.anuncioId,
                        fecha: element.fecha,
                        estado: element.estado,
                        cursoId: element.cursoId
                    };
                    anuncios_curso.current.push(nous_data);
                }
            }
        }
    },[props]);

    const getAnuncios = useCallback(async()=>{
        const qsp = {
            cursoId: props.cursoId
        }
        API.get("rocket","/getAnuncios",{
            queryStringParameters: qsp
        }).then(async(value)=>{
            if(value.result === "success"){
                isFirstRender.current = false;
                await prepareAnnouncement(value.description);
                setIsLoading(false);
            } else{
                console.log(value);
                //dummyData
                anuncios_curso.current = dummyAnouncementData;
                dummyInd.current = true;
                setIsLoading(false);
            }
        })
    },[dummyAnouncementData, prepareAnnouncement, props])

    useEffect(()=>{
        if(isFirstRender.current){
            getAnuncios();
        }
    },[dummyAnouncementData, getAnuncios])

    const renderCard = (btn_edit, btn_showHide, btn_delete, titulo, anuncio, fecha, style) => {
        if(dummyInd.current){
            return (
                <div style={style}>
                    <Card>
                        <Card.Header className="capitalize">
                            {dummyAnouncementData[0].titulo}
                        </Card.Header>
                        <Card.Body>
                            {dummyAnouncementData[0].anuncio}     
                        </Card.Body>
                    </Card>
                </div>
            )
        } else {
            return (
                <div style={style}>
                    <Card>
                        <Card.Header className="capitalize">
                            <Row>
                                <Col>{titulo}</Col>
                                <Col md={"auto"}>
                                    <Row>
                                        <Col className="text-right" md={"auto"} style={{paddingRight: "0px"}}>
                                            {btn_edit}
                                        </Col>
                                        <Col className="text-right" md={"auto"} style={{paddingRight: "0px"}}>
                                            {btn_showHide}
                                        </Col>
                                        <Col className="text-right" md={"auto"}>
                                            {btn_delete}
                                        </Col>
                                    </Row>
                                </Col>    
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Editor 
                                toolbarHidden
                                initialContentState={anuncio}
                                readOnly
                                editorClassName="editor-anuncios-curso"
                            />     
                            <footer className="text-right blockquote-footer">
                                <cite title="Publicado en">{fecha}</cite>
                            </footer>                   
                        </Card.Body>
                    </Card>
                </div>
            )
        }
    }

    const handleEstadoAnuncio = async(anuncio_Data) => {
        //console.log(anuncio_Data);
        if(anuncio_Data){
            setIsLoading(true);
            anuncios_curso.current = [];
            const anon_data = {
                institucionId: props.institucionId,
                propiedades: [
                    {
                        propiedad: "estado",
                        value: !anuncio_Data.estado
                    }
                ]
            };
            try{
                const response = await API.put("rocket", "/anuncios/"+anuncio_Data.anuncioId, {
                    body: anon_data
                });
                if (response.result === "success"){
                    getAnuncios();
                } else {
                    console.log(response);
                    setIsLoading(false);
                }
            } catch (error) {
                console.log("Error actualizando anuncio");
                console.log(error);
                setIsLoading(false);
            }
        } else {
            console.log("No hay información de anuncio a actualizar");
        }
    }

    const handleEliminaAnuncio = async(anuncio_data) => {
        if(anuncio_data){
            setIsLoading(true);
            anuncios_curso.current = [];
            const anon_del_data = {
                institucionId: props.institucionId
            };
            try {
                const response = await API.del("rocket","/anuncios/"+anuncio_data.anuncioId,{
                    body: anon_del_data
                });
                if (response.result === "success"){
                    getAnuncios();
                } else {
                    console.log(response);
                    setIsLoading(false);
                }
            } catch(error){
                console.log("Error eliminando anuncio");
                console.log(error);
                setIsLoading(false);
            }
        } else {
            console.log("No hay información de anuncio a eliminar");
        }
    }

    const handleEditarAnuncio = (data_anuncio) => {
        modalAnuncioTitle.current = ["Editar anuncio | ", <span key="123x" className="capitalize">{props.cursoNombre}</span>]
        modalAnuncioBody.current = <CrearAnuncio anuncio_data={data_anuncio} cursoId={data_anuncio.cursoId} onFinish={setShowModalAnuncio}/>
        setShowModalAnuncio(true);
    }

    const anuncioToRender = ({index, style}) => {
        const estado = anuncios_curso.current[index].estado;
        var btn_edit = <div></div>;
        var btn_showHide = <div></div>;
        var btn_delete = <div></div>;
        if(props.rol === "docente" || props.rol === "coordinador" || props.rol === "manager"){
            btn_edit = <Button onClick={()=>handleEditarAnuncio(anuncios_curso.current[index])} className="action-button" variant="light"><AiOutlineEdit /></Button>;
            btn_delete =
            <Dropdown>
                <Dropdown.Toggle className="action-button" title="Eliminar anuncio" variant="ligh" id="dropdown-delete">
                    <AiOutlineDelete />
                </Dropdown.Toggle>
            
                <Dropdown.Menu>
                    <Dropdown.Item >¿Seguro?</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={()=>handleEliminaAnuncio(anuncios_curso.current[index])}>Si, elimina este anuncio</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>;
            switch (estado) {
                case true:
                    btn_showHide = <Button onClick={()=>handleEstadoAnuncio(anuncios_curso.current[index])} className="action-button" variant="light"><AiOutlineEyeInvisible /></Button>
                    break;
                case false:
                    btn_showHide = <Button onClick={()=>handleEstadoAnuncio(anuncios_curso.current[index])} className="action-button" variant="light"><AiOutlineEye /></Button>
                    break;
                default:
                    break;
            }
        }
        const titulo = estado ? anuncios_curso.current[index].titulo : anuncios_curso.current[index].titulo + " - Anuncio Oculto -";
        const anuncio = anuncios_curso.current[index].anuncio;
        const fecha = timeConverter(anuncios_curso.current[index].fecha);
        if(!estado){
            if(props.rol === "docente" || props.rol === "coordinador" || props.rol === "manager"){
                return (renderCard(btn_edit, btn_showHide, btn_delete, titulo, anuncio, fecha, style));
            } else {
                return (<div></div>)
            }
        } else {
            return (renderCard(btn_edit, btn_showHide, btn_delete, titulo, anuncio, fecha, style));
        }
    }

    const handleCloseModalAnuncio = () => {
        modalAnuncioTitle.current = "";
        modalAnuncioBody.current = "";
        setShowModalAnuncio(false);
    }

    return (
        <div>
            <Modal 
                show={showModalAnuncio}
                onHide={()=>handleCloseModalAnuncio()}
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modalAnuncioTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalAnuncioBody.current}
                </Modal.Body>
            </Modal>
            {isLoading ? 
                <Row>
                    <Col className="text-center">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <Row style={{height: 250}}>
                    <Col>
                        <AutoSizer>
                            {({height, width})=>(
                                <List2
                                    height={250}
                                    width={width}
                                    itemCount={anuncios_curso.current.length}
                                    itemSize={250}
                                >
                                    {anuncioToRender}
                                </List2>
                            )}
                        </AutoSizer>
                    </Col>
                </Row>
            }
        </div>
    )
}

export default RenderCurso;