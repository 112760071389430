import React, { useState, useRef } from "react";
import { Row, Col, Form, Alert } from "react-bootstrap";
import { Auth } from "aws-amplify";
import LoaderButton from "./loaderButton";
import { useHistory } from "react-router-dom";

const RecuperarClave = () => {
    const [email, setEmail] = useState('');
    const [codigo, setCodigo] = useState('');
    const [newPassword,setNewPassword] = useState('');
    const [confirmNewPassword,setConfirmNewPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showCodeArea, setShowCodeArea] = useState(false);
    const [showNewPassArea, setShowNewPassArea] = useState(false);
    const [showMessageArea, setShowMessageArea] = useState(false);
    const [textButton, setTextButton] = useState('Cambiar clave');
    const [textCodeButton, setTextCodeButton] = useState('Confirmar');
    
    const msgAreaMsg = useRef('');
    const msgAreaVariant = useRef('info');

    const history = useHistory();

    const handleErrors = (error) => {
        switch (error.code) {
            case 'LimitExceededException':
                msgAreaMsg.current = 'Haz llegado al máximo de intentos permitidos, intentalo de nuevo más tarde'
                msgAreaVariant.current = 'danger';
                setShowMessageArea(true);
                setTextButton("Cambiar clave");
                setIsLoading(false);
                break;
            case "CodeMismatchException":
                msgAreaMsg.current ="El codigo ingresado no es el correcto, intentalo de nuevo";
                msgAreaVariant.current = 'danger';
                setShowMessageArea(true);
                setTextCodeButton("Confirmar");
                setIsLoading(false);
                break;
            default:
                msgAreaMsg.current = 'Se presento un error realizando el cambio de clave. '+
                'Intentalo de nuevo, si el problema persiste, comunicate con nosotros'
                msgAreaVariant.current = 'danger';
                setShowMessageArea(true);
                setTextButton("Cambiar clave");
                setIsLoading(false);
                break;
        }
    };

    const handleEnableButton = () => {
        return email.length > 3 &&
        email.includes('@')
    };

    const handleEnableConfirmButton = () => {
        return newPassword.length > 7 && newPassword === confirmNewPassword
    };

    const handleEnableCodeButton = () => {
        return codigo.length > 4
    };

    const handleCambiarClave = async() => {
        setTextButton(" ");
        setIsLoading(true);
        setShowMessageArea(false);
        try{
            await Auth.forgotPassword(email);
            setShowCodeArea(true);
            setTextButton("Cambiar clave");
            setIsLoading(false);
        } catch (error) {
            console.log(error);
            setTextButton("Cambiar clave");
            setIsLoading(false);
            handleErrors(error);
        }
    };

    const handleConfirmarCambioClave = async() => {
        setTextCodeButton(" ");
        setIsLoading(true);
        setShowMessageArea(false);
        try{
            await Auth.forgotPasswordSubmit(email, codigo, newPassword);
            await Auth.signIn(email, newPassword);
            history.push("/");
        } catch (error){
            console.log(error);
            setTextCodeButton("Confirmar");
            setIsLoading(false);
            handleErrors(error);
        }
    };

    const handleGoBack = () =>{
        setShowMessageArea(false);
        setShowNewPassArea(false);
        setShowCodeArea(true);
    }

    return (
        <div>
            {!showCodeArea && !showNewPassArea &&
            <div>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="emailFormGroup">
                            <Form.Label>Correo electrónico</Form.Label>
                            <Form.Control 
                                type="email" 
                                placeholder="tucorreo@ejemplo.com"
                                value={email}
                                onChange={e=>setEmail(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <LoaderButton 
                            onClick={()=>handleCambiarClave()}
                            isLoading={isLoading}
                            variant="primary"
                            disabled={!handleEnableButton()}
                        >{textButton}
                        </LoaderButton>
                    </Col>
                </Row>
            </div>
            }
            {showCodeArea && !showNewPassArea &&
            <div>
                <Row>
                    <Col>
                        <Alert show={true} variant='info'>
                            A continuación ingresa el código enviado al 
                            correo electrónico {email}.
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="codePassFormGroup">
                            <Form.Label>Código</Form.Label>
                            <Form.Control 
                                type="number"
                                value={codigo}
                                onChange={e=>setCodigo(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <LoaderButton 
                            onClick={()=>setShowNewPassArea(true)}
                            isLoading={isLoading}
                            variant="success"
                            disabled={!handleEnableCodeButton()}
                        >Siguiente
                        </LoaderButton>
                    </Col>
                </Row>
            </div>
            }
            {showNewPassArea && 
            <div>
                <Row>
                    <Col>
                        <Alert show={true} variant='info'>
                            <Alert.Heading>Ingresa y confirma tu nueva clave.</Alert.Heading>
                            Recuerda que: La nueva clave debe tener mínimo 8 caracteres, debe incluir numeros, 
                            letras mayúsculas, minúsculas y caracteres especiales (*,#).
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="newPassFormGroup">
                            <Form.Label>Nueva clave</Form.Label>
                            <Form.Control 
                                type="password"
                                value={newPassword}
                                onChange={e=>setNewPassword(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3" controlId="confirmNewPassFormGroup">
                            <Form.Label>Confirmar clave</Form.Label>
                            <Form.Control 
                                type="password"
                                value={confirmNewPassword}
                                onChange={e=>setConfirmNewPassword(e.target.value)} />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-right">
                        <LoaderButton 
                            className="mr-3"
                            onClick={()=>handleGoBack()}
                            isLoading={isLoading}
                            variant="primary"
                        >Atrás
                        </LoaderButton>
                        <LoaderButton 
                            onClick={()=>handleConfirmarCambioClave()}
                            isLoading={isLoading}
                            variant="success"
                            disabled={!handleEnableConfirmButton()}
                        >{textCodeButton}
                        </LoaderButton>
                    </Col>
                </Row>
            </div>
            }
            {showMessageArea && 
                <Row className="mt-3">
                    <Col>
                        <Alert show={showMessageArea} variant={msgAreaVariant.current}>
                            {msgAreaMsg.current}
                        </Alert>
                    </Col>
                </Row>
            }
        </div>
    )
};

export default RecuperarClave;