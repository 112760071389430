import store from "../store";
import { actualizaCursoIntegrado, actualizaUsuarioIntegrado } from "./sincro";

/**
     * Funcion para enviar a integracion con croom el curso dado
     * 
     * Variables:
     * cData = la data del curso a integrar
*/
export const integraCursoCroom = (cData) => {
    var cRoomIntegrationData = store.getState().integraciones;
    if(cRoomIntegrationData.croom){
        var croomData = cRoomIntegrationData.croom;
        console.log("Iniciando integración de curso "+cData.internalId);
        var croom_client = require("moodle-client");
        croom_client.init({
            wwwroot: croomData.url,
            token: croomData.usuario,
            service: croomData.servicio,
            strictSSL: false
        }).then(client=>{
            var cursoNombre = cData.nombre;
            var cNombreCap = cursoNombre.charAt(0).toUpperCase() + cursoNombre.slice(1);
            client.call({
                wsfunction: "core_course_create_courses",
                method: "POST",
                args: {
                    courses: [
                        {
                            "fullname": cNombreCap,
                            "shortname": cData.internalId,
                            "categoryid": croomData.categoria
                        }
                    ]
                }
            }).then(value=>{
                if(value.exception){
                    throw new Error(value.message);
                } else {
                    actualizaCursoIntegrado(cData.cursoId, cData.institucionId, value[0].id);
                }
            }).catch(error=>{
                console.log(error);
            })
        }).catch(err=>{
            console.log(err);
        })
        
    } else {
        console.log("La integración con croom no se encuentra configurada");
    }
};

/**
     * Funcion para enviar a integracion con croom (crear) el usuario dado
     * 
     * Variables:
     * uData = la data del usuario a crear en croom
*/
export const integraUsuarioCroom = (uData) => {
    var cRoomIntegrationData = store.getState().integraciones;
    if(cRoomIntegrationData.croom){
        var croomData = cRoomIntegrationData.croom;
        console.log("Iniciando integración de usuario "+uData.identificacion);
        var croom_client = require("moodle-client");
        croom_client.init({
            wwwroot: croomData.url,
            token: croomData.usuario,
            service: croomData.servicio,
            strictSSL: false
        }).then(client=>{
            var userfName = uData.nombre.charAt(0).toUpperCase() + uData.nombre.slice(1);
            var userlName = uData.apellido.charAt(0).toUpperCase() + uData.apellido.slice(1);
            client.call({
                wsfunction: "core_user_create_users",
                method: "POST",
                args: {
                    users: [
                        {   
                            "createpassword": 0,
                            "password": "R0c3e7GV1S*",
                            "username": uData.email,
                            "firstname": userfName,
                            "lastname": userlName,
                            "email": uData.email,
                            "institution": uData.institucionAlias
                        }
                    ]
                }
            }).then(value=>{
                if(value.exception){
                    console.log(value);
                    throw new Error(value.message);
                } else {
                    actualizaUsuarioIntegrado(uData.usuarioId, uData.institucionId, value[0].id);
                }
            }).catch(error=>{
                console.log(error);
            })
        }).catch(err=>{
            console.log(err);
        })
        
    } else {
        console.log("La integración con croom no se encuentra configurada");
    }
};

/**
     * Funcion para enviar a integracion con croom (crear) la inscripcion dada
     * 
     * Variables:
     * cursoIntegracionId = el id de integracion del curso / ya debe existir el curso en croom
     * usuarioIntegracionId = el id de integracion del usuario / ya debe existir el usuario en croom
     * eData = La data de la inscripcion, con el rol del usuario para la inscripcion a integrar
*/
export const integraInscripcionesCroom = (cursoIntegracionId, usuarioIntegracionId, eData) => {
    var cRoomIntegrationData = store.getState().integraciones;
    var cRoomRolesData = store.getState().rolesData;
    if(cRoomIntegrationData.croom){
        var croomData = cRoomIntegrationData.croom;
        if(cursoIntegracionId && usuarioIntegracionId && eData){
            var rolId = 0;
            for (let index = 0; index < cRoomRolesData.length; index++) {
                const element = cRoomRolesData[index];
                if (element.rolId === eData.rol){
                    switch (element.rol) {
                        case "docente":
                            rolId = 3
                            break;
                        case "estudiante":
                            rolId = 5;
                            break;
                        default:
                            break;
                    }
                }                
            }
            if(rolId !== 0){
                console.log("Iniciando integración de inscripcion "+eData.inscripcionId);
                var croom_client = require("moodle-client");
                croom_client.init({
                    wwwroot: croomData.url,
                    token: croomData.usuario,
                    service: croomData.servicio,
                    strictSSL: false
                }).then(client=>{
                    client.call({
                        wsfunction: "enrol_manual_enrol_users",
                        method: "POST",
                        args: {
                            enrolments: [
                                {   
                                    "roleid": rolId,
                                    "userid": usuarioIntegracionId,
                                    "courseid": cursoIntegracionId,
                                    "suspend": (eData.estado ? 0 : 1)
                                }
                            ]
                        }
                    }).then(value=>{
                        if(value !== null){
                            throw new Error(value.message);
                        } else {
                            console.log("success");
                        }
                    }).catch(error=>{
                        console.log(error);
                    })
                }).catch(err=>{
                    console.log(err);
                })
            } else {
                console.log("Rol invalido, no se puede continuar con la integración de la inscripción");
            }
        } else {
            console.log("Faltan parametros para integrar la inscripción");
        }        
    } else {
        console.log("La integración con croom no se encuentra configurada");
    }
};