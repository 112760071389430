import React, { useEffect, useState } from 'react';
import { Row, Col, Button, } from 'react-bootstrap';
import { Editor } from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

const FormMatriculaEditParagraph = (props) => {

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    
    const handleEnableSave = () =>  {
        const raw_data = convertToRaw(editorState.getCurrentContent());
        return raw_data.blocks[0].text.length > 3;
    };

    const handleSave = () => {
        const elementEdited = props.elementToEdit;
        const raw_data = convertToRaw(editorState.getCurrentContent());
        const msg_html = draftToHtml(raw_data);
        elementEdited.text = msg_html;
        props.onComplete(elementEdited);
    }

    const handleEditorStateChange = (eState) => {
        setEditorState(eState);
    }

    useEffect(()=>{
        const contentBlock = htmlToDraft(props.elementToEdit.text);
        if(contentBlock){
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const newEditorState = EditorState.createWithContent(contentState);
            setEditorState(newEditorState);
        }
    },[props.elementToEdit]);

    return (
        <div>
            <Row>
                <Col>
                    <Editor 
                        editorState={editorState}
                        wrapperClassName="editor-border"
                        editorClassName="text-editor"
                        onEditorStateChange={estate=>handleEditorStateChange(estate)}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'history'],
                            inline: {
                                options: ['bold', 'italic', 'underline']
                            }
                        }}
                    />
                </Col>
            </Row>
            <hr></hr>
            <Row>
                <Col className='mt-3 text-right'>
                    <Button 
                        onClick={()=>handleSave()} 
                        disabled={!handleEnableSave()} 
                        variant='outline-primary'
                    >Guardar</Button>
                </Col>
            </Row>
        </div>
    )
};

export default FormMatriculaEditParagraph;