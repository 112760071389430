import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import LMSAccess from "../img/LogoCroom.png";
import vroomAccess from "../img/LogoVroom.png";
import TestAccess from "../img/LogoGrader.png";
import MatriculasAccess from "../img/LogoMatriculas.png";
import { connect } from "react-redux"
import API from "@aws-amplify/api";
import { onError } from "../libs/errorLib";
import { useHistory } from "react-router-dom";

const mapStateToProps = (state, props) => ({
    userData: state.userAuthenticated,
    integraciones: state.integraciones,
    setIsLoading: props.setIsLoading,
    institucionData: state.institucionData,
});

const mapDispatchToProps = dispatch => ({
    setConfigMatriculas(configMatriculas){
        dispatch({
            type: "SET_MATRICULAS_CONFIG",
            configMatriculas
        })
    }
});

const QuickAccess = ({userData, integraciones, setIsLoading, institucionData, setConfigMatriculas}) => {

    const [matriculasState, setMatriculasState] = useState(false);

    const isFirstRender = useRef(true);
    const matriculasConfig = useRef({});

    const history = useHistory();

    const goToCroom = async() => {
        console.log("go to croom");
        setIsLoading(true);
        try{
            var croom_go = require("moodle-client");
            const client = await croom_go.init({
                wwwroot: integraciones.croom.url,
                token: integraciones.croom.usuario,
                service: integraciones.croom.servicio,
                strictSSL: false
            });
            const result = await client.call({
                wsfunction: "auth_userkey_request_login_url",
                method: "POST",
                args: {
                    user: {
                        "username": userData.email
                    }
                }
            });
            if(result.exception){
                console.log(result);
                throw new Error ("SsoException");
            } else {
                console.log(result);
                window.location.href = result.loginurl;
            }
            setIsLoading(false);
        } catch (error){
            console.log(error);
            setIsLoading(false);
        }
    };

    /*const testApiMoodleGetCourseActivities = async() => {
        console.log("calling client");
        try{
            var rq = require("moodle-client");
            const client = await rq.init({
                wwwroot: 'http://localhost:8000/webservice/rest/server.php',
                token: 'a270939b00354cab700d1dbe2dc089d4',
                service: 'getcourseactivities',
                strictSSL: false
            });
            const result = await client.call({
                wsfunction: "local_getcourseactivities_get_activities",
                method: "POST",
                args: {
                    course: {
                        "courseid": 2
                    }
                }
            });
            if(result.exception){
                console.log(result);
                throw new Error ("SsoException");
            } else {
                console.log(result);
            }
        } catch (error){
            console.log(error);
        }
    }*/

    const testApiMoodleGetGrades = async() => {
        /*console.log("calling client");
        try{
            var rq = require("moodle-client");
            const client = await rq.init({
                wwwroot: 'http://localhost:8000/webservice/rest/server.php',
                token: 'e6a608bcedf7bfbf42dc4ecfdf0bd3b0',
                service: 'getgrades',
                strictSSL: false
            });
            const result = await client.call({
                wsfunction: "local_get_grades",
                method: "POST",
                args: {
                    course: {
                        "courseid": 2,
                        "modname": "forum",
                        "iteminstance": 2,
                        "userid": 3
                    }
                }
            });
            console.log(result);
        } catch (error){
            console.log(error);
        }*/
    };

    const handleMatriculas = () => {
        setConfigMatriculas(matriculasConfig.current);
        history.push("/matriculas");
    };

    const getMatriculaState = useCallback(async()=>{
        try {
            const qsp = {
                get: ['matriculas']
            };
            const response = await API.get('rocket','/config/'+userData.institucionId,{
                queryStringParameters: qsp,
            });
            if(response.result === 'fail'){
                onError(response);
                const err = 'GetMatriculasConfigException';
                throw (err);
            } else {
                setMatriculasState(response.description.matriculas.activas);
                if(response.description.matriculas.activas){
                    matriculasConfig.current = response.description.matriculas;
                }
            }
        } catch (error) {
            onError(error);
        }
    },[userData])

    useEffect(()=>{

        if(isFirstRender.current){
            isFirstRender.current = false;
            if(userData.rolName === 'estudiante' && !isFirstRender.current){
                getMatriculaState();
            }
        }
    },[getMatriculaState, userData]);
    
    return(
        <div>
            <Row className="justify-content-center">
                {userData.rolName !== "financiero" && userData.rolName !== "coordinador" ? 
                    <Col xs={6} sm={5} md={5} lg={4} xl={3}>
                        <Card >
                            <Button className="text-white" variant="secondary" onClick={()=>goToCroom()} >
                                <img alt="croom" src={LMSAccess} style={{width: "80%"}}/>
                                    <Card.ImgOverlay className="pt-2">
                                        <Card.Body >
                                            <Card.Title>Croom</Card.Title>
                                            <Card.Text>
                                                Ir a tus cursos
                                            </Card.Text>
                                        </Card.Body>                
                                    </Card.ImgOverlay>
                            </Button>
                        </Card>
                    </Col>
                :
                    <div></div>
                }
                {userData.rolName !== "estudiante" ?
                    <Col xs={6} sm={5} md={5} lg={4} xl={3}>
                        <Card >
                            <Button className="text-white" variant="secondary" onClick={/*()=>console.log("test")*/ ()=>testApiMoodleGetGrades()} >
                                <img alt="vroom" src={vroomAccess} style={{width: "80%"}}/>
                                    <Card.ImgOverlay className="pt-2">
                                        <Card.Body >
                                            <Card.Title>Vroom</Card.Title>
                                            <Card.Text>
                                                Ir a tu sala virtual
                                            </Card.Text>
                                        </Card.Body>                
                                    </Card.ImgOverlay>
                            </Button>
                        </Card>
                    </Col>
                :   
                    <div></div>
                }
                {userData.rolName === "docente" ? 
                    <Col xs={6} sm={5} md={5} lg={4} xl={3}>
                        <Card>
                            <Button className="text-white" variant="secondary" onClick={()=>console.log("test")} >
                                <img alt="grader" src={TestAccess} style={{width: "80%"}}/>
                                    <Card.ImgOverlay className="pt-2">
                                        <Card.Body >
                                            <Card.Title>Grader</Card.Title>
                                            <Card.Text>
                                                Ir a calificar
                                            </Card.Text>
                                        </Card.Body>                
                                    </Card.ImgOverlay>
                            </Button>
                        </Card>
                    </Col>
                :
                    <div></div>
                }
                {matriculasState && 
                    <Col xs={6} sm={5} md={5} lg={4} xl={3}>
                        <Card>
                            <Button  variant="secondary" onClick={()=>handleMatriculas()} >
                                <img alt="matriculas" src={MatriculasAccess} style={{width: "80%"}}/>
                                    <Card.ImgOverlay className="pt-2">
                                        <Card.Body >
                                            <Card.Title>¡Matriculas abiertas!</Card.Title>
                                        </Card.Body>                
                                    </Card.ImgOverlay>
                            </Button>
                        </Card>
                    </Col>
                }
            </Row>
            <Row>
                <Col>
                    <hr></hr>
                </Col>
            </Row>
        </div>
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(QuickAccess);