import API from "@aws-amplify/api";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row, Card, Button, Modal, Spinner, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import { AiOutlineEye, AiOutlineEyeInvisible, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { IoMdRefresh, IoMdAdd } from "react-icons/io";
import { FixedSizeList as List2 } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { Editor } from "react-draft-wysiwyg";
import CrearAnuncio from "../components/crearAnuncio";

const MapStateToProps = state => ({
    userData: state.userAuthenticated
});

const MapDispatchToProps = dispatch => ({

});

const Home = ({userData}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [showModalAnuncio, setShowModalAnuncio] = useState(false);
    const isFirstRender = useRef(true);
    const anuncios_institucion = useRef([]);
    const dummyInd = useRef(false);
    const modalAnuncioTitle = useRef("");
    const modalAnuncioBody = useRef("");

    function timeConverter(UNIX_timestamp){
        var a = new Date(UNIX_timestamp);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var time = date + ' ' + month + ' ' + year + '  ' + hour + ':' + min ;
        return time;
      }

    const dummyAnouncementData = [
        {
            titulo: "No hay anuncios!",
            anuncio: "Aún no hay anuncios institucionales publicados. Sin embargo, pronto podrian haber, vuelve pronto!",
            anuncioId: "123-abcd",
            fecha: timeConverter(Date.now()),
            estado: true
        }
    ];

    const prepareAnnouncement = useCallback(async(anuncios)=>{
        for (let index = 0; index < anuncios.length; index++) {
            const element = anuncios[index];
            if(element.estado){
                const raw_data = element.textoAnuncio;
                const nous_data = {
                    titulo: element.tituloAnuncio,
                    anuncio: raw_data,
                    anuncioId: element.anuncioId,
                    fecha: element.fecha,
                    estado: element.estado
                };
                anuncios_institucion.current.push(nous_data);
            } else {
                if(userData.rolName === "coordinador" || userData.rolName === "manager"){
                    const raw_data = element.textoAnuncio;
                    const nous_data = {
                        titulo: element.tituloAnuncio,
                        anuncio: raw_data,
                        anuncioId: element.anuncioId,
                        fecha: element.fecha,
                        estado: element.estado,
                    };
                    anuncios_institucion.current.push(nous_data);
                }
            }
        }
    },[userData]);

    const getAnunciosInstitucionales = useCallback(async()=>{
        const qsp = {
            institucionId: userData.institucionId
        };
        API.get("rocket","/getAnuncios",{
            queryStringParameters: qsp
        }).then(async(value)=>{
            if(value.result==="success"){
                isFirstRender.current = false;
                await prepareAnnouncement(value.description);
                setIsLoading(false)
            } else {
                console.log(value);
                //ponerDummyData
                anuncios_institucion.current = dummyAnouncementData;
                dummyInd.current = true;
                setIsLoading(false)
            }
        })
    },[userData, dummyAnouncementData, prepareAnnouncement])

    useEffect(()=>{
        if(isFirstRender.current){
            getAnunciosInstitucionales();
        }
    },[getAnunciosInstitucionales])

    const renderCard = (btn_edit, btn_showHide, btn_delete, titulo, anuncio, fecha, style) => {
        if(dummyInd.current){
            return (
                <div style={style}>
                    <Card>
                        <Card.Header className="capitalize">
                            {dummyAnouncementData[0].titulo}
                        </Card.Header>
                        <Card.Body>
                            {dummyAnouncementData[0].anuncio}     
                        </Card.Body>
                    </Card>
                </div>
            )
        } else {
            return (
                <div style={style}>
                    <Card>
                        <Card.Header className="capitalize">
                            <Row>
                                <Col>{titulo}</Col>
                                <Col xs={"auto"}>
                                    <Row>
                                        <Col className="text-right" xs={"auto"} style={{paddingRight: "0px"}}>
                                            {btn_edit}
                                        </Col>
                                        <Col className="text-right" xs={"auto"} style={{paddingRight: "0px"}}>
                                            {btn_showHide}
                                        </Col>
                                        <Col className="text-right" xs={"auto"}>
                                            {btn_delete}
                                        </Col>
                                    </Row>
                                </Col>    
                            </Row>
                        </Card.Header>
                        <Card.Body>
                            <Editor 
                                toolbarHidden
                                initialContentState={anuncio}
                                readOnly
                                editorClassName="editor-anuncios-institucion"
                            />     
                            <footer className="text-right blockquote-footer">
                                <cite title="Publicado en">{fecha}</cite>
                            </footer>                   
                        </Card.Body>
                    </Card>
                </div>
            )
        }
    }

    const handleEstadoAnuncio = async(anuncio_Data) => {
        if(anuncio_Data){
            setIsLoading(true);
            anuncios_institucion.current = [];
            const anon_data = {
                institucionId: userData.institucionId,
                propiedades: [
                    {
                        propiedad: "estado",
                        value: !anuncio_Data.estado
                    }
                ]
            };
            try{
                const response = await API.put("rocket", "/anuncios/"+anuncio_Data.anuncioId, {
                    body: anon_data
                });
                if (response.result === "success"){
                    getAnunciosInstitucionales();
                } else {
                    console.log(response);
                    setIsLoading(false);
                }
            } catch (error) {
                console.log("Error actualizando anuncio");
                console.log(error);
                setIsLoading(false);
            }
        } else {
            console.log("No hay información de anuncio a actualizar");
        }
    }

    const handleEliminaAnuncio = async(anuncio_data) => {
        if(anuncio_data){
            setIsLoading(true);
            anuncios_institucion.current = [];
            const anon_del_data = {
                institucionId: userData.institucionId
            };
            try {
                const response = await API.del("rocket","/anuncios/"+anuncio_data.anuncioId,{
                    body: anon_del_data
                });
                if (response.result === "success"){
                    getAnunciosInstitucionales();
                } else {
                    console.log(response);
                    setIsLoading(false);
                }
            } catch(error){
                console.log("Error eliminando anuncio");
                console.log(error);
                setIsLoading(false);
            }
        } else {
            console.log("No hay información de anuncio a eliminar");
        }
    }

    const handleEditarAnuncio = (data_anuncio) => {
        modalAnuncioTitle.current = "Editar anuncio"
        modalAnuncioBody.current = <CrearAnuncio anuncio_data={data_anuncio} onFinish={setShowModalAnuncio}/>
        setShowModalAnuncio(true);
    }

    const refreshAnuncios = () => {
        anuncios_institucion.current = [];
        setIsLoading(true);
        getAnunciosInstitucionales();
    }

    const anuncioToRender = ({index, style}) => {
        const estado = anuncios_institucion.current[index].estado;
        var btn_edit = <div></div>;
        var btn_showHide = <div></div>;
        var btn_delete = <div></div>;
        if(userData.rolName === "coordinador" || userData.rolName === "manager"){
            btn_edit = <Button onClick={()=>handleEditarAnuncio(anuncios_institucion.current[index])} className="action-button" variant="light"><AiOutlineEdit /></Button>;
            btn_delete =
            <Dropdown>
                <Dropdown.Toggle className="action-button" title="Eliminar anuncio" variant="ligh" id="dropdown-delete">
                    <AiOutlineDelete />
                </Dropdown.Toggle>
            
                <Dropdown.Menu>
                    <Dropdown.Item >¿Seguro?</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={()=>handleEliminaAnuncio(anuncios_institucion.current[index])}>Si, elimina este anuncio</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>;
            switch (estado) {
                case true:
                    btn_showHide = <Button onClick={()=>handleEstadoAnuncio(anuncios_institucion.current[index])} className="action-button" variant="light"><AiOutlineEyeInvisible /></Button>
                    break;
                case false:
                    btn_showHide = <Button onClick={()=>handleEstadoAnuncio(anuncios_institucion.current[index])} className="action-button" variant="light"><AiOutlineEye /></Button>
                    break;
                default:
                    break;
            }
        }
        const titulo = estado ? anuncios_institucion.current[index].titulo : anuncios_institucion.current[index].titulo + " - Anuncio Oculto -";
        const anuncio = anuncios_institucion.current[index].anuncio;
        const fecha = timeConverter(anuncios_institucion.current[index].fecha);
        if(!estado){
            if(userData.rolName === "coordinador" || userData.rolName === "manager"){
                return (renderCard(btn_edit, btn_showHide, btn_delete, titulo, anuncio, fecha, style));
            } else {
                return (<div></div>)
            }
        } else {
            return (renderCard(btn_edit, btn_showHide, btn_delete, titulo, anuncio, fecha, style));
        }
    }

    const handleCloseModalAnuncio = () => {
        modalAnuncioTitle.current = "";
        modalAnuncioBody.current = "";
        setShowModalAnuncio(false);
    }

    const handleCrearAnuncio = () => {
        modalAnuncioTitle.current = "Nuevo anuncio institucional"
        modalAnuncioBody.current = <CrearAnuncio onFinish={setShowModalAnuncio}/>
        setShowModalAnuncio(true);
    }
    
    return (
        <div>
            <Modal 
                show={showModalAnuncio}
                onHide={()=>handleCloseModalAnuncio()}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modalAnuncioTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalAnuncioBody.current}
                </Modal.Body>
            </Modal>
            {isLoading ? 
                <Row>
                    <Col className="text-center">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <div>
                    <Row>
                        <Col xs={6}>
                            <h2>Anuncios institucionales</h2>
                        </Col>
                        {userData.rolName === "coordinador" || userData.rolName === "manager" ?
                             <Col className="text-right">
                                <Button onClick={()=>handleCrearAnuncio()} variant="outline-primary" className="mr-1"><IoMdAdd /></Button>
                                <Button onClick={()=>refreshAnuncios()} variant="outline-primary"><IoMdRefresh /></Button>
                            </Col>
                        :
                            <div></div>
                        }
                       
                    </Row>
                    <Row>
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row style={{height: 350}}>
                        <Col>
                            <AutoSizer>
                                {({height, width})=>(
                                    <List2
                                        height={height}
                                        width={width}
                                        itemCount={anuncios_institucion.current.length}
                                        itemSize={350}
                                    >
                                        {anuncioToRender}
                                    </List2>
                                )}
                            </AutoSizer>
                        </Col>
                    </Row>
                </div>
            }
        </div>
    );
}

export default connect(MapStateToProps,MapDispatchToProps)(Home);