import React, { useState } from "react";
import { Alert, Modal, Row, Col, InputGroup, FormControl, Form } from "react-bootstrap";
import { API } from 'aws-amplify';
import LoaderButton from "./loaderButton";

const ModalSetPassword = ({
    setShow,
    show = false,
    elementData,
    buttonType = "success",
    object,
    ...props
}) => {
    const handleClose = () => {
        blank();
        setIsLoading(false);
        setShow(false);
    }
    //console.log(elementData);
    const [isLoading, setIsLoading] = useState(false);
    const [clave, setClave] = useState('');
    const [cClave, setCclave] = useState('');
    const [newPassInNextLogin, setNewPassInNextLogin] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [error, setError] = useState(null);

    const handleEnableButton = () => {
        return clave.length > 6 && (clave === cClave)
    };

    const blank = () => {
        setClave('');
        setCclave('')
        setShowAlert(false);
        setError(null);
    };
    
    const cambiarClave = async() => {
        setIsLoading(true);
        setShowAlert(false);
        try {
            const data = {
                institucionId: elementData.institucionId,
                usuarioId: elementData.usuarioId,
                clave: clave
            };
            const response = await API.post('rocket','/cambiarClave',{
                body: data
            });
            if (response.result === 'fail'){
                console.log(response);
                setError(response.description);
                setShowAlert(true);
                setIsLoading(false);
            } else {
                console.log('Clave actualizada correctamente');
                handleClose(false);
            }            
        } catch (error) {
            console.log(error);
            handleClose(false);
        }
    };

    return (
        <Modal 
            show={show}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>Cambiar clave</Modal.Title>
            </Modal.Header>

            <Modal.Body>
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="clave">Clave</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Contraseña"
                            type='password'
                            aria-label="clave"
                            aria-describedby="clave"
                            value={clave}
                            onChange={e=>setClave(e.target.value)}
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                            <InputGroup.Text id="cclave">Confirmar clave</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            placeholder="Contraseña"
                            type='password'
                            aria-label="cclave"
                            aria-describedby="cclave"
                            value={cClave}
                            onChange={e=>setCclave(e.target.value)}
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group >
                        <Form.Check 
                            type="checkbox" 
                            label="¿Solicitar cambio de clave al usuario en su próximo inicio de sesión?" 
                            checked={newPassInNextLogin}
                            value={newPassInNextLogin}
                            onChange={()=>setNewPassInNextLogin(!newPassInNextLogin)}/>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Alert variant={'warning'}>
                        La contraseña debe tener mínimo 8 caracteres, debe incluir numeros, letras mayúsculas, minúsculas y caracteres especiales (*,#)
                    </Alert>
                </Col>
            </Row>
            {showAlert && 
                <Row>
                    <Col>
                        <Alert variant={'danger'}>
                            {error}
                        </Alert>
                    </Col>
                </Row>
            }
            </Modal.Body>

            <Modal.Footer>
                <LoaderButton 
                    isLoading={isLoading}
                    variant={buttonType}
                    disabled={!handleEnableButton()}
                    onClick={()=>cambiarClave()}
                >
                    Cambiar clave
                </LoaderButton>
            </Modal.Footer>
            {props.children}
        </Modal>
    )
}

export default ModalSetPassword;
