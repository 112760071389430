import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col, Spinner, InputGroup, Modal, Card, ButtonGroup, Button, Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { connect } from "react-redux";
import { API } from "aws-amplify";
import { useHistory } from "react-router";
import RenderCurso from "../components/renderCurso";
import { MdErrorOutline } from "react-icons/md";
import { GiSpellBook, GiBookCover, GiBookshelf, GiWhiteBook, GiBookPile , GiOpenBook, GiBlackBook, GiBookmark } from "react-icons/gi";
import { GrDocumentConfig } from "react-icons/gr";
import CrearAnuncio from "../components/crearAnuncio";
import LoadingModal from "../components/loadingModal";
import ExecReport from "../components/exectReport";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const mapStateToProps = (state, props) => ({
    userData: state.userAuthenticated,
    integraciones: state.integraciones,
    props: props
});

const mapDispatchToProps = dispatch => ({});

const TodosLosCursos = ({userData, props, integraciones}) => {
    const bootstrapTableMsg = useRef("No hay cursos");
    const [isLoading, setIsLoading] = useState(true);
    const msgModal = useRef("");
    const alertModalTitle = useRef(<MdErrorOutline />);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showModalAnuncio, setShowModalAnuncio] = useState(false);
    const [showLM, setShowLM] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [reportData, setReportData] = useState({});
    const [showConfigReportModal, setShowConfigReportModal] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const history = useHistory();
    const modalAnuncioTitle = useRef("");
    const modalAnuncioBody = useRef("");
    const reportProps = useRef({});
    const configReportModalTitle = useRef(<GrDocumentConfig />);
    const showPeriodo = useRef(false);
    const reportId = useRef("");
    const periodoSelected = useRef(null);
    const cursoSelected = useRef(null);
    const cursoSelectedNombre = useRef("");

    const courseIcons = useRef([
        <GiSpellBook size={60}/>,<GiBookCover size={60}/>,<GiBookshelf size={60}/>,
        <GiWhiteBook size={60}/>,<GiBookPile size={60}/>, <GiOpenBook size={60}/>, 
        <GiBlackBook size={60}/>, <GiBookmark size={60}/>
    ])
    const columns = useRef([
        {
            dataField: "action",
            text: "Cursos",
            headerStyle: () => {
                return { width: '30%' }
            }
        },
        {
            dataField: "curso",
            text: "Anuncios",
        }
    ]);

    const tableData = useRef([]);

    const handleErrors = (exception) => {
        switch (exception.code) {
            case "GetCoursesException":
                msgModal.current = "No es posible recuperar los cursos de la institución, por favor intentalo de nuevo, si el problema persiste comunicate con nosotros.";
                break;
            default:
                msgModal.current = "Error en la aplicación, intentalo de nuevo, si el problema persiste comunicate con nosotros.";
                break;
        }
        setShowAlertModal(true);
    }

    const goToCroomCourse = useCallback(async(id) => {
        console.log("go to croom course");
        setIsLoading(true);
        try{
            var croom_go = require("moodle-client");
            const client = await croom_go.init({
                wwwroot: integraciones.croom.url,
                token: integraciones.croom.usuario,
                service: integraciones.croom.servicio,
                strictSSL: false
            });
            const result = await client.call({
                wsfunction: "auth_userkey_request_login_url",
                method: "POST",
                args: {
                    user: {
                        "username": userData.email
                    }
                }
            });
            if(result.exception){
                console.log(result);
                throw new Error ("SsoException");
            } else {
                console.log(result);
                const url = result.loginurl.split("auth/");
                const courseAccess = result.loginurl + "&wantsurl=" + url[0] + "course/view.php?id="+id;
                window.location.href = courseAccess;
            }
            setIsLoading(false);
        } catch (error){
            console.log(error);
            setIsLoading(false);
        }
    },[integraciones, userData]);

    useEffect(()=>{
        if(!showReport){
            if(showLM){
                setShowReport(true);
            }
        }
    },[showReport, showLM])

    const handleReport = (repId, cursoId, cursoNombre) => {
        setShowReport(false);
        switch (repId) {
            case "2021B01":
                //Reporte Asistencia
                reportId.current = repId;
                cursoSelected.current = cursoId;
                cursoSelectedNombre.current = cursoNombre;
                showPeriodo.current = true;
                setShowConfigReportModal(true);
                break;
            case "2021B03":
                //reporte estudiantes
                reportProps.current = {
                    reportId: repId,
                    onLoad: setShowLM,
                    cursoId: cursoId,
                    cursoNombre: cursoNombre
                };
                setReportData(reportProps.current);
                setShowLM(true);
                reportProps.current = {};
                break;
            default:
                break;
        }
    };

    const handleCrearAnuncio = (cursoData) => {
        modalAnuncioTitle.current = ["Nuevo anuncio | ", <span key="123x" className="capitalize">{cursoData.nombre}</span>]
        modalAnuncioBody.current = <CrearAnuncio cursoId={cursoData.cursoId} onFinish={setShowModalAnuncio}/>
        setShowModalAnuncio(true);
    }

    useEffect(()=>{
        if(userData.rolName === "coordinador" || userData.rolName === "manager"){
            API.get("rocket", "/getCursos",{
                "queryStringParameters": {
                    institucionId: userData.institucionId,
                    index: "-1"
                }
            }).then(response => {
                if(response.result === "success"){
                    const curso = response.description;
                    for (let index = 0; index < curso.length; index++) {
                        const element = curso[index];
                        if(element.status){
                            const courseButton = 
                                <Card className="text-center">
                                    <Card.Header className="capitalize">{element.nombre}</Card.Header>
                                    <Card.Body>
                                        <Card.Title>{courseIcons.current[Math.floor(Math.random() * 8)]}</Card.Title>
                                        <Dropdown as={ButtonGroup}>
                                            <Button onClick={()=>goToCroomCourse(element.integracionId)} variant="link">Entrar</Button>
                                            <Dropdown.Toggle split variant="outline-link" id="dropdown-split" />
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={()=>handleReport("2021B01", element.cursoId, element.nombre)}>{"Asistencia"}</Dropdown.Item>
                                                <Dropdown.Item onClick={()=>handleCrearAnuncio(element)}>Crear anuncio</Dropdown.Item>
                                                <Dropdown.Item onClick={()=>handleReport("2021B03", element.cursoId, element.nombre)}>Estudiantes</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Card.Body>
                                </Card>
                            const cData = {
                                cursoId: element.cursoId,
                                action: courseButton,
                                internalId: element.internalId,
                                nombre: element.nombre,
                                curso: <RenderCurso institucionId={userData.institucionId} cursoId={element.cursoId} cursoNombre={element.nombre} rol={userData.rolName}/>
                            };
                            tableData.current.push(cData);
                        }                        
                    }
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    console.log(response);
                }
            }).catch(error=>{
                const err = {
                    code: "GetCoursesException",
                    description: error
                };
                setIsLoading(false);
                handleErrors(err);
            })
        } else {
            history.push("/");
        }
    },[userData, history, props, goToCroomCourse])

    const handleCloseAlertModal = () => {setShowAlertModal(false);}

    const handleCloseModalAnuncio = () => {
        modalAnuncioTitle.current = "";
        modalAnuncioBody.current = "";
        setShowModalAnuncio(false);
    }

    const handleSetReport = (data, param) => {
        switch (param) {
            case "periodo":
                periodoSelected.current = data;
                break;
            default:
                break;
        }

        switch (reportId.current) {
            case "2021B01":
                //Reporte Asistencia
                if(periodoSelected.current){
                    reportProps.current = {
                        reportId: reportId.current,
                        onLoad: setShowLM,
                        periodo: periodoSelected.current,
                        cursoId: cursoSelected.current,
                        cursoNombre: cursoSelectedNombre.current,
                    };
                    setReportData(reportProps.current);
                    handleCloseConfigReportModal();
                    setShowLM(true);
                }
                break;
            default:
                break;
        }
    };


    const handleCloseConfigReportModal = () => {
        setShowConfigReportModal(false);
        periodoSelected.current = null;
        cursoSelected.current = null;
        setStartDate(null);
        showPeriodo.current = false;
        reportId.current = "";
        cursoSelectedNombre.current = "";
        reportProps.current = {};
    };

    return (
        <div>
            <Modal 
                show={showConfigReportModal}
                onHide={()=>handleCloseConfigReportModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{configReportModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showPeriodo.current && 
                    <Row className="justify-content-center">
                        <Col xs={"auto"}>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="nombre-acudiente">Periodo</InputGroup.Text>
                                </InputGroup.Prepend>
                                <DatePicker
                                    className="form-control text-center"
                                    selected = {startDate}
                                    dateFormat="MM/yyyy"
                                    onChange={date => {setStartDate(date);handleSetReport(date, "periodo")}}
                                    showMonthYearPicker
                                />  
                            </InputGroup>
                        </Col>
                    </Row>}
                </Modal.Body>
            </Modal>
            <LoadingModal showLoadingModal={showLM}/>
            <Modal 
                show={showModalAnuncio}
                onHide={()=>handleCloseModalAnuncio()}
                size="lg"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{modalAnuncioTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalAnuncioBody.current}
                </Modal.Body>
            </Modal>
            <Modal 
                show={showAlertModal}
                onHide={()=>handleCloseAlertModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-5 pt-5">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <div>
                    <Row>
                        <Col>
                            <BootstrapTable
                                keyField={"cursoId"}
                                data={tableData.current}
                                columns={columns.current}
                                pagination={paginationFactory({sizePerPage: 10})}
                                noDataIndication={bootstrapTableMsg.current}
                                bordered={ false }
                            />
                        </Col>
                    </Row>
                    { showReport && <ExecReport reportProps={reportData} >
                            
                            
                        </ExecReport>}
                </div>
            }

        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TodosLosCursos);