import React, { useState, useRef, useEffect, forwardRef } from "react";
import { Row, Col, Modal, Spinner, InputGroup, FormControl, Form, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { IoMdAdd, IoMdRemove, IoMdCloseCircleOutline } from "react-icons/io";
import { MdErrorOutline } from "react-icons/md";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { API } from "aws-amplify";
import LoaderButton from "../components/loaderButton";

const mapStateToProps = state => ({
    userData: state.userAuthenticated,
    rolesData: state.rolesData
});

const mapDispatchToProps = dispatch => ({});

const AsignarObligaciones = ({userData, rolesData}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSearching, setIsSearching] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [startDate, setStartDate] = useState([new Date()]);
    const [descripcion, setDescripcion] = useState("");
    const [costo, setCosto] = useState(0);
    const [filtroBusqueda, setFiltroBusqueda] = useState("1");
    const [searchCriteria, setSearchCriteria] = useState("");
    const [tableData, setTableData] = useState([]);
    const [obligacionAssign, setObligacionAssign] = useState([]);
    const [textButton, setTextButton] = useState("Guardar");
    const [obAssData, setObAssData] = useState([]);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const rolEstudianteId = useRef("");
    const alertModalTitle = useRef(<MdErrorOutline />);
    const msgModal = useRef("");
    const history = useHistory();
    const bootstrapTableMsg = "No se encontrarón estudiantes";

    const columns = useRef([
        {
            dataField: "identificacion", 
            text: "Identificación",
            headerStyle: () => {
                return { width: '20%' }
            }
        },
        {
            dataField: "estudiante", 
            text: "Estudiante",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        }
    ]);

    const DatePickerCustom = forwardRef(
        ({ value, onClick }, ref) => (
          <Button variant="outline-success" onClick={onClick} ref={ref}>
            {value}
          </Button>
        ),
    );

    const updDate = (date, index) => {
        const dates = [...startDate];
        dates[index] = date;
        setStartDate(dates);
    }
    
    useEffect(()=>{
        if(userData.rolName === "financiero" || userData.rolName === "manager"){
            for (let index = 0; index < rolesData.length; index++) {
                const element = rolesData[index];
                switch (element.rol) {
                    case "estudiante":
                        rolEstudianteId.current = element.rolId;
                        break;
                    default:
                        break;
                }
            }
            setIsLoading(false);
        } else {
            history.push("/");
        }
    },[userData, history, rolesData])

    const handleAddDate = () => {
        const dates = [...startDate];
        var addYear = 0;
        var addMonth = 2;
        if((dates[dates.length - 1].getMonth()) === 11){
            addMonth  = -10
            addYear = 1
        } 
        const addDate = Date.parse((dates[dates.length - 1].getMonth() + addMonth)+"/"+dates[dates.length - 1].getDate()+"/"+(dates[dates.length - 1].getFullYear() + addYear));
        dates.push(new Date(addDate));
        setStartDate(dates);
    }

    const handleRemoveDate = () => {
        const dates = [...startDate];
        dates.pop();
        setStartDate(dates);
    }

    const handleSearch = async() => {
        setIsSearching(true);
        setTableData([]);
        try{
            const response = await API.get("rocket", "/getUsers", {
                "queryStringParameters": {
                    institucionId: userData.institucionId,
                    filtroBusqueda: filtroBusqueda,
                    searchCriteria: searchCriteria.trim().toLowerCase()
                }
            });
            if(response.result === "success"){
                const tableDataTemp = [];
                for (let index = 0; index < response.description.length; index++) {
                    const element = response.description[index];
                    if(element.rol === rolEstudianteId.current){
                        const studentData = {
                            usuarioId: element.usuarioId,
                            identificacion: element.identificacion,
                            estudiante: [element.nombre + " " + element.apellido, <span className="to-lower-case form-text" key="ft787">{element.email}</span>],
                            nombre: element.nombre,
                            apellido: element.apellido
                        }
                        tableDataTemp.push(studentData);
                    }
                }
                setTableData(tableDataTemp);
                setIsSearching(false);
            } else {
                console.log(response);
                setIsSearching(false);
            }
        } catch(error){
            console.log(error);
            setIsSearching(false);
        }
    }

    const handleEnabled = () =>{
        switch (filtroBusqueda) {
            case "3":
                return searchCriteria.includes("@");
            default:
                return searchCriteria.length > 3;
        }
    }

    const validarCreacion = () => {
        return descripcion.length > 3 &&
        costo > 0 &&
        startDate.length > 0 &&
        obligacionAssign.length > 0;
    }

    const handleOnSelect = (row, isSelect) => {
        if(isSelect){
            if(!obligacionAssign.includes(row.usuarioId)){
                const obAss = [...obligacionAssign];
                const obData = [...obAssData]
                const data = {
                    identificacion: row.identificacion,
                    usuarioId: row.usuarioId,
                    nombre: row.nombre,
                    apellido: row.apellido
                }
                obAss.push(row.usuarioId);
                obData.push(data);
                setObligacionAssign(obAss);
                setObAssData(obData);
            }
        }
    }

    const handleQuitAssign = (index) => {
        const obAss = [...obligacionAssign];
        const obData = [...obAssData];
        obAss.splice(index, 1);
        obData.splice(index, 1);
        setObligacionAssign(obAss);
        setObAssData(obData);
    }

    const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleOnSelect
    }

    const blank = () => {
        setDescripcion("");
        setCosto(0);
        setStartDate([new Date()]);
        setSearchCriteria("");
        setTableData([]);
        setObligacionAssign([]);
        setObAssData([]);
    }

    const handleErrors = (exception) => {
        switch (exception.code) {
            case "AsingarObligacionExcepcion":
                msgModal.current = "No fue posible crear esta obligación financiera, intentalo de nuevo, si el problema persiste comunicate con nosotros.";
                break;
            default:
                msgModal.current = "Error en la aplicación, intentalo de nuevo, si el problema persiste comunicate con nosotros.";
                break;
        }
        setShowAlertModal(true);
    }

    const handleCreacion = async() => {
        setIsSaving(true);
        setTextButton("");
        const obligaciones = [];
        for (let index = 0; index < startDate.length; index++) {
            const element = startDate[index];
            const periodo = Date.parse((element.getMonth() + 1)+"/"+1+"/"+element.getFullYear());
            for (let idx = 0; idx < obligacionAssign.length; idx++) {
                const uId = obligacionAssign[idx];
                const data = {
                    periodo: periodo,
                    descripcion: descripcion.trim().toLowerCase(),
                    costo: costo,
                    usuarioId: uId,
                    institucionId: userData.institucionId,
                    nombre: obAssData[idx].nombre,
                    apellido: obAssData[idx].apellido,
                    identificacion: obAssData[idx].identificacion,
                }
                obligaciones.push(data);
            }
        }
        //API CALL
        try{
            const response = await API.post("rocket", "/crearObligacionFinanciera",{
                body: obligaciones
            });
            if(response.result === "success"){
                blank();
            } else {
                console.log(response);
                const error = {
                    code: "AsingarObligacionExcepcion"
                };
                handleErrors(error);
            }
        } catch(error){
            console.log(error);
            const err = {
                code: "ApiRequestExcepcion"
            };
            handleErrors(err);
        }
        setTextButton("Guardar");
        setIsSaving(false);
    }

    const handleCloseAlertModal = () => {
        setShowAlertModal(false);
    }

    return(
        <div>
            <Modal 
                show={showAlertModal}
                onHide={()=>handleCloseAlertModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-5 pt-5">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            :
                <div className="mt-3">
                    <h4>Nueva asignación de obligación financiera</h4>
                    <hr></hr>
                    <Row>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="descripcion-obligacion">Descripción</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    autoFocus
                                    placeholder="Descripcion de esta obligación financiera"
                                    aria-label="descripcion"
                                    aria-describedby="descripcion-obligacion"
                                    value={descripcion}
                                    onChange={e=>setDescripcion(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text id="costo-obligacion">Costo</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    placeholder="Costo de esta obligación"
                                    type="number"
                                    aria-label="costo"
                                    aria-describedby="costo-obligacion"
                                    value={costo}
                                    onChange={e=>setCosto(e.target.value)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>Recurrencia</h4>
                        </Col>
                        <Col xs="auto">
                            {startDate.length > 1 ? <Button className="action-button mr-3" onClick={()=> handleRemoveDate()} variant="link"><IoMdRemove /></Button> : ""}
                            <Button className="action-button mr-2" onClick={()=> handleAddDate()} variant="link"><IoMdAdd /></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row>
                        {startDate.map((value, key)=>{
                            return (
                                <Col className="mt-2" xs="auto" key={key}>
                                    <DatePicker
                                        selected={startDate[key]}
                                        dateFormat="MM/yyyy"
                                        onChange={date => updDate(date, key)}
                                        showMonthYearPicker
                                        customInput={<DatePickerCustom />}
                                    />
                                </Col>
                            )
                        })}
                        
                    </Row>
                    <Row>
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h4>Asignación:</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={"auto"}>
                            <Form.Label className="mr-sm-2" htmlFor="opciones-busqueda" srOnly>
                                Opciones de busqueda
                            </Form.Label>
                            <Form.Control
                                as="select"
                                className="mr-sm-2"
                                id="opciones-busqueda"
                                value={filtroBusqueda}
                                onChange={e=>setFiltroBusqueda(e.target.value)}
                                custom
                            >
                                <option value="1">Nombre</option>
                                <option value="2">Apellido</option>
                                <option value="3">email</option>
                                <option value="4">Identificación</option>
                            </Form.Control>
                        </Col>
                        <Col>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="Criterios de busqueda"
                                    aria-label="Criterios de busqueda"
                                    aria-describedby="basic-addon2"
                                    value={searchCriteria}
                                    onChange={e=>setSearchCriteria(e.target.value)}
                                />
                                <InputGroup.Append>
                                    <Button disabled={!handleEnabled()} onClick={()=>handleSearch()} variant="outline-secondary">Buscar</Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row>
                        {isSearching ? 
                            <Col className="text-center mt-2">
                                <Spinner animation="border" variant="primary" />
                            </Col>
                            
                        :
                            <Col>
                                <BootstrapTable
                                    keyField="usuarioId"
                                    data={tableData}
                                    columns={columns.current}
                                    pagination={paginationFactory(5)}
                                    noDataIndication={bootstrapTableMsg}
                                    selectRow={selectRow}
                                />
                            </Col>
                        }
                    </Row>
                    <Row>
                        {obAssData.map((value, key)=>{
                            const title = value.nombre.charAt(0).toUpperCase() + value.nombre.slice(1) + " " + value.apellido.charAt(0).toUpperCase() + value.apellido.slice(1);
                            const textBtn = [value.identificacion + " ", <IoMdCloseCircleOutline key="abcc" size={25}/>];
                            return (
                                <Col className="mt-2" xs="auto" key={key}>
                                    <Button onClick={()=>handleQuitAssign(key)} variant="outline-success" title={title}>{textBtn}</Button>
                                </Col>
                            )
                        })}
                    </Row>
                    <hr></hr>
                    <Row>
                        <Col className="text-right">
                            <LoaderButton
                                variant="success"
                                onClick={()=>handleCreacion()}
                                isLoading={isSaving}
                                disabled={!validarCreacion()}
                            > {textButton}
                            </LoaderButton>
                        </Col>
                    </Row>
                    <hr></hr>
                </div>
            }
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AsignarObligaciones);