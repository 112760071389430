import React, { useState } from "react";
import { Row, Col, Accordion, Card, Button, InputGroup, FormControl, Modal } from "react-bootstrap";
import { API } from "aws-amplify";
import { connect } from "react-redux";
import LoaderButton from "../components/loaderButton";
import { useRef } from "react";
import { MdErrorOutline } from "react-icons/md";

const mapStateToProps = state => ({
    userData: state.userAuthenticated,
    integraciones: state.integraciones
});

const mapDispatchToProps = dispatch => ({

});

const Integraciones = ({userData, integraciones}) => {
    const [urlCroom, setUrlCroom] = useState((integraciones.croom ? integraciones.croom.url : ""));
    const [usuarioCroom, setUsuarioCroom] = useState((integraciones.croom ? integraciones.croom.usuario: ""));
    const [categoriaCroom, setCategoriaCroom] = useState((integraciones.croom ? integraciones.croom.categoria : ""));
    const [servicioCroom,setServicioCroom] = useState((integraciones.croom ? integraciones.croom.servicio : ""));
    const [urlVroom, setUrlVroom] = useState((integraciones.vroom ? integraciones.vroom.url : ""));
    const [secretVroom, setSecretVroom] = useState((integraciones.vroom ? integraciones.vroom.secret : ""));
    const [textButton, setTextButton] = useState("Guardar");
    const [isLoading, setIsLoading] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const alertModalTitle = useRef(<MdErrorOutline />);
    const msgModal = useRef("");

    var sincro = require("../libs/sincro");

    const handleErrors = (exception) => {
        switch (exception.code) {
            case "UnableSaveException":
                msgModal.current = "Se presento un error guardando la configuración, intentalo de nuevo, si el problema persiste, comunicate con nosotros";
                break;
            case "UnableRetrievingIntegrations":
                msgModal.current = "Se presento un error cargando la configuración, intentalo de nuevo, si el problema persiste, comunicate con nosotros";
                break;
            default:
                break;
        }
        setShowAlertModal(true);
    }

    const handleSaveIntegrationCroom = () => {
        setIsLoading(true);
        setTextButton("");
        const data = {
            value: {
                url: urlCroom,
                usuario: usuarioCroom,
                categoria: categoriaCroom,
                servicio: servicioCroom
            },
            propiedad: "croom"
        }
        API.put("rocket", "/integraciones/"+userData.institucionId, {
            body: data
        }).then((value)=>{
            setIsLoading(false);
            setTextButton("Guardar");
            sincro.sincroIntegraciones();
        }).catch(error=>{
            console.log(error)
            const err = {
                code: "UnableSaveException"
            }
            handleErrors(err)
            setIsLoading(false);
            setTextButton("Guardar");
            sincro.sincroIntegraciones();
        })
    }

    const handleDisabledCroom = () => {
        return urlCroom.includes("https") &&
        usuarioCroom.length > 4 && 
        servicioCroom.length > 2;
    }

    const handleSaveIntegrationVroom = () => {
        setIsLoading(true);
        setTextButton("");
        const data = {
            value: {
                url: urlVroom,
                secret: secretVroom
            },
            propiedad: "vroom"
        }
        API.put("rocket", "/integraciones/"+userData.institucionId, {
            body: data
        }).then((value)=>{
            console.log(value)
            setIsLoading(false);
            setTextButton("Guardar");
            sincro.sincroIntegraciones();
        }).catch(error=>{
            console.log(error)
            const err = {
                code: "UnableSaveException"
            }
            handleErrors(err)
            setIsLoading(false);
            setTextButton("Guardar");
            sincro.sincroIntegraciones();
        })
    }

    const handleDisabledVroom = () => {
        return urlVroom.includes("https") &&
        secretVroom.length > 4;
    }

    return (
        <div>
            
            <Modal 
                show={showAlertModal}
                onHide={()=>setShowAlertModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>
            <Row className='mt-3'>
                <Col>
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    Integración con croom
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="url">URL servidor croom</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl
                                                    placeholder="https://miservidor.gvalor.co/webservice/rest/server.php"
                                                    aria-label="url"
                                                    aria-describedby="url"
                                                    value={urlCroom}
                                                    onChange={e=>setUrlCroom(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="usuario">Usuario</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl
                                                    placeholder="Usuario de integración"
                                                    aria-label="usuario"
                                                    aria-describedby="usuario"
                                                    value={usuarioCroom}
                                                    onChange={e=>setUsuarioCroom(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="categoria">Categoria para los cursos</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl
                                                    placeholder="Código para la categoria de integración de los cursos"
                                                    type="number"
                                                    aria-label="categoria"
                                                    aria-describedby="categoria"
                                                    value={categoriaCroom}
                                                    onChange={e=>setCategoriaCroom(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="servicio">Servicio</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <FormControl
                                                    placeholder="Nombre del servicio de integración"
                                                    aria-label="servicio"
                                                    aria-describedby="servicio"
                                                    value={servicioCroom}
                                                    onChange={e=>setServicioCroom(e.target.value)}
                                                />
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="text-right">
                                            <LoaderButton 
                                                variant="success"
                                                isLoading={isLoading}
                                                disabled={!handleDisabledCroom()}
                                                onClick={()=>handleSaveIntegrationCroom()}
                                                > {textButton}
                                            </LoaderButton>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                    Integración con vroom
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="url">URL servidor vroom</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            placeholder="https://miservidor.gvalor.co/bigbluebutton/"
                                            aria-label="url"
                                            aria-describedby="url"
                                            value={urlVroom}
                                            onChange={e=>setUrlVroom(e.target.value)}
                                        />
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="url">Secret</InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <FormControl
                                            placeholder="Secret de integración"
                                            aria-label="secret"
                                            aria-describedby="secret"
                                            value={secretVroom}
                                            onChange={e=>setSecretVroom(e.target.value)}
                                        />
                                    </InputGroup>
                                    <Row>
                                        <Col className="text-right">
                                            <LoaderButton 
                                                variant="success"
                                                isLoading={isLoading}
                                                disabled={!handleDisabledVroom()}
                                                onClick={()=>handleSaveIntegrationVroom()}
                                                > {textButton}
                                            </LoaderButton>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                </Col>
            </Row>
            <Row>
                <Col>
                    <hr></hr>
                </Col>
            </Row>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Integraciones);