import React from 'react';
import { Container } from "react-bootstrap";
import { Provider } from "react-redux";
import store from "./store";
import './App.css';
import Routes from "./Routes";
import 'react-pro-sidebar/dist/css/styles.css';
import * as Sentry from "@sentry/react";
import errorPic from './img/drop-down.svg';

const FallbackComponent = () => {
  return (
    <div>
      <div className="mt-5 text-center">
        <h3>Ups! se presento un error, prueba recargando la página e intentalo de nuevo</h3>
      </div>
      <div className="mt-5 text-center">
          <img style={{width: 200}} alt='ice cream accident' src={errorPic}></img>
      </div>
    </div>
  )
};

function App() {
  return (
    <Container fluid>
      <Sentry.ErrorBoundary fallback={<FallbackComponent />}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </Sentry.ErrorBoundary>
    </Container>
  )
}

export default App;
