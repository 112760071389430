import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Spinner } from "react-bootstrap";
import Header from "../containers/header";
import { ProSidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent } from "react-pro-sidebar";
import RocketLogo from "../img/apple-touch-icon.png";
import QuickAccess from "./quickAccess";
import Home from "../containers/home";
import NotFound from "../containers/notFound";
import { FaUniversity } from "react-icons/fa";
//import { MdErrorOutline } from "react-icons/md";
import { ImBooks } from "react-icons/im";
import { MdSchool } from "react-icons/md";
import { GoChecklist } from "react-icons/go";
import MisCursos from "./misCursos";
import Calificaciones from "../containers/calificaciones";

const mapStateToProps = state => ({
    userData: state.userAuthenticated
});

const mapDispatchToProps = dispatch => ({});

const Docentes = ({userData}) => {
    const history = useHistory();
    //const msgModal = useRef("");
    //const alertModalTitle = useRef(<MdErrorOutline />);
    /*const [showAlertModal, setShowAlertModal] = useState(false);*/
    const [page, setPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [toggled, setToggled] = useState(false);

    /*const handleErrors = (exception) => {
        switch (exception.code) {
            case "MetodoPagoException":
                msgModal.current = "Tu cuenta no esta habilitada para ingresar a Rocket, por favor ponte en contacto con nosotros.";
                break;
            case "NoExisteUsuarioException":
                msgModal.current = "No se encuentra la información de tu cuenta, no es posible iniciar sesión, por favor ponte en contacto con nosotros.";
                break;
            case "DisabledUserException":
                msgModal.current = "Tu usuario no se encuentra habilitado, comunicate con nosotros.";
                break;
            case "GetInstitutionDataException":
                msgModal.current = "Tu institución no esta configurada para usar Rocket, comunicate con nosotros.";
                break;
            case "GetRolException":
                console.log("No es posible acceder a los roles");
                console.log(exception.description);
                break;
            case "MisplacedException":
                msgModal.current = "Parece que estas en un lugar en el que no deberias estar, vamos a solucionar esto, iniciemos nuevamente la sesión";
                break;
            default:
                msgModal.current = "Error en la aplicación, intentalo de nuevo, si el problema persiste comunicate con nosotros.";
                break;
        }
        setShowAlertModal(true);
    }*/

    /**
     * Verifica que el usuario tenga el rol adecuado para ver esta página
     */
    useEffect(()=>{
        if(userData.rolName === "docente"){
            setIsLoading(false);
        } else {
            history.push("/")
        }
    }, [userData.rolName, history])

    const handlePageChange = () => {
        switch (page) {
            case 0:
                return <Home />
            case 1:
                return <MisCursos setPage={setPage}/>
            case 2: 
                return <Calificaciones />
            default:
                return <NotFound />
        }
    }

    /*const handleCloseAlertModal = () => {
        
    }*/

    return (
        <div>
            {/*<Modal 
                show={showAlertModal}
                onHide={()=>handleCloseAlertModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>*/}
            {isLoading ? 
                <Row>
                    <Col className="text-center mt-5 pt-5">
                        <Spinner animation="border" variant="primary" />
                    </Col>
                </Row>
            : 
                <div>
                    <Row>
                        <Col>
                            <Header setPage={setPage}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={"auto"}>
                        <ProSidebar className="md" toggled={toggled} onToggle={()=>setToggled(false)}>
                            <SidebarHeader>

                            </SidebarHeader>
                            <SidebarContent>
                                <Menu iconShape="circle">
                                    <MenuItem icon={<ImBooks />}><label className="menu-label" onClick={()=>setPage(1)}>Mis cursos</label></MenuItem>
                                    <MenuItem icon={<GoChecklist />}><label className="menu-label" onClick={()=>setPage(2)}>Calificar</label></MenuItem>
                                    <MenuItem icon={<MdSchool />}><label className="menu-label" onClick={()=>setPage(3)}>Observador alumno</label></MenuItem>
                                    <MenuItem icon={<FaUniversity />}><label className="menu-label" onClick={()=>setPage(0)}>Mi institución</label></MenuItem>
                                </Menu>
                            </SidebarContent>
                            <SidebarFooter className="GVIS-Background">
                                <Col className="text-center mt-2 mb-2 rounded">
                                    <img 
                                        alt="logo rocket"
                                        src={RocketLogo}
                                        className="Sidebar-rocket-logo"
                                    ></img>
                                    <span style={{color:"white"}}>&nbsp;Rocket</span>
                                    <Col>
                                        <i className="footer-text">© Grupo Valor International Solutions.</i>
                                    </Col>
                                </Col>
                            </SidebarFooter>
                        </ProSidebar>
                        </Col>
                        <Col className="mt-3">
                            <QuickAccess setIsLoading={setIsLoading}/>
                            {handlePageChange()}
                        </Col>
                    </Row>
                </div>
            }
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Docentes);