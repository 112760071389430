import ReactDOM from "react-dom";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { API } from "aws-amplify";
import { IoMdCheckmark, IoMdClose } from "react-icons/io";

const mapStateToProps = (state, props) => ({
    userData: state.userAuthenticated,
    props: props,
    rolesData: state.rolesData
});

const mapDispatchToProps = dispatch => ({});

const ExecReport = ({userData, props, rolesData}) => {
    const [okForPortal, setOkForPortal] = useState(false);

    const tmpStartDate = useRef(new Date());
    const tableData = useRef([]);
    const tableIngresos = useRef([]);
    const tableAsistencia = useRef([]);
    const tableEstudiantes = useRef([]);
    const tableUsuarios = useRef([]);
    const tableEgresos = useRef([]);
    const totalCartera = useRef(0);
    const totalIngresos = useRef(0);
    const totalEgresos = useRef(0);
    const bootstrapTableMsg = useRef("No hay datos");
    const isFirstRender = useRef(true);
    const totalUsuarios = useRef(0);
    const totalRoles = useRef({
        "estudiante": 0,
        "docente": 0,
        "coordinador": 0,
        "financiero": 0,
        "manager": 0
    });

    const columns = useRef([
        {
            dataField: "periodo", 
            text: "Periodo",
            sort: true
        },
        {
            dataField: "apellido", 
            text: "Apellido",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "nombre", 
            text: "Nombre",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "descripcion", 
            text: "Obligación",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "saldo", 
            text: "Saldo",
            sort: true,
            headerStyle: () => {
                return { textAlign: "right" }
            },
            style: {
                textAlign: "right"
            }
        },
    ]);

    const columnsIngresos = useRef([
        {
            dataField: "descripcion", 
            text: "Descripción",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "ingreso", 
            text: "Ingreso",
            sort: true,
            headerStyle: () => {
                return { textAlign: "right" }
            },
            style: {
                textAlign: "right"
            }
        },
    ]);
    const columnsEgresos = useRef([
        {
            dataField: "descripcion", 
            text: "Descripción",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "egreso", 
            text: "Egreso",
            sort: true,
            headerStyle: () => {
                return { textAlign: "right" }
            },
            style: {
                textAlign: "right"
            }
        },
    ]);

    const columnsAsistencia = useRef([
        {
            dataField: "nombreCompleto", 
            text: "Estudiante",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        }
    ]);

    const columnsEstudiantes = useRef([
        {
            dataField: "identificacion",
            text: "Identificacion",
            sort: true,
        },
        {
            dataField: "apellido", 
            text: "Apellido",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "nombre", 
            text: "Nombre",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "email", 
            text: "email",
            sort: true
        },
    ]);

    const columnsUsuarios = useRef([
        {
            dataField: "identificacion",
            text: "Identificacion",
            sort: true,
        },
        {
            dataField: "email", 
            text: "Usuario",
            sort: true
        },
        {
            dataField: "apellido", 
            text: "Apellido",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "nombre", 
            text: "Nombre",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "rolName", 
            text: "Rol",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "status", 
            text: "Habilitado",
            sort: true,
            style: {
                textAlign: "center"
            }
        },
    ]);

    const columnsRoles = useRef([
        {
            dataField: "rol", 
            text: "Rol",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        },
        {
            dataField: "cantidad", 
            text: "Cantidad",
            sort: true,
            style: {
                textAlign: "center"
            }
        },
    ]);

    const extWin = useRef(null);
    const extWinTitle = useRef("")

    const container = document.createElement('div');
    container.className="container";

    const asistenciaEstudiantes = useRef([])
    const asistenciaFechas = useRef([]);
    const monthAndYear = useRef("");
    const meses = useRef(["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]);

    const getAsistencia = useCallback(async()=>{
        asistenciaEstudiantes.current = [];
        asistenciaFechas.current = [];
        tableAsistencia.current = [];
        columnsAsistencia.current = [{
            dataField: "nombreCompleto", 
            text: "Estudiante",
            sort: true,
            style: {
                textTransform: "capitalize"
            }
        }];
        if(isFirstRender){
            const periodo = props.reportProps.periodo;
            const monthLastDay = new Date(periodo.getFullYear(),(periodo.getMonth()+1),0).getDate();
            const fecha = new Date(periodo);
            monthAndYear.current = meses.current[fecha.getMonth()] + " " + fecha.getFullYear();
            extWinTitle.current = "Reporte asistencia";
            try{
                //Recuperando asistencia para la fecha requerida
                for (let index = 1; index <= monthLastDay; index++) {
                    const date = Date.parse((periodo.getMonth() + 1)+"/"+index+"/"+periodo.getFullYear());
                    const response = await API.get("rocket", "/asistencia/"+props.reportProps.cursoId,{
                        "queryStringParameters": {fecha: date}
                    });
                    if(response.result === "success"){
                        for (let index = 0; index < response.description.length; index++) {
                            const element = response.description[index];
                            const indOf = asistenciaEstudiantes.current.indexOf(element);
                            if( indOf !== -1){
                                tableAsistencia.current[indOf].fecha.push(date);
                            } else {
                                const data = {
                                    usuarioId: element,
                                    fecha: [date]
                                };
                                asistenciaEstudiantes.current.push(element);
                                tableAsistencia.current.push(data)
                            }
                        }
                    }
                    
                }
                //Recuperando data de los usuarios en la data de la fecha de asistencia
                for (let index = 0; index < tableAsistencia.current.length; index++) {
                    const element = tableAsistencia.current[index];
                    const response = await API.get("rocket", "/usuarios/"+element.usuarioId,{
                        "queryStringParameters": {institucionId: userData.institucionId}
                    });
                    if(response.result === "success"){
                        const uData = response.description;
                        const name = uData.nombre.charAt(0).toUpperCase() + uData.nombre.slice(1);
                        const lastN = uData.apellido.charAt(0).toUpperCase() + uData.apellido.slice(1);
                        tableAsistencia.current[index].nombre = name;
                        tableAsistencia.current[index].apellido = lastN;
                        tableAsistencia.current[index].nombreCompleto = name + " " + lastN;
                    } else {
                        console.log(response);
                    }
                }
                //Adicionando las fechas de asistencia para cada estudiante
                for (let index = 0; index < tableAsistencia.current.length; index++) {
                    const element = tableAsistencia.current[index];
                    for (let indexB = 0; indexB < element.fecha.length; indexB++) {
                        const elementB = element.fecha[indexB];
                        tableAsistencia.current[index][elementB] = <IoMdCheckmark />;
                        if(!asistenciaFechas.current.includes(elementB)){
                            asistenciaFechas.current.push(elementB);
                        }
                    }
                }
                //Ordenando las fechas de asistencia
                asistenciaFechas.current.sort(function(a,b){
                    return a - b;
                });
                //Construyendo las columnas de la tabla
                for (let index = 0; index < asistenciaFechas.current.length; index++) {
                    const element = asistenciaFechas.current[index];
                    const fecha = new Date(element);
                    const dia = fecha.getDate();
                    const clmAsis2 = {
                        dataField: String(element), 
                        text: String(dia),
                        sort: true,
                        style: {
                            textAlign: "center",
                        },
                        headerStyle: () => {
                            return { textAlign: "center",}
                        },
                    }
                    columnsAsistencia.current.push(clmAsis2);
                }
                //Si el solicitante es un estudiante limpia la data dejando solo la datal del solicitante
                if(props.reportProps.usuarioId){
                    const usuarioData = [];
                    for (let index = 0; index < tableAsistencia.current.length; index++) {
                        const element = tableAsistencia.current[index];
                        if(element.usuarioId === props.reportProps.usuarioId){
                            usuarioData.push(element);
                            break;
                        }
                    }
                    tableAsistencia.current = usuarioData;
                }
                setOkForPortal(true);
            }catch(error){
                console.log(error);
                bootstrapTableMsg.current = "No es posible general el reporte en este momento, intentelo de nuevo";
            }
        }
    }, [props, userData]);

    const getIngresosEgresos = useCallback(async()=>{
        if(isFirstRender){
            tableIngresos.current = [];
            tableEgresos.current = [];
            const periodo = props.reportProps.periodo;
            const periodoInit = Date.parse((periodo.getMonth() + 1)+"/"+periodo.getDate()+"/"+periodo.getFullYear());
            const monthLastDay = new Date(periodo.getFullYear(),(periodo.getMonth()+1),0).getDate();
            const periodoEnd = Date.parse((periodo.getMonth() + 1)+"/"+monthLastDay+"/"+periodo.getFullYear());
            const qsp = {
                periodoInit: periodoInit,
                periodoEnd: periodoEnd,
                institucionId: userData.institucionId,
            };
            extWinTitle.current = "Ingresos vs Egresos";
            try{
                const response = await API.get("rocket", "/getObligaciones", {
                    queryStringParameters: qsp
                });
                if(response.result==="success"){
                    const ingresos = response.description;
                    for (let index = 0; index < ingresos.length; index++) {
                        const element = ingresos[index];
                        if(element.pago){
                            const data = {
                                obligacionId: element.obligacionId,
                                descripcion: element.descripcion,
                                ingreso: parseFloat(element.pago)
                            };
                            totalIngresos.current = totalIngresos.current + parseFloat(element.pago);
                            tableIngresos.current.push(data);
                        }
                    }
                } else {
                    console.log(response);
                }
                const response2 = await API.get("rocket","/getEgresos",{
                    queryStringParameters: qsp
                });
                if(response2.result === "success"){
                    const egresos = response2.description;
                    for (let index = 0; index < egresos.length; index++) {
                        const element = egresos[index];
                        const data = {
                            egresoId: element.egresoId,
                            descripcion: element.descripcion,
                            egreso: element.costo
                        }
                        totalEgresos.current = totalEgresos.current + parseFloat(element.costo);
                        tableEgresos.current.push(data);
                    }
                } else {
                    console.log(response2);
                }
                setOkForPortal(true);
            } catch(error){
                console.log(error);
                bootstrapTableMsg.current = "No es posible general el reporte en este momento, intentelo de nuevo";
            }
        }
    },[props, userData]);

    const getObligaciones = useCallback(async()=>{
        tableData.current = [];
        const periodoInit = Date.parse(1+"/"+1+"/"+2021);
        const monthLastDay = new Date(tmpStartDate.current.getFullYear(),(tmpStartDate.current.getMonth()+1),0).getDate();
        const periodoEnd = Date.parse((tmpStartDate.current.getMonth() + 1)+"/"+monthLastDay+"/"+tmpStartDate.current.getFullYear());
        const qsp = {
            periodoInit: periodoInit,
            periodoEnd: periodoEnd,
            institucionId: userData.institucionId,
        };
        const response = await API.get("rocket", "/getObligaciones", {
            queryStringParameters: qsp
        });
        if(response.result === "success"){
            extWinTitle.current = "Cartera por cobrar";
            const obligaciones = response.description;
            for (let index = 0; index < obligaciones.length; index++) {
                const element = obligaciones[index];
                const costo = parseFloat(element.costo);
                const pago = element.pago ? parseFloat(element.pago) : 0;
                if(costo - pago > 0){
                    const fecha = new Date(element.periodo);
                    const fechaRender = ((fecha.getMonth() + 1) < 10 ? "0"+ (fecha.getMonth() + 1) : (fecha.getMonth() + 1)) + "/" + fecha.getFullYear();
                    const data = {
                        nombre: element.nombre,
                        apellido: element.apellido,
                        descripcion: element.descripcion,
                        periodo: fechaRender,
                        saldo: costo - pago,
                        obligacionId: element.obligacionId
                    }
                    totalCartera.current = totalCartera.current + (costo - pago);
                    tableData.current.push(data);
                }
            }
            setOkForPortal(true);
        } else {
            console.log(response);
            bootstrapTableMsg.current = "No es posible general el reporte en este momento, intentelo de nuevo";
        }
    },[userData]);

    const getEstudiantes = useCallback(async()=>{
        tableEstudiantes.current = [];
        if(isFirstRender){
            extWinTitle.current = "Listado de participantes";
            try{
                const response = await API.get("rocket", "/getInscripciones", {
                    "queryStringParameters": {
                        cursoId: props.reportProps.cursoId,
                        enrollTypeFlag: false
                    }
                });
                if(response.result === "success"){
                    const inscripciones = response.description;
                    for (let index = 0; index < inscripciones.length; index++) {
                        const element = inscripciones[index];
                        const requestUserData = await API.get("rocket", "/usuarios/"+element.usuarioId, {
                            "queryStringParameters": {
                                institucionId: userData.institucionId
                            }
                        });
                        if(requestUserData.result === "success"){
                            const uData = requestUserData.description;
                            if(uData.estado){
                                tableEstudiantes.current.push(uData);
                            }
                        } else {
                            console.log(requestUserData);
                        }
                    }
                } else {
                    console.log(response);
                }
                setOkForPortal(true);
            } catch(error){
                console.log(error);
                bootstrapTableMsg.current = "No es posible general el reporte en este momento, intentelo de nuevo";
            }
        }
    },[props, userData])

    const getUsuarios = useCallback(async() => {
        tableUsuarios.current = [];
        if(isFirstRender){
            extWinTitle.current = "Listado de usuarios";
            try{
                const request = {
                    institucionId: userData.institucionId,
                    filtroBusqueda: "-1"
                }
                const requestUsers = await API.get("rocket", "/getUsers", {
                    "queryStringParameters": request
                });
                if(requestUsers.result === "success"){
                    const users = requestUsers.description;
                    totalUsuarios.current = users.length;
                    for (let index = 0; index < users.length; index++) {
                        const element = users[index];
                        if(element.estado){
                            users[index].status = <IoMdCheckmark />
                        } else {
                            users[index].status = <IoMdClose />
                        }
                        for (let indexB = 0; indexB < rolesData.length; indexB++) {
                            const rol = rolesData[indexB];
                            if(element.rol === rol.rolId){
                                totalRoles.current[rol.rol] = totalRoles.current[rol.rol] + 1;
                                users[index].rolName = rol.rol;
                                break;
                            }
                        }
                    }
                    tableUsuarios.current = users;
                    for (let index = 0; index < rolesData.length; index++) {
                        const element = rolesData[index];
                        rolesData[index].cantidad = totalRoles.current[element.rol]
                    }
                    setOkForPortal(true);
                } else {
                    console.log(requestUsers)
                }
            } catch(error){
                console.log(error);
                bootstrapTableMsg.current = "No es posible general el reporte en este momento, intentelo de nuevo";
            }
        }
    },[userData, rolesData]);

    useEffect(()=>{
        const showWindow = async() => {
            if(okForPortal){
                extWin.current = window.open();
                extWin.current.document.body.appendChild(container);
                extWin.current.document.title = extWinTitle.current;
                const src = extWin.current.document.createElement('link');
                src.rel = "stylesheet";
                src.href = "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/css/bootstrap.min.css";
                src.integrity="sha384-eOJMYsd53ii+scO/bJGFsiCZc+5NDVN2yr8+0RDqr0Ql0h+rP48ckxlpbzKgwra6";
                src.crossOrigin="anonymous";
                extWin.current.document.head.appendChild(src);
                switch (props.reportProps.reportId) {
                    case "2021A02":
                        const script = document.createElement('script');
                        script.type = "text/javascript";
                        const j = await fetch('https://cdn.jsdelivr.net/npm/chart.js@2.9.4/dist/Chart.js')
                        const jsText = await j.text();
                        const js = `const drawChart = async() => {
                                ${jsText}
                                var ctx = document.getElementById('chart').getContext('2d');
                                var myChart = new Chart(ctx, {
                                    type: 'bar',
                                    data: {
                                        labels: ['Ingresos', 'Egresos'],
                                        datasets: [{
                                            label: 'Ingresos Vs Egresos',
                                            data: [${totalIngresos.current}, ${totalEgresos.current}],
                                            backgroundColor: [
                                                'rgba(75, 192, 192, 0.2)',
                                                'rgba(255, 99, 132, 0.2)',
                                            ],
                                            borderColor: [
                                                'rgba(75, 192, 192, 1)',
                                                'rgba(255, 99, 132, 1)',
                                            ],
                                            borderWidth: 1
                                        }]
                                    },
                                    options: {
                                        scales: {
                                            yAxes: [{
                                                ticks: {
                                                    beginAtZero: true
                                                }
                                            }]
                                        }
                                    }
                                });
                            };
                            window.onload = drawChart();
                        `;
                        const javascript = extWin.current.document.createTextNode(js);
                        script.appendChild(javascript);
                        extWin.current.document.body.appendChild(script);
                        break;
                    case "2021C01":
                        const script2 = document.createElement('script');
                        script2.type = "text/javascript";
                        const j2 = await fetch('https://cdn.jsdelivr.net/npm/chart.js@2.9.4/dist/Chart.js')
                        const jsText2 = await j2.text();
                        const js3Text = `var ctx = document.getElementById('chart').getContext('2d');
                        var myChart = new Chart(ctx, {
                            type: 'pie',
                            data: {
                                labels: [${rolesData.map((val)=>{
                                    return "'"+val.rol+"'"
                                })}],
                                datasets: [{
                                    label: 'Usuarios por rol',
                                    data: [${rolesData.map((val)=>{
                                        return val.cantidad
                                    })}],
                                    backgroundColor: [
                                        'rgba(255, 99, 132, 0.2)',
                                        'rgba(54, 162, 235, 0.2)',
                                        'rgba(255, 206, 86, 0.2)',
                                        'rgba(75, 192, 192, 0.2)',
                                        'rgba(153, 102, 255, 0.2)',
                                    ],
                                    borderColor: [
                                        'rgba(255, 99, 132, 1)',
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(255, 206, 86, 1)',
                                        'rgba(75, 192, 192, 1)',
                                        'rgba(153, 102, 255, 1)',
                                    ],
                                    borderWidth: 1
                                }]
                            },
                        });`
                        const js2 = `const drawChart = async() => {
                                ${jsText2}
                                ${js3Text}
                            };
                            window.onload = drawChart();
                        `;
                        const javascript2 = extWin.current.document.createTextNode(js2);
                        script2.appendChild(javascript2);
                        extWin.current.document.body.appendChild(script2);
                        break;
                    default:
                        break;
                }
                props.reportProps.onLoad(false);
            }
        }
        showWindow();
    },[okForPortal, container, props, rolesData])

    useEffect(()=>{
        const init = async() => {
            if(isFirstRender){
                switch (props.reportProps.reportId) {
                    case "2021A01":
                        //reporte cartera por cobrar
                        await getObligaciones();
                        break;
                    case "2021A02":
                        //reporte ingresos vs egresos
                        await getIngresosEgresos();
                        break;
                    case "2021B01":
                        //reporte asistencia
                        await getAsistencia();
                        break;
                    case "2021B03":
                        //reporte particpantes
                        await getEstudiantes();
                        break;
                    case "2021C01":
                        //reporte usuarios
                        await getUsuarios();
                        break;
                    default:
                        console.log(props.reportProps);
                        break;
                }
            }
        } 
        init();
    },[container, props, getObligaciones, getIngresosEgresos, getAsistencia, getEstudiantes, getUsuarios])

    useEffect(()=>{
        
        return function cleanup(){
            extWin.current.close();
        }
    },[])

    const RenderReportCartetaPorCobrar = () => {

        return (
            <div>
                <Row className = "align-items-center mt-3">
                    <Col className="">
                        <img style={{width: "10%"}} alt="logo" src="https://rocket.gvalor.co/static/media/logo.b8eed8ed.png" />
                    </Col>
                    <Col className="text-right text-end">
                        <h2>Cartera por cobrar</h2>
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col>
                        <BootstrapTable
                            keyField="obligacionId"
                            data={tableData.current}
                            columns={columns.current}
                            noDataIndication={bootstrapTableMsg.current}
                        />
                    </Col>
                </Row>
                <Row >
                    <Col className="text-right text-end">
                        <h3>Total Cartera: {totalCartera.current}</h3>
                    </Col>
                </Row>
                <hr></hr>
            </div>
        )
    };

    const RenderReportIngresosVsEgresos = () => {
        
        return (
            <div>
                <Row className = "align-items-center mt-3">
                    <Col className="">
                        <img style={{width: "10%"}} alt="logo" src="https://rocket.gvalor.co/static/media/logo.b8eed8ed.png" />
                    </Col>
                    <Col className="text-right text-end">
                        <h2>Ingresos Vs Egresos</h2>
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col className="text-center" id="canvas_container">
                        <canvas id="chart"></canvas>
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col className="text-center">
                        <h3>Utilidad: {totalIngresos.current - totalEgresos.current}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4>Total ingresos: {totalIngresos.current}</h4>
                    </Col>
                    <Col>
                        <h4>Total Egresos: {totalEgresos.current}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BootstrapTable
                            keyField="obligacionId"
                            data={tableIngresos.current}
                            columns={columnsIngresos.current}
                            noDataIndication={bootstrapTableMsg.current}
                        />
                    </Col>
                    <Col>
                        <BootstrapTable
                            keyField="egresoId"
                            data={tableEgresos.current}
                            columns={columnsEgresos.current}
                            noDataIndication={bootstrapTableMsg.current}
                        />
                    </Col>
                </Row>
                <hr></hr>
            </div>
        )
    };

    const RenderReportAsistencia = () => {
        
        return (
            <div>
                <Row className = "align-items-center mt-3">
                    <Col className="">
                        <img style={{width: "10%"}} alt="logo" src="https://rocket.gvalor.co/static/media/logo.b8eed8ed.png" />
                    </Col>
                    <Col className="text-right text-end">
                        <h2><span style={{textTransform:"capitalize"}}>{props.reportProps.cursoNombre}</span> | Asistencia</h2>
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col className="text-center">
                        <h4>{monthAndYear.current}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BootstrapTable
                            keyField="usuarioId"
                            data={tableAsistencia.current}
                            columns={columnsAsistencia.current}
                            noDataIndication={bootstrapTableMsg.current}
                        />
                    </Col>
                </Row>
                <hr></hr>
            </div>
        )
    };

    const RenderReportListaEstudiantes = () => {

        return (
            <div>
                <Row className = "align-items-center mt-3">
                    <Col className="">
                        <img style={{width: "10%"}} alt="logo" src="https://rocket.gvalor.co/static/media/logo.b8eed8ed.png" />
                    </Col>
                    <Col className="text-right text-end">
                        <h2><span style={{textTransform:"capitalize"}}>{props.reportProps.cursoNombre}</span> | Participantes</h2>
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col>
                        <BootstrapTable
                            keyField="usuarioId"
                            data={tableEstudiantes.current}
                            columns={columnsEstudiantes.current}
                            noDataIndication={bootstrapTableMsg.current}
                        />
                    </Col>
                </Row>
                <hr></hr>
            </div>
        )
    };

    const RenderReportListaUsuarios = () => {

        return (
            <div>
                <Row className = "align-items-center mt-3">
                    <Col className="">
                        <img style={{width: "10%"}} alt="logo" src="https://rocket.gvalor.co/static/media/logo.b8eed8ed.png" />
                    </Col>
                    <Col className="text-right text-end">
                        <h2>Reporte de usuarios</h2>
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col className="text-center">
                        <h4>Total usuarios {totalUsuarios.current}</h4>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center" id="canvas_container">
                        <canvas id="chart"></canvas>
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col>
                        <h4>Cantidad de usuarios por rol</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BootstrapTable
                            keyField="rolId"
                            data={rolesData}
                            columns={columnsRoles.current}
                            noDataIndication={bootstrapTableMsg.current}
                        />
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col>
                        <h4>Lista de usuarios</h4>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <BootstrapTable
                            keyField="usuarioId"
                            data={tableUsuarios.current}
                            columns={columnsUsuarios.current}
                            noDataIndication={bootstrapTableMsg.current}
                        />
                    </Col>
                </Row>
                <hr></hr>
            </div>
        )
    };
    
    return (
        <div >
            {okForPortal && props.reportProps.reportId === "2021A01" && ReactDOM.createPortal(<RenderReportCartetaPorCobrar />, container)}
            {okForPortal && props.reportProps.reportId === "2021A02" && ReactDOM.createPortal(<RenderReportIngresosVsEgresos />, container)}
            {okForPortal && props.reportProps.reportId === "2021B01" && ReactDOM.createPortal(<RenderReportAsistencia />, container)}
            {okForPortal && props.reportProps.reportId === "2021B03" && ReactDOM.createPortal(<RenderReportListaEstudiantes />, container)}
            {okForPortal && props.reportProps.reportId === "2021C01" && ReactDOM.createPortal(<RenderReportListaUsuarios />, container)}
        </div>
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(ExecReport);