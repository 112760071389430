import { API } from "aws-amplify";
import store from "../store";

/**
 * Obtiene la configuración de las integraciones del cliente
 */
export const sincroIntegraciones = () => {
    const userData = store.getState();
    API.get("rocket", "/integraciones/"+userData.userAuthenticated.institucionId, {
    }).then((value)=>{
        switch (value.result) {
            case "success":
                const integracionesData = value.description;
                store.dispatch({
                    type: "SET_INTEGRACIONES",
                    integracionesData
                });
                break;
            case "fail":
                console.log(value);
                break;
            default:
                break;
        }
    }).catch(error=>{
        const err = {
            code: "UnableRetriveIntegrations",
            error: error
        };
        console.log(err);
    })
};

/**
 * Obtiene los roles disponibles en la base de datos
 */
export const sincroRoles = () => {
    API.get("rocket","/getRoles")
    .then((value)=>{
        switch (value.result) {
            case "success":
                const rolesData = value.description;
                store.dispatch({
                    type: "SET_ROLES",
                    rolesData
                })
                break;
            case "fail":
                console.log(value);
                break;
            default:
                break;
        }
    }).catch(error=>{
        const err = {
            code: "UnableRetriveRoles",
            error: error
        };
        console.log(err);
    })
};

/**
* Actualiza la data del curso adicionando el ID de integración devuelto por croom
 * cuando la integracion se realizo correctamente.
 * @param {String} cursoId: el id del curso en rocket,
 * @param {String} institucionId: el id de la institucion a la que pertenece el curso en rocket
 * @param {String} integracionId: el id devuelto por croom confirmando la correcta integración 
*/
export const actualizaCursoIntegrado = (cursoId, institucionId, integracionId) => {
    if (cursoId && institucionId && integracionId){
        const data = {
            cursoId: cursoId,
            institucionId: institucionId,
            integracionId: integracionId,
            servicio: "curso_update"
        }
        API.post("rocket","/integracionManager",{
            body: data
        }).then(value=>{
            switch (value.result) {
                case "success":
                    console.log(value.result);
                    break;
                case "fail":
                    console.log(value.description);
                    break;
                default:
                    break;
            }
        }).catch(error=>{
            console.log(error);
        })
    } else {
        console.log("Faltan parametros para ejecutar actualización");
    }    
};

/**
 * Actualiza la data del curso adicionando el ID de integración devuelto por croom
 * cuando la integracion se realizo correctamente.
 * @param {String} cursoId: el id del curso en rocket,
 * @param {String} institucionId: el id de la institucion a la que pertenece el curso en rocket
 * @param {String} integracionId: el id devuelto por croom confirmando la correcta integración
*/
export const actualizaUsuarioIntegrado = (usuarioId, institucionId, integracionId) => {
    if (usuarioId && institucionId && integracionId){
        const data = {
            usuarioId: usuarioId,
            institucionId: institucionId,
            integracionId: integracionId,
            servicio: "usuario_update"
        }
        API.post("rocket","/integracionManager",{
            body: data
        }).then(value=>{
            switch (value.result) {
                case "success":
                    console.log(value.result);
                    break;
                case "fail":
                    console.log(value.description);
                    break;
                default:
                    break;
            }
        }).catch(error=>{
            console.log(error);
        })
    } else {
        console.log("Faltan parametros para ejecutar actualización");
    }    
};