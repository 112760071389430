import React, { useState, useRef, useEffect } from "react";
import { Row, Col, InputGroup, FormControl, Tooltip, OverlayTrigger, Modal } from "react-bootstrap";
import LoaderButton from "../components/loaderButton";
import { connect } from "react-redux";
import { MdErrorOutline } from "react-icons/md";
import { nanoid } from "nanoid";
import { API } from "aws-amplify";
import LoadingModal from "../components/loadingModal";

const mapStateToProps = state => ({
    cursoData: state.cursoData,
    institucionId: state.institucionData.institucionId
});

const mapDispatchToProps = dispatch => ({
    setCursoData(cursoData){
        dispatch({
            type: "CARGAR_CURSO_DATA",
            cursoData
        })
    }
});

const CrearCurso = ({cursoData, setCursoData, institucionId}) => {
    const [internalId, setInternalId] = useState((cursoData.internalId ? cursoData.internalId : nanoid(5)));
    const [nombre, setNombre] = useState(cursoData.nombre ? cursoData.nombre : "");
    const [enable, setEnable] = useState(cursoData.status ? true : false);

    const [textButton, setTextButton] = useState("Guardar");
    const [isLoading, setIsLoading] = useState(false);

    const msgModal = useRef("");
    const alertModalTitle = useRef(<MdErrorOutline />);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showLoadingModal, setShowLoadingModal] = useState(false);

    const validarCreacion = () => {
        return internalId.length > 0 &&
        nombre.length > 2
    };

    const enviarCreacion = (event) => {
        event.preventDefault();
        setShowLoadingModal(true);
        setTextButton("");
        setIsLoading(true);
        if(cursoData.internalId){
            const editedCourseData = {
                nombre: nombre.trim().toLowerCase(),
                status: enable,
                institucionId: institucionId
            };
            API.put("rocket", "/cursos/"+cursoData.cursoId, {
                body: editedCourseData
            }).then((value)=>{
                switch (value.result) {
                    case "success":
                        setInternalId(nanoid(5));
                        setNombre("");
                        setEnable(true);
                        setTextButton("Guardar");
                        setIsLoading(false);
                        setShowLoadingModal(false);
                        break;
                    case "fail":
                        const err = {
                            code: "UnableSaveCourse"
                        };
                        handleError(err);
                        setInternalId(nanoid(5));
                        setNombre("");
                        setEnable(true);
                        setTextButton("Guardar");
                        setIsLoading(false);
                        setShowLoadingModal(false);
                        break;
                    default:
                        break;
                }
                setCursoData({});
            }).catch(error=>{
                console.log(error);
                setCursoData({});
                handleError("error");
                setInternalId(nanoid(5));
                setNombre("");
                setEnable(true);
                setTextButton("Guardar");
                setIsLoading(false);
                setShowLoadingModal(false);
            })
        } else {
            const courseData = {
                internalId: internalId.trim(),
                nombre: nombre.trim().toLowerCase(),
                status: enable,
                institucionId: institucionId
            };
            API.post("rocket", "/crearCurso",{
                body: courseData
            }).then((value)=>{
                switch (value.result) {
                    case "success":
                        setInternalId(nanoid(5));
                        setNombre("");
                        setEnable(true);
                        setTextButton("Guardar");
                        setIsLoading(false);
                        setShowLoadingModal(false);
                        break;
                    case "fail":
                        console.log(value);
                        const err = {
                            code: "UnableSaveCourse"
                        }
                        handleError(err);
                        setTextButton("Guardar");
                        setIsLoading(false);
                        setShowLoadingModal(false);
                        break;
                    default:
                        break;
                }
            }).catch(error=>{
                console.log(error);
                handleError("error");
                setTextButton("Guardar");
                setIsLoading(false);
                setShowLoadingModal(false);
            })
        }
    }

    const handleError = (exception) =>{
        switch (exception.code) {
            case "UnableSaveCourse":
                msgModal.current = "No es posible gurdar este curso. Intentalo de nuevo, si el problema persiste, por favor ponte en contacto con nosotros.";
                break;
            default:
                msgModal.current = "Error en la aplicación. Intentalo de nuevo, si el problema persiste, comunicate con nosotros.";
                break;
        }
        setShowAlertModal(true);
    }

    useEffect(()=>{
        return function cleanUp(){
            setCursoData({});
        }
    },[setCursoData]);

    const renderToolTip = (props) => (
        <Tooltip id="info-tooltip" {...props}>
            {cursoData.internalId ? "No es posible editar el ID de un curso existente." : "Puede ingresar un codigo personalizado. Sin embargo, este debe ser único."}
        </Tooltip>
    );

    return (
        <div>
            <LoadingModal 
                show={showLoadingModal}
            />
            <Modal 
                show={showAlertModal}
                onHide={()=>setShowAlertModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{alertModalTitle.current}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {msgModal.current}
                </Modal.Body>
            </Modal>
            <h4>Crear curso</h4>
            <form onSubmit={enviarCreacion}>
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="ID-curso">ID</InputGroup.Text>
                            </InputGroup.Prepend>
                            <OverlayTrigger
                                placement="auto-start"
                                delay={{ show: 100, hide: 400 }}
                                overlay={renderToolTip}
                            >
                                <FormControl
                                    placeholder="Ingrese ID del curso"
                                    aria-label="ID"
                                    aria-describedby="ID-curso"
                                    value={internalId}
                                    readOnly={(cursoData.internalId ? true : false)}
                                    onChange={e=>setInternalId(e.target.value)}
                                />
                            </OverlayTrigger>
                        </InputGroup>
                    </Col>
                    <Col md={"auto"} sm={"auto"} xs={"auto"}>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="enable-check">Disponible</InputGroup.Text>
                            </InputGroup.Prepend>
                                <span className="input-group-text form-control">
                                    <input 
                                        type="checkbox"
                                        checked={enable}
                                        onChange={e=>setEnable(e.target.checked)} 
                                        aria-label="Disponible"
                                    />
                                </span>        
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="nombre-curso">Nombre</InputGroup.Text>
                            </InputGroup.Prepend>
                            <FormControl 
                                placeholder="Nombre del curso"
                                aria-label="Nombre"
                                aria-describedby="nombre-curso"
                                value={nombre}
                                onChange={e=>setNombre(e.target.value)}
                            />
                        </InputGroup>
                    </Col>
                </Row>
                <hr></hr>
                <Row>
                    <Col className="text-right">
                        <LoaderButton
                            type="submit"
                            variant="success"
                            isLoading={isLoading}
                            disabled={!validarCreacion()}
                        > {textButton} 
                        </LoaderButton>
                    </Col>
                </Row>
                <hr></hr>
            </form>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(CrearCurso);